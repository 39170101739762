<div
  class="request text-right d-flex justify-content-end align-items-center c-pointer bg-seen my-3"
  (click)="onClickNotif()" *ngIf="notification?.seen == true" 
>
  <div class="d-flex  ">
    <div class="request-infos mr-4">
      <div class="request-title">
        <span dir="rtl" class="title">{{ notification?.notification?.content }}</span>
      </div>
      <div class="request-date mt-1">
        <span class="date">{{ notification.notification.createdAt | date :'dd-MMM-yy h:mm:ss a ' }}</span>
        
      </div>
    </div>

    <div class="request-avatar">
      <img
        class="circle-img"
        alt=""
        [src]="
          notification?.notification?.relatedTo?.avatar?.public_url
            ? (isDemoMode ? '' : BASE_API) + 
              notification?.notification?.relatedTo?.avatar?.filename
            : 'assets/img/logo.png'
        "
      />
    </div>
  </div>
</div>


<div
  class="request text-right d-flex justify-content-end align-items-center c-pointer"
  (click)="onClickNotif()" *ngIf="notification?.seen == false" 
>
  <div class="d-flex">
    <div class="request-infos mr-4">
      <div class="request-title">
        <span dir="rtl" class="title">{{ notification?.notification?.content }}</span>
      </div>
      <div class="request-date mt-1">
        <span class="date">{{ notification.notification.createdAt | date :'dd-MMM-yy h:mm:ss a ' }}</span>
        
      </div>
    </div>

    <div class="request-avatar">
      <img
        class="circle-img"
        alt=""
        [src]="
          notification?.notification?.relatedTo?.avatar?.public_url
            ? (isDemoMode ? '' : BASE_API)  +
              notification?.notification?.relatedTo?.avatar?.filename
            : 'assets/img/logo.png'
        "
      />
    </div>
  </div>
</div>
