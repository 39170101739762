import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxImageCompressService } from 'ngx-image-compress';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {
  @Input() length = 3;
  @Output() photos = new EventEmitter();
  imageList = [];
  images = [];
  showLengthWarning: boolean;

  constructor(private imageCompress: NgxImageCompressService) { }

  resetCarousel() {
    // Logic to reset the carousel content
    this.imageList = [];
    this.images = [];
  }

  ngOnInit(): void { }

  onFileInput(files: FileList) {
    let length = this.length;
    let currentLength = this.images.length + files.length;
    if (currentLength > this.length) {
      this.showLengthWarning = true;
      setTimeout(() => {
        this.showLengthWarning = false;
      }, 6000);
      if (this.images.length < this.length) {
        length = this.length - this.images.length;
      } else {
        return;
      }
    }

    for (let i = 0; i < length; i++) {
      let reader = new FileReader();
      reader.onload = () => {
        if (reader.result) {
          console.error('normal: ',reader.result)
 
          this.compressFile(reader.result).then((res)=>{
            this.images.push(res);
          } )
          this.imageList.push(reader.result);
         
          this.photos.emit(this.images);
        }
      };
      reader.readAsDataURL(files[i]);
    }
  }

  async compressFile(image) {
    var orientation = -1;
    let test = null;



    return this.imageCompress.compressFile(image, orientation, 50, 30);
  
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }

  removeImg(index: number) {
    this.imageList = this.imageList.filter((item, i) => i !== index);
    this.images = this.images.filter((item, i) => i !== index);
    this.photos.emit(this.images);
  }
}
