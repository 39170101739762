import { FormControl, Validators } from "@angular/forms";


//********* BUYER_SELLER FIELDS ********//

const email = new FormControl('', {
    validators: [Validators.required, Validators.email],
  });
  const firstName = new FormControl('', {
    validators: [Validators.required],
  });
  const lastName = new FormControl('', { validators: [Validators.required] });
  const phoneNumber = new FormControl('', {
    validators: [Validators.required],
  });
  const pwd = new FormControl('', {
    validators: [Validators.required, Validators.minLength(8)],
  });
  const confirmPwd = new FormControl('', {
    validators: [Validators.required, Validators.minLength(8)],
  });
  const avatar = new FormControl('', { validators: [] });
  const pairingCode = new FormControl('');
  const commertialRegister = new FormControl('', { validators: [] });
  const experyDateOfcr = new FormControl('', { validators: [] });
  const city = new FormControl('', { validators: [] });
  const region = new FormControl('', { validators: [] });
  const postalCode = new FormControl('', { validators: [] });
  const address = new FormControl('', { validators: [] });
  const otp = new FormControl('', {
    validators: [Validators.required, Validators.maxLength(6), Validators.minLength(6)]
  });
  const isRulesApproved = new FormControl('', { validators: [] });
  const taxId = new FormControl('', {
   validators: [Validators.maxLength(15), Validators.minLength(15)]
  });






export const BUYER_SELLER_FIELDS = [

    {
      name: 'email',
      text: '* البريد الإلكتروني',
      type: 'email',
      errorMsg: 'الرجاء إدخال بريد إلكتروني صحيح ',
      control: email,
    },

  
    {
      name: 'phoneNumber',
      text: '* رقم الهاتف',
      type: 'tel',
      control: phoneNumber,
    },

    {
      name: 'region',
      text: 'المنطقة *',
      type: 'select',
      control: region,
      childs: []
    },
    {
      name: 'city',
      text: 'المدينة * ',
      type: 'select',
      control: city,
      childs: []
    },
   
  ];