import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { INTERNATIONAL_PHONE_CODE, PHONE_LENGTH } from 'src/app/core/config';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  phoneNumber: number;
  otpCode: any;
  isValid: boolean;
  resetCode: any;
  password: any;
  confirmPassword: any;

  sendCodeStep=true;
  verifyCodeStep=false;
  resetPasswordStep=false;



  phoneCode = INTERNATIONAL_PHONE_CODE;
  phoneLength: number = PHONE_LENGTH;

  timeLeft: number = 60;
  interval;
  canResend: boolean = false;

  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private router: Router,
    private sharedService: SharedService,
    private layoutService: LayoutService
  ) {}

  ngOnInit(): void {
    this.phoneNumber = this.authService.phoneNumber

    this.startTimer();
  }

  sendCode(){
    if(this.phoneNumber)
    this.authService.sendResetCode(`${this.phoneCode}${this.phoneNumber}`).subscribe(
      res=>{
        console.log(res);
        this.sendCodeStep = false;
        this.verifyCodeStep = true;
        this.resetPasswordStep = false;

      }
    )
  }

  verifyCode(){
    if(this.phoneNumber && this.resetCode)
    this.authService.verifyResetCode(`${this.phoneCode}${this.phoneNumber}`,this.resetCode).subscribe(
      res=>{
        console.log(res);
        this.sendCodeStep = false;
        this.verifyCodeStep = false;
        this.resetPasswordStep = true;
      }
    )
  }

  resetPassword(){
    if(this.password && this.confirmPassword){
      if(this.password == this.confirmPassword){
        this.authService.resetPassword(`${this.phoneCode}${this.phoneNumber}`,this.resetCode, this.password).subscribe(
          res=>{
            console.log(res);
            this.router.navigate(['/auth/login']);
            this.sendCodeStep = false;
            this.verifyCodeStep = false;
            this.resetPasswordStep = false;
          }
        )
      }
    } else {

    }
    
  }

  onChangeOtpCode() {
    if (this.otpCode.length === 6) {
      this.isValid = true;
    } else {
      this.isValid = false;
    }
  }

  resendOtp() {
    this.timeLeft = 60;
    this.canResend = false;
    this.startTimer();
    this.authService.sendOtp(this.phoneNumber, this.authService.type).subscribe((response) => {
      console.log(response);
    });
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.canResend = true;
        this.pauseTimer();
      }
    }, 1000);
  }

  pauseTimer() {
    clearInterval(this.interval);
  }

  login() {
    const requestData = {
      phoneNumber: this.phoneCode + this.phoneNumber,
      otp: this.otpCode
    };

    this.authService.signin(this.authService.type, requestData).subscribe(
      (response: any) => {
        if (!this.sharedService?.isMock) {
          localStorage.setItem('user', JSON.stringify(response.data.user));
          localStorage.setItem('token', response.data.token);
        }
        this.toastr.success('! تم تسجيل الدخول بنجاح');
        this.layoutService.initializeSidebarListenners();
        this.router.navigate(['/']);
      },
      (error) => {
        this.toastr.error('! عذراً، لقد حصل خطأ');
      }
    );
  }

}
