<div class="card">
  <!-- Card body -->
  <div class="card-body">
    <div class="row row-reverse align-items-center">
      <div class="col-auto">
        <!-- Avatar -->

        <a href="javascript:;" class="avatar avatar-xl rounded-circle">
          <img
            *ngIf="user?.pairedUser?.avatar"
            alt="Image placeholder"
            [src]="
              (isDemoMode ? '' : BASE_API) + user?.pairedUser?.avatar?.filename
            "
          />
          <img
            *ngIf="user?.pairedUser?.avatar == null"
            alt="Image placeholder"
            src="assets/img/default-avatar.png"
          />
        </a>
      </div>
      <div class="col ml--2 text-right">
        <h3 class="mb-0">
          <a href="javascript:;">{{
            user?.pairedUser?.firstName +
              ' ' +
              user?.pairedUser?.lastName +
              ' ' +
              user?.pairedUser?.thirdName
          }}</a>
        </h3>

        <p class="text-sm text-muted mb-0">{{ user?.pairedUser?.email }}</p>
        <p class="text-sm text-muted mb-0">
          {{ user?.pairedUser?.phoneNumber }}
        </p>

        <li dir="rtl">
          <small *ngIf="user?.pairedUser"
            >{{ user?.pairedUser?.createdAt | date : 'MM-dd-yyyy' }} تاريخ
            التسجيل
          </small>
        </li>

        <li dir="rtl">
          <small *ngIf="user?.pairedUser?.pairingInfo"
            >{{
              user?.pairedUser?.pairingInfo?.endDate | date : 'MM-dd-yyyy'
            }}
            تاريخ إنتهاء التفعيل
          </small>
        </li>
        <hr />
        <div *ngIf="isExpired(user?.pairedUser?.pairingInfo?.endDate) == false">
          <span class="text-success">●</span>
          <small>مفعل</small>
        </div>

        <div *ngIf="isExpired(user?.pairedUser?.pairingInfo?.endDate) == true">
          <span class="text-danger">●</span>
          <small>متوقف</small>
        </div>
      </div>

      <div class="col ml--2 text-left">
        <h4 class="mb-0">{{ user?.count }} عدد الحجوزات</h4>
      </div>
      <!-- <div class="col-auto">
        <button type="button" class="btn btn-sm btn-primary">✘</button>
      </div> -->
    </div>
  </div>
</div>
