<!-- SUCCESS MODAL -->

<div
  class="modal fade bd-example-modal-lg"
  id="success-modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myLargeModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog custom-modal modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-body d-flex justify-content-center text-center">
        <div class="modal-container fs-22">
          <div class="">
            <span>
              <i class="ni ni-check-bold modal-icon modal-icon_success"></i>
            </span>
          </div>
          <div class="main-text mt-4">
            <h2 class="text-black">{{ title }}</h2>
          </div>
          <div class="second-text mt-2">
            <h4 class="text-gray">
              {{ description }}
            </h4>
          </div>

          <div class="d-flex justify-content-center mt-5 text-center">
            <button
              class="btn btn-secondary"
              data-dismiss="modal"
              aria-label="Close"
            >
              إغلاق
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- END SUCCESS MODAL -->

<!-- ERROR MODAL -->

<div
  class="modal fade bd-example-modal-lg"
  id="error-modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myLargeModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog custom-modal modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-body d-flex justify-content-center text-center">
        <div class="modal-container fs-22">
          <div class="">
            <span>
              <i class="ni ni-fat-remove modal-icon modal-icon_error"></i>
            </span>
          </div>
          <div class="main-text mt-4">
            <h2 class="text-black">{{ title }}</h2>
          </div>
          <div class="second-text mt-2">
            <h4 class="text-gray">
              {{ description }}
            </h4>
          </div>

          <div class="d-flex justify-content-center mt-5 text-center">
            <button
              class="btn btn-secondary"
              data-dismiss="modal"
              aria-label="Close"
            >
              إغلاق
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- END ERROR MODAL -->

<!-- INFO MODAL -->

<div
  class="modal fade"
  id="modal-notification"
  tabindex="-1"
  role="dialog"
  aria-labelledby="modal-notification"
  aria-hidden="true"
>
  <div
    class="modal-dialog modal-danger modal-dialog-centered modal-notif"
    role="document"
  >
    <div class="modal-content bg-white">
      <div class="modal-header text-primary">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
        <h6
          class="modal-title text-white text-right"
          id="modal-title-notification"
        ></h6>
      </div>
      <div class="modal-body">
        <div class="py-3 text-center text-primary">
          <i class="ni ni-bell-55 ni-3x"></i>
          <h4 class="heading mt-4 text-primary fs-18">{{ title }}</h4>
          <p>{{ description }}</p>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-link text-gray ml-auto"
          data-dismiss="modal"
        >
          إغلاق
        </button>
      </div>
    </div>
  </div>
</div>

<!-- END INFO MODAL -->

<!-- TRIGGER BUTTONS -->

<!-- SUCCESS -->
<button
  type="button"
  class="d-none"
  #btnDisplaySuccessModal
  data-toggle="modal"
  data-target="#success-modal"
></button>

<!-- ERROR -->
<button
  type="button"
  class="d-none"
  #btnDisplayErrorModal
  data-toggle="modal"
  data-target="#error-modal"
></button>

<!-- INFO -->
<button
  type="button"
  class="d-none"
  #btnDisplayInfoModal
  data-toggle="modal"
  data-target="#modal-notification"
></button>

<!-- END TRIGGER BUTTONS -->
