import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [

  {
    path: 'home',
    loadChildren: () =>
      import('./features/public/public.module').then((m) => m.PublicModule)
  },

  {
    path: '',
    loadChildren: () =>
      import('./features/private/private.module').then((m) => m.PrivateModule),
  },


  {
    path: '',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
