import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

export interface NotifInfo {
  allNotifCount:number,
  notifications:any[]
}

@Injectable()
export class DataService {

  private _todo = new BehaviorSubject<NotifInfo>({allNotifCount:0,notifications:[],
});
  readonly todos$ = this._todo.asObservable();
  private todo: NotifInfo ={allNotifCount:0,notifications:[],}
  constructor() { }
  loadAll() {
    this.todo = {allNotifCount:0,notifications:[],}  ;
    this._todo.next(this.todo);
  }
  update(data:any[]) {
    this.todo = {allNotifCount:0, notifications:[],
      }
      
    this.todo.notifications=data;
    
    data.forEach(item=>{
      if(item.seen==false){
        this.todo.allNotifCount++
      }
})
      console.log(this.todo)
    this._todo.next(Object.assign([], this.todo));
  }

  create() {
    this._todo.next(Object.assign([], {allNotifCount:0, notifications:[]}));
  }


}