import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { USER_TYPES } from 'src/app/core/enums';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-register-main',
  templateUrl: './register-main.component.html',
  styleUrls: ['./register-main.component.scss']
})
export class RegisterMainComponent implements OnInit, AfterViewInit {

  userTypes = USER_TYPES;
  selectedType?: string = null;
  pairingCode: string;

  constructor(private activatedRoute: ActivatedRoute, private authService: AuthService,
    private toastrService: ToastrService) {

    this.activatedRoute.queryParams.subscribe(params => {
      this.pairingCode = params['pairingCode'];
     

    });
  }
  ngAfterViewInit(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    if (this.pairingCode) {
      this.authService.checkPairingCode(this.pairingCode).subscribe(
        (res: any) => {
          this.toastrService.success(`سيتم تسجيلك عن طريق رمز  المسوق ${res.data.pairingCode} `);
        },
        err => {
          this.pairingCode = null
          this.toastrService.warning('رمز مسوق غير صالح');
        }
      )
    }
  }

  ngOnInit(): void {
    console.log(this.userTypes);


  }

  setRegisterType(choosedType) {
    if (choosedType == this.userTypes.TRANSPORTER_AGENT) this.selectedType = this.userTypes.TRANSPORTER
    else
      this.selectedType = this.userTypes[choosedType];
    console.log(this.selectedType);


  }

  onChange(deviceValue) {
    this.setRegisterType(deviceValue)
  }

}
