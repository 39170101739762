export const nationalities = [
  {
    id: '03152754-00b7-44c9-8d79-d82207143dba',
    key: 'مصر',
    createdAt: '2021-08-04T09:16:39.940Z',
    updatedAt: '2021-08-04T09:16:39.940Z'
  },
  {
    id: '29eb032f-400b-492a-a642-5eb475fc7dcc',
    key: 'البحرين',
    createdAt: '2021-08-04T09:16:57.414Z',
    updatedAt: '2021-08-04T09:16:57.414Z'
  },
  {
    id: '6ee8c1cc-4b5f-44ff-8d50-c3dda314bd3c',
    key: 'قطر',
    createdAt: '2021-08-04T09:17:13.862Z',
    updatedAt: '2021-08-04T09:17:13.862Z'
  },
  {
    id: '99b24188-73db-4f6e-9073-35fb5b96ba20',
    key: 'المملكة العربية السعودية',
    createdAt: '2021-08-04T09:16:18.296Z',
    updatedAt: '2021-08-04T09:16:18.296Z'
  },
  {
    id: 'c5250663-acf8-4c65-8fbf-d2809d57ec82',
    key: 'تونس',
    createdAt: '2021-08-04T09:16:44.651Z',
    updatedAt: '2021-08-04T09:16:44.651Z'
  }
];
