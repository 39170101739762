// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  BLOB_URL: 'https://kharaaj.blob.core.windows.net/khraaj/',
  BASE_URL: 'https://nestapp.azurewebsites.net/',
  API_URL: 'https://nestapp.azurewebsites.net/api/v1/',
  INTERNATIONAL_PHONE_CODE: '+966',
  PHONE_LENGTH: 9,
  MAPS_API_KEY:  '28caf107c44f38343aff770ad89e7613',
  HOST: 'https://app.kharaaj.com'
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
