import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { APP_ROUTES } from 'src/app/core/config/routes';
import { TRANSPORTER_FIELDS } from 'src/app/core/const/register/transporter.fields';
import { USER_TYPES } from 'src/app/core/enums';
import { StepperConfig, StepperFields } from 'src/app/core/types/stepper.types';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-register-transporter',
  templateUrl: './register-transporter.component.html',
  styleUrls: ['./register-transporter.component.scss']
})
export class RegisterTransporterComponent implements OnInit {
  formRegister: FormGroup;
  stepperConfig: StepperConfig;
  stepperField: StepperFields[] = TRANSPORTER_FIELDS;

  type = USER_TYPES.TRANSPORTER;

  nationalities = [];
  languages = [];

  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private sharedService: SharedService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm() {
    this.stepperConfig = {
      number: 1,
      type: USER_TYPES.TRANSPORTER_AGENT,
      config: [
        {
          step: 1,
          size: 9
        }
      ]
    };
  }

  onSubmit(event) {
    const type = event?.type;
    delete event?.type;
    delete event?.confirmPwd;
    this.authService.signUp(type, event).subscribe((data) => {
      this.toastr.success('تمت العملية بنجاح ! مرحبا بك');
      this.sharedService.changeSignUpStatus('RESET');
      this.router.navigate([APP_ROUTES.AUTH.LOGIN], { queryParams: {type: this.type}});
    });
  }
}
