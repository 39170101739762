import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BLOB_URL, isMock } from 'src/app/core/config';
import { notifications } from 'src/app/core/mock/notifications';
import { DataService } from '../../services/data.service';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnDestroy {
  @Input() notification: any;
  isDemoMode: boolean = isMock;

  componentDestroyed$ = new Subject<boolean>();
  BASE_API = BLOB_URL;

  constructor(private sharedService: SharedService, private dataService: DataService,
    private router: Router) {}

  ngOnInit(): void {
    this.sharedService
      .getDemoStatus()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((status) => {
        this.isDemoMode = status;
      });
      
  }
  onClickNotif() {
    console.log(this.notification)
    if( this.notification.seen == false){
      this.sharedService
      .markNotificationAsSeen(this.notification.notification.id)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((result) => {
       
       /* if(this.notification.notification?.product){ 
          this.router.navigate(['dashboard/my-products'])
        } */

        if(this.notification.notification.product){
          this.router.navigate(['/product/page/'+this.notification.notification.product?.id]);
        }

        if(this.notification.notification?.transaction){ 
          this.router.navigate(['/reservations/'+ this.notification.notification?.transaction.id])
        }
        if(this.notification.notification?.fundTransfer){ 
          this.router.navigate(['/transactions'])
        }
        if(this.notification.notification?.receiveMoney){ 
          this.router.navigate(['/myaccount/wallet'])
        }
        if(this.notification.notification?.request){ 
          this.router.navigate(['/transport/requests'])
        }
        if(this.notification.notification?.proposal?.status == 'APPROVED'){ 
          this.router.navigate(['/transport/history'])
        }
        
        this.getNotifications()
      });
    }if( this.notification.seen == true) {
      this.sharedService
      .markNotificationAsUnSeen(this.notification.notification.id)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((result) => {
        this.getNotifications()
      });
    }
     
  }
  ngOnDestroy(): void {
    this.componentDestroyed$.complete();
  }

  getNotifications(){
    this.sharedService.getNotifications(50)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe((notifications: any) => {
      if (notifications) {
        this.dataService.update(notifications?.data)
      }
    });
  }
}
