import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { RegisterMainComponent } from './components/register-main/register-main.component';
import { RegisterBuyerVendorComponent } from './components/register-buyer-vendor/register-buyer-vendor.component';
import { RegisterBuyerComponent } from './components/register-buyer/register-buyer.component';
import { RegisterMarketerComponent } from './components/register-marketer/register-marketer.component';
import { RegisterTransporterComponent } from './components/register-transporter/register-transporter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { LoginComponent } from './components/login/login.component';
import { OtpComponent } from './components/otp/otp.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RegisterSellerComponent } from './components/register-seller/register-seller.component';
import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { InternalLoginComponent } from './components/internal-login/internal-login.component';
import { RegisterOtpComponent } from './components/register-otp/register-otp.component';


@NgModule({
  declarations: [RegisterMainComponent, RegisterBuyerVendorComponent, RegisterBuyerComponent, RegisterMarketerComponent, RegisterTransporterComponent, LoginComponent, OtpComponent, RegisterSellerComponent, ForgetPasswordComponent, InternalLoginComponent, RegisterOtpComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    AuthRoutingModule,
    HttpClientModule,
    SharedModule
  ]
})
export class AuthModule { }
