import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { AuthService } from 'src/app/features/auth/services/auth.service';

@Component({
  selector: 'app-transporter-identification',
  templateUrl: './transporter-identification.component.html',
  styleUrls: ['./transporter-identification.component.scss']
})
export class TransporterIdentificationComponent implements OnInit {

  drivingLicense;
  imgDrivingLicencePath: any = 'assets/img/placeholder.png';

  identityCard;
  imgIdentityCardPath: any = 'assets/img/placeholder.png';

  commercialRegister;
  imgCommercialPath: any = 'assets/img/placeholder.png';

  todayDate;
  field: any;
  taxId: string = '';

  form: FormGroup;
  constructor(private authService: AuthService, private toastrService: ToastrService,) {
    this.form = new FormGroup({
      taxId: new FormControl(''),
      firstName: new FormControl('', {
        validators: [Validators.required]
      }),
      lastName: new FormControl('', {
        validators: [Validators.required]
      }),
      thirdName: new FormControl('', {
        validators: [Validators.required]
      }),
      experyDateOfdl: new FormControl('', {
        validators: [Validators.required]
      })
    });
  }

  ngOnInit(): void {
    this.getTodayDate()
  }

  update() {
    if (!this.drivingLicense) this.toastrService.warning('الرجاء تحميل الصورة');
    if (!this.identityCard) this.toastrService.warning('الرجاء تحميل الصورة');

    const data = this.form.getRawValue()
    data.drivingLicense = this.drivingLicense
    data.identityCard = this.identityCard

    this.authService.updateTransporterBusinessInfo(data)
      .pipe(take(1))
      .subscribe(
        res => {
          window.location.reload()
        }, err => {
          console.log(err)
        }
      )
  }

  getTodayDate() {

    const today = new Date();
    let dd: any = today.getDate();
    let mm: any = today.getMonth() + 1;
    const yyyy = today.getFullYear();

    if (dd < 10) {
      dd = '0' + dd;
    }

    if (mm < 10) {
      mm = '0' + mm;
    }
    this.todayDate = yyyy + '-' + mm + '-' + dd;
  }

  onFileIdentityCardChange(file) {
    this.identityCard = file.files[0];
    this.imgIdentityCardPath = file.value;
    let reader = new FileReader();
    reader.onload = () => {
      if (reader.result) {
        this.imgIdentityCardPath = reader.result;
      }
    };
    reader.readAsDataURL(this.identityCard);
  }

  onFileDriveLicenceChange(file) {
    this.drivingLicense = file.files[0];
    this.imgDrivingLicencePath = file.value;
    let reader = new FileReader();
    reader.onload = () => {
      if (reader.result) {
        this.imgDrivingLicencePath = reader.result;
      }
    };
    reader.readAsDataURL(this.drivingLicense);
  }

}
