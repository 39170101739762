<div class="container">
  <div class="row justify-content-center">
    <div class="col-lg-5">
      <div class="card bg-white shadow register-card">
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-center text-muted mb-4 text-create-account">
            <small>إنشاء حساب</small>
          </div>
          <app-stepper
            [pairingCode]="pairingCode"
            (submitStepper)="onSubmit($event)"
            [stepperConfig]="stepperConfig"
            [stepperFields]="stepperField"
            [type]="'BUYER'"></app-stepper>
        </div>
      </div>
    </div>
  </div>
</div>
