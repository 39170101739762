import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { APP_ROUTES } from 'src/app/core/config/routes';
import { LayoutService } from '../../services/layout.service';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/features/auth/services/auth.service';
import { SharedService } from '../../services/shared.service';
import { USER_TYPES } from 'src/app/core/enums';
import { SIDENAV } from 'src/app/core/config/menu';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  isMenuToggled: boolean = true;
  componentDestroyed$ = new Subject<boolean>();
  ROUTES = APP_ROUTES;
  currentSection: string;
  currentSubSection: string;
  connectedUser: any;
  USER_TYPES = USER_TYPES;
  MENU_CONFIG = SIDENAV;
  type: string;

  constructor(
    private layoutService: LayoutService,
    private router: Router,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.getMenuToggleStatus();
    this.layoutService
      .getCurrentSection()
      .subscribe((currentSection: string) => {
        this.currentSection = currentSection;
        console.log(currentSection);
      });
    this.layoutService
      .getCurrentSubSection()
      .subscribe((currentSubSection: string) => {
        this.currentSubSection = currentSubSection;
        console.log(currentSubSection);
      });
    this.connectedUser = this.sharedService.getConnectedUser();
    this.type = this.connectedUser?.type;
  }

  changeCurrentSection(currentSection: string) {
    this.layoutService.changeCurrentSection(currentSection);
    this.router.navigate([currentSection]);
  }

  changeCurrentSubSection(currentSubSection: string) {
    this.layoutService.changeCurrentSubSection(currentSubSection);
    this.router.navigate([currentSubSection]);
  }

  getMenuToggleStatus() {
    this.layoutService
      .getMenuToggled()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((isToggled: boolean) => {
        this.isMenuToggled = isToggled;
      });
  }

  hideMenu() {
    document.getElementById('body').classList.add('g-sidenav-hidden');
    document.getElementById('body').classList.remove('g-sidenav-pinned');
    document.getElementById('backdrop').classList.add('d-none');
    this.layoutService.toggleMenu();
  }

  ngOnDestroy() {
    this.componentDestroyed$.complete();
  }
}
