import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BLOB_URL, isMock } from 'src/app/core/config';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent implements OnInit {
  @Input() user: any;

  isDemoMode: boolean = isMock;
  componentDestroyed$ = new Subject<boolean>();
  dateNow = new Date()

  BASE_API = BLOB_URL;

  constructor(
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.sharedService
    .getDemoStatus()
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe((status) => {
      this.isDemoMode = status;
    });
  }
  
  isExpired(date){
    let dateExp = new Date(date)
    if(dateExp < this.dateNow){
      return true
    }else{
      return false
    }

  }

}
