import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IsConnectedGuard } from '../auth/guards/is-connected.guard';
import { InscriptionFormComponent } from './components/inscription-form/inscription-form.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';

const routes: Routes = [
  {
    path: '',
    component: LandingPageComponent,
    canActivate: [IsConnectedGuard]
  },
  {
    path: 'inscription-form',
    component: InscriptionFormComponent,

  },

  {
    path: 'home2',
    loadChildren: () =>
      import('src/app/features/private/home/home.module').then((m) => m.HomeModule)
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PublicRoutingModule {}
