import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MapsAPILoader } from '@agm/core';

import { SharedService } from '../../services/shared.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-places',
  templateUrl: './places.component.html',
  styleUrls: ['./places.component.scss']
})
export class PlacesComponent implements OnInit, OnChanges {
  @Input() placesChanged;
  @Input() long;
  @Input() lat;
  @Input() isCustom;
  @Input() customPlaces;

  places = [];
  lt: number = 21.3890824
  lg: number = 39.8579118
  geocodes;

  address;

  openItinerary(place: any) {
    const destination = `${place.latitude},${place.longitude}`;
    const url = `https://www.google.com/maps/dir/?api=1&destination=${destination}`;
    window.open(url, '_blank');
  }

  constructor(
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    if (!this.isCustom) {
      this.getPlaces();
    }
    else {
      this.places = this.customPlaces;
      //this.getAddress();
    }


  }

  getAddress() {
    console.log(this.long, this.lat)
    this.sharedService.getAddress(this.long, this.lat)
      .pipe(take(1))
      .subscribe((response: any) => {
        if (response) {
          this.address = response?.data[0];
          console.log(this.address);
        }

      })
  }

  ngOnChanges() {
    if (!this.isCustom) {
      this.getPlaces();
    }
  }

  getPlaces() {
    this.sharedService.getPlaces()
      .pipe(take(1))
      .subscribe((response: any) => {
        if (response && response?.data) {
          this.places = response?.data;
          console.log(this.places)
        }
      })
  }

}
