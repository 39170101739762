import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { validateIBAN } from 'ngx-iban-validator';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from '../../modal/service/modal.service';
import { SharedService } from '../../services/shared.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-add-account',
  templateUrl: './add-account.component.html',
  styleUrls: ['./add-account.component.scss']
})
export class AddAccountComponent implements OnInit {

  @Input() isPage: boolean;
  @Input() account;
  @Input() edit;
  @Output() onAddAccount = new EventEmitter();
  currentImage: any = 'assets/img/placeholder.png';
  file;

  form: FormGroup;

  constructor(
    private sharedService: SharedService,
    private toastr: ToastrService,
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
    this.initializeForm();
  }

  onFileUpload(fileList: FileList) {
    console.log(fileList);

    //this.currentImage =
    //console.log(this.currentImage);
    const file = fileList[0];
    this.file = file;
    const reader = new FileReader();
    reader.onload = (e) => (this.currentImage = reader.result);

    reader.readAsDataURL(file);
  }


  initializeForm() {
    const ibanNumber = this.edit && this.account ? this.account?.ibanNumber : '';
    const bankName = this.edit && this.account ? this.account?.bankName : '';
    const accountNumber = this.edit && this.account ? this.account?.accountNumber : '';
    
   if (this.edit) {
    this.form = new FormGroup({
      newIbanNumber: new FormControl(ibanNumber, {validators: [Validators.required, validateIBAN]}),
      newBankName: new FormControl(bankName, {validators: []}),
      newAccountNumber: new FormControl(accountNumber, {validators: []}),
      comment: new FormControl('', {validators: []}),
    })
   }
   else {
    this.form = new FormGroup({
      ibanNumber: new FormControl(ibanNumber, {validators: [Validators.required, validateIBAN]}),
      bankName: new FormControl(bankName, {validators: [Validators.required]}),
      accountNumber: new FormControl(accountNumber, {validators: [Validators.required]}),
    })
   }
    
  }


  uploadFile() {
    if (
      this.edit &&
      this.account?.ibanNumber == this.form.value.newIbanNumber &&
      this.account?.bankName == this.form.value.newBankName &&
      this.account?.accountNumber == this.form.value.newAccountNumber
      ) {
        return;
      }
    this.sharedService.uploadFile(this.file)
    .pipe(take(1))
    .subscribe((response: any) => {
      if (response && response?.data) {
        const fileId = response?.data?.id;
        this.addAccount(fileId);
      }
    })
  }


  addAccount(fileId: string) {
    if(!this.edit){
      if (this.form.get('ibanNumber').errors && this.form.get('ibanNumber').errors.ibanInvalid ) {
        this.toastr.error('الرجاء إدخال المعلومات المطلوبة ');
        return;
      }
    }else{
      if (this.form.get('newIbanNumber').errors && this.form.get('newIbanNumber').errors.ibanInvalid ) {
        this.toastr.error('الرجاء إدخال المعلومات المطلوبة ');
        return;
      }
    }
    

    
    
    const requestData = {
      ...this.form.value,
      attachment: fileId,
    };
    this.onAddAccount.emit(requestData);
  }

}
