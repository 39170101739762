import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { INTERNATIONAL_PHONE_CODE, PHONE_LENGTH } from 'src/app/core/config';
import { APP_ROUTES } from 'src/app/core/config/routes';
import { USER_TYPES } from 'src/app/core/enums';
import { StepperConfig, StepperFields } from 'src/app/core/types/stepper.types';
import { AuthService } from 'src/app/features/auth/services/auth.service';
import { SharedService } from '../../services/shared.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit {
  @ViewChild('btn_modal_rules') btn_modal_rules: ElementRef;

  @Input() pairingCode: string;

  @Input() stepperConfig: StepperConfig;

  @Input() stepperFields: StepperFields[];

  @Input() type;

  @Output() submitStepper = new EventEmitter<any>();

  currentStep: number = 0;
  stepForms: any[];
  formsFields: [];
  phoneNumber: string;
  email: string;
  otpCode: string = '';
  otp = new FormControl('');
  transporterTypes = USER_TYPES;
  transporterType: USER_TYPES;

  avatar;
  isLegacyAccepted = false;

  imgPath: any = 'assets/img/cam-2.png';
  imgCommercialPath: any = 'assets/img/placeholder.png';
  imgIdentityCardPath: any = 'assets/img/placeholder.png';
  imgdrivingLicensePath: any = 'assets/img/placeholder.png';

  phoneCode = INTERNATIONAL_PHONE_CODE;
  phoneLength = PHONE_LENGTH;

  commercialRegister;
  APP_ROUTES = APP_ROUTES;

  identityCard;
  drivingLicense;

  languages = [];
  nationalities = [];

  regions = [];
  cities = [];

  selectedRegion;

  todayDate;
  isOtpSent: boolean = false;;

  constructor(
    private toastr: ToastrService,
    private sharedService: SharedService,
    private authService: AuthService,

  ) { }

  ngOnInit(): void {
    this.isOtpSent = false;
    this.getTodayDate();
    this.getNationalties();
    this.getLanguages();
    this.initializeForm();
    this.getRegions();
    this.getSignUpStatus();
  }

  getSignUpStatus() {
    this.sharedService.getSignUpStatus()
      .pipe(take(1))
      .subscribe((status: string) => {
        if (status == 'RESET') {
          this.resetForm();
          this.isOtpSent = true
        }
      })
  }

  sendOtpVerification() {
    this.authService.verifyOtp(this.phoneNumber, this.email, 'BUYER')
      .pipe(take(1))
      .subscribe(
        res => {
          this.isOtpSent = true
          this.toastr.success('أرسلنا رمز التفعيل إلى الهاتف والبريد الإلكتروني')
        }
      )
  }

  getTodayDate() {

    const today = new Date();
    let dd: any = today.getDate();
    let mm: any = today.getMonth() + 1;
    const yyyy = today.getFullYear();

    if (dd < 10) {
      dd = '0' + dd;
    }

    if (mm < 10) {
      mm = '0' + mm;
    }
    this.todayDate = yyyy + '-' + mm + '-' + dd;
  }

  onFileInput(file) {
    this.avatar = file.files[0];
    this.imgPath = file.value;
    let reader = new FileReader();
    reader.onload = () => {
      if (reader.result) {
        this.imgPath = reader.result;
      }
    };
    reader.readAsDataURL(this.avatar);
  }

  onFileCommercialRegisterChange(file) {
    console.log('alaa')
    this.commercialRegister = file.files[0];
    this.imgCommercialPath = file.value;
    let reader = new FileReader();
    reader.onload = () => {
      if (reader.result) {
        this.imgCommercialPath = reader.result;
      }
    };
    reader.readAsDataURL(this.commercialRegister);
  }

  onFileIdentityCardChange(file) {
    this.identityCard = file.files[0];
    this.imgIdentityCardPath = file.value;
    let reader = new FileReader();
    reader.onload = () => {
      if (reader.result) {
        this.imgIdentityCardPath = reader.result;
      }
    };
    reader.readAsDataURL(this.identityCard);
  }

  onFiledrivingLicense(file) {
    this.drivingLicense = file.files[0];
    this.imgdrivingLicensePath = file.value;
    let reader = new FileReader();
    reader.onload = () => {
      if (reader.result) {
        this.imgdrivingLicensePath = reader.result;
      }
    };
    reader.readAsDataURL(this.drivingLicense);
  }

  onChangeCheck() {
    this.isLegacyAccepted = !this.isLegacyAccepted;
  }

  onSubmitStep() {

    console.log(this.stepForms[this.currentStep]);
    this.sharedService.changeSignUpStatus('START');
    if (this.currentStep + 1 === this.stepperConfig.number) {
      if (!this.isLegacyAccepted) {
        this.toastr.error('لا يمكنك إنشاء حساب بدون الموافقة على الشروط والأحكام ');
        return;
      }
      let formValid = true;
      this.stepForms.forEach((item) => {
        if (item.form.invalid) {
          console.log(item.form);
          formValid = false;
        }
      });

      if (formValid) {
        console.log('OK SUBMITTED');
        let finalResult: any = {};
        this.stepForms.forEach((item) => {
          finalResult = {
            ...finalResult,
            ...item.form.value
          };
        });
        finalResult = {
          ...finalResult,
          avatar: this.avatar,
          commercialRegister: this.commercialRegister,
          identityCard: this.identityCard,
          drivingLicense: this.drivingLicense,
          legacyAccepted: this.isLegacyAccepted,
          phoneNumber: this.phoneCode + finalResult.phoneNumber,
          type: this.stepperConfig.type
          // otp: this.otpCode
        };
        if ((this.type == 'BUYER_SELLER' || this.type == 'BUYER' || this.type == 'SELLER') && this.pairingCode) {
          finalResult = {
            ...finalResult,
            pairingCode: this.pairingCode
          }
        }
        console.log(finalResult)



        this.submitStepper.emit(finalResult);
        //this.resetForm();
      } else {
        this.toastr.warning('يرجى إدخال جميع المعلومات المطلوبة ');

      }
    } else {
      this.currentStep++;
    }
  }

  resetForm() {
    this.stepForms.forEach(form => {
      form?.form.reset();
    })
  }

  backStep() {
    if (this.currentStep === 0) {
      return;
    }
    this.currentStep--;
  }

  initializeForm() {

    console.error(this.stepperConfig)

    this.stepForms = [];
    let current = 0;
    for (let i = 0; i < this.stepperConfig.number; i++) {
      const stepForm = new FormGroup({});
      const formFields = [];
      for (let j = 0; j < this.stepperConfig.config[i].size; j++) {
        if (this.stepperFields[current]) {
          const control = this.stepperFields[current];
          stepForm.addControl(control.name, control.control);
          formFields.push(control);
        }
        current++;
      }
      const formConfig = {
        form: stepForm,
        fields: formFields
      };
      this.stepForms.push(formConfig);
    }
    if (this.stepperConfig.type === USER_TYPES.TRANSPORTER_AGENT) {
      this.stepForms.forEach((form) => {
        if (form.form.controls.hasOwnProperty('type')) {
          form.form.controls['type'].setValue(USER_TYPES.TRANSPORTER_AGENT);
          this.transporterType = USER_TYPES.TRANSPORTER_AGENT;
          form.form.controls['type'].valueChanges.subscribe((value: USER_TYPES) => {
            this.transporterType = USER_TYPES.TRANSPORTER_AGENT;
          })
        }
      });
    }

    this.stepForms.forEach((form) => {
      if (form.form.controls.hasOwnProperty('region')) {

        form.form.controls['region'].valueChanges.subscribe((value) => {
          this.selectedRegion = value;
          if (this.selectedRegion) {
            this.getCities();
          }
        })
      }
    });
  }

  getLanguages() {
    this.sharedService.getLanguages()
      .pipe(take(1))
      .subscribe((response: any) => {
        if (response) {
          this.languages = response?.data;
          this.languages.forEach((language) => {
            const item = {
              key: language?.key,
              value: language?.key
            };
            const languageIndex = this.stepperFields.findIndex(
              (field) => field?.name === 'language'
            );
            if (languageIndex !== -1) {
              this.stepperFields[languageIndex].childs.push(item);
            }
          });
        }
      });
  }

  getNationalties() {
    this.sharedService.getNationalities()
      .pipe(take(1))
      .subscribe((response: any) => {
        if (response) {
          this.nationalities = response?.data;
          this.nationalities.forEach((nationality) => {
            const item = {
              key: nationality?.key,
              value: nationality?.key
            };
            const nationalityIndex = this.stepperFields.findIndex(
              (field) => field?.name === 'nationality'
            );
            if (nationalityIndex !== -1) {
              this.stepperFields[nationalityIndex].childs.push(item);
            }
          });
        }
      });
  }


  getRegions() {
    this.sharedService.getRegions()
      .pipe(take(1))
      .subscribe((response: any) => {
        if (response) {
          this.regions = response?.data;
        }
      });
  }


  getCities() {
    this.sharedService.getCities(this.selectedRegion)
      .pipe(take(1))
      .subscribe((response: any) => {
        if (response) {
          this.cities = response?.data;
          const cityIndex = this.stepperFields.findIndex(
            (field) => field?.name === 'city'
          );
          this.stepperFields[cityIndex].childs = [];
          this.cities.forEach((city) => {
            const item = {
              key: city?.key,
              value: city?.id
            };

            if (cityIndex !== -1) {
              this.stepperFields[cityIndex].childs.push(item);
            }
          });
        }
      });
  }

  openRulesModal() {
    this.btn_modal_rules.nativeElement.click();
  }
}
