import { AgmCoreModule } from '@agm/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { NgxSpinnerModule } from 'ngx-spinner';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './features/auth/auth.module';
import { AuthInterceptor } from './features/private/interceptors/auth.interceptor';
import { PublicModule } from './features/public/public.module';
import { HttpMockInterceptor } from './shared/interceptors/http.interceptor';
import { DataService } from './shared/services/data.service';
import { SharedModule } from './shared/shared.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { PullToRefreshComponent } from './pull-to-refresh/pull-to-refresh.component';
import { NgImageSliderModule } from 'ng-image-slider';

@NgModule({
  declarations: [AppComponent, PullToRefreshComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AuthModule,
    SharedModule,
    NgxSpinnerModule,
    PublicModule,
    NgImageSliderModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDtrTzC8evG8i7SjFqNMks7p6ALESEwwO8',
      libraries: ['places']
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:10'
    }),
  ],
  providers: [
    Meta,
    DataService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpMockInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
