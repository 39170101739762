<div class="alert alert-secondary alert-dismissible fade show" role="alert">
  <div class="container-alert d-flex text-right align-items-center">
    <button
      type="button"
      class="btn btn-warning"
      data-dismiss="alert"
      aria-label="Close"
      (click)="changeDemoMode()"
    >
      الخروج
    </button>
    <div class="d-flex align-items-center">
      <span class="alert-text fs-18"
        ><strong class="fs-20">وضع التجريب</strong> يمكنك إدخال معلومات خاطئة
        لتصفح الموقع</span
      >
      <span class="alert-icon"><i class="ni ni-like-2 ml-3"></i></span>
    </div>
  </div>
</div>
