import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { user } from 'src/app/core/mock/user';

@Injectable({
  providedIn: 'root'
})
export class SellerService {
  seller: any;

  constructor(private httpClient: HttpClient) {}

  getSeller(userId: string) {
    if (this.seller) {
      return this.seller;
    } else {
      return of(user); // TODO will be changed when API is ready
    }
  }

  setSeller(seller: any) {
    this.seller = seller;
  }
}
