import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { LoginComponent } from './components/login/login.component';
import { OtpComponent } from './components/otp/otp.component';
import { RegisterMainComponent } from './components/register-main/register-main.component';
import { IsConnectedGuard } from './guards/is-connected.guard';
import { InternalLoginComponent } from './components/internal-login/internal-login.component';
import { RegisterOtpComponent } from './components/register-otp/register-otp.component';

const routes: Routes = [
  {
    path: 'auth/register',
    component: RegisterMainComponent,
    canActivate: [IsConnectedGuard]
  },
  {
    path: 'auth/login-intern',
    component: InternalLoginComponent,
    canActivate: [IsConnectedGuard]
  },
  {
    path: 'auth/login',
    component: LoginComponent,
    canActivate: [IsConnectedGuard]
  },

  {
    path: 'auth/otp',
    component: OtpComponent,
    canActivate: [IsConnectedGuard]
  },
  {
    path: 'auth/register-otp',
    component: RegisterOtpComponent,
    canActivate: [IsConnectedGuard]
  },
  {
    path: 'auth/forget-password',
    component: ForgetPasswordComponent,
    canActivate: [IsConnectedGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
