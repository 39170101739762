<div class="container pt-lg-7">
    <div class="row justify-content-center">
      <div class="col-lg-5">
        <div class="login-card">
          <div class="card-header bg-white pb-5"></div>
          <div class="card-body px-lg-5 py-lg-5 mt-3">
            <div class="text-center text-muted text-login">
              <div class="text-login_welcome main-font_light">
                إعادة ضبط كلمة المرور
              </div>
              <div *ngIf="sendCodeStep" class="text-login_desc">
                <small>
                  الرجاء إدخال رقم هاتفك لتلقي رمز إعادة تعيين كلمة المرور
                </small>
              </div>
            </div>
            
            <form *ngIf="sendCodeStep" class="login-form" role="form">
              <div class="form-group mb-3">
                <div class="input-group input-group-alternative">
                  <label class="login-form_phone-code"> {{ phoneCode }} </label>
                  <input
                    class="form-control"
                    style="letter-spacing: 10px"
              
                    type="text"
                    [(ngModel)]="phoneNumber"
                    pattern="\d*"
                    
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
              </div>
              <div class="text-center">
                
                
              </div>
  
              <div class="text-center login-form_bottom">
                <button
                  type="button"
                  class="btn btn-primary my-4"
                  (click)="sendCode()"
                 
                >
                  إرسال
                </button>
              </div>
            </form>
            <!---->

            <div *ngIf="verifyCodeStep" class="text-login_desc">
              <small>
                الرجاء إدخال رمز التحقق
              </small>
            </div>
       
          
          <form *ngIf="verifyCodeStep" class="login-form" role="form">
            <div class="form-group mb-3">
              <div class="input-group input-group-alternative">
               
                <input
                  class="form-control"
                  style="letter-spacing: 10px"
            
                  type="text"
                  [(ngModel)]="resetCode"
                  pattern="\d*"
                  
                  [ngModelOptions]="{ standalone: true }"
                />
              </div>
            </div>
            <div class="text-center">
              
              
            </div>

            <div class="text-center login-form_bottom">
              <button
                type="button"
                class="btn btn-primary my-4"
                (click)="verifyCode()"
               
              >
                إرسال
              </button>
            </div>
          </form>

          <!---->

          <div *ngIf="resetPasswordStep" class="text-login_desc">
            <small>
              الرجاء إدخال كلمة المرور الجديدة الخاصة بك
            </small>
          </div>
     
        
        <form *ngIf="resetPasswordStep" class="login-form" role="form">
          <div dir="rtl" class="form-group mb-3">
            <div class="input-group input-group-alternative">
              <label>كلمة المرور الجديدة</label>
            </div>

            <div class="input-group input-group-alternative">
              <input
              class="form-control"
              style="letter-spacing: 10px"
        
              type="password"
              [(ngModel)]="password"
              pattern="\d*"
              
              [ngModelOptions]="{ standalone: true }"
            />
            </div>

            <div class="input-group input-group-alternative">
              <label>تأكيد كلمة المرور</label>
            </div>

            <div class="input-group input-group-alternative">
              <input
                class="form-control"
                style="letter-spacing: 10px"
          
                type="password"
                [(ngModel)]="confirmPassword"
                pattern="\d*"
                
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
            
          </div>
          <div class="text-center">
            
            
          </div>

          <div class="text-center login-form_bottom">
            <button
              type="button"
              class="btn btn-primary my-4"
              (click)="resetPassword()"
             
            >
            تحديث
            </button>
          </div>
        </form>
            
          </div>
        </div>
      </div>
    </div>
  </div>