export const product_types = [
  {
    id: '4a870fe1-004c-469c-9b5d-e29a6cc30db1',
    key: 'نوع رفيع',
    createdAt: '2021-07-22T17:33:45.270Z',
    updatedAt: '2021-07-22T17:33:45.270Z'
  }
];

export const product_units = [
  {
    id: 'a358a0af-b33e-40c7-bc52-19c8511e4711',
    key: 'طن',
    createdAt: '2021-07-22T17:37:05.556Z',
    updatedAt: '2021-07-22T17:37:05.556Z'
  },
  {
    id: 'f0aa61f1-0cb7-48b2-8462-4794de5b0c01',
    key: 'كغ',
    createdAt: '2021-07-22T17:37:07.697Z',
    updatedAt: '2021-07-22T17:37:07.697Z'
  }
];

export const product_packages = [
  {
    id: 'c78173a3-faed-4331-9863-c37191a11fca',
    key: 'تغليف 1',
    createdAt: '2021-07-22T20:31:36.157Z',
    updatedAt: '2021-07-22T20:31:36.157Z'
  },
  {
    id: 'e32e62cd-2ef7-4e8c-ae6f-a209efe45d57',
    key: 'تغليف 2',
    createdAt: '2021-07-22T20:31:45.396Z',
    updatedAt: '2021-07-22T20:31:45.396Z'
  }
];

export const product_qualities = [
  {
    id: '4b6deb12-582c-4cc4-a784-1b261c5a06f2',
    key: 'جودة Y',
    createdAt: '2021-08-02T10:45:05.487Z',
    updatedAt: '2021-08-02T10:45:05.487Z'
  },
  {
    id: '5c871bc4-735a-49ad-bb84-35634e8fee3c',
    key: 'جودة X ',
    createdAt: '2021-07-26T17:28:26.320Z',
    updatedAt: '2021-07-26T17:28:26.320Z'
  },
  {
    id: 'a7750811-cb24-48cf-aaa9-c743bfcaeaad',
    key: 'جودة Z',
    createdAt: '2021-08-02T10:45:12.222Z',
    updatedAt: '2021-08-02T10:45:12.222Z'
  }
];

export const product_countries = [
  {
    id: '01da14e2-c627-45c2-b4e1-1f4a14e0715a',
    key: 'هولندا',
    createdAt: '2021-08-06T13:39:35.827Z',
    updatedAt: '2021-08-06T13:39:35.827Z'
  },
  {
    id: '268a783b-c031-490b-ba57-78acf9eb6aa2',
    key: 'تونس',
    createdAt: '2021-08-06T13:38:57.935Z',
    updatedAt: '2021-08-06T13:38:57.935Z'
  },
  {
    id: '69ed1ebc-1ed3-4df4-9d9f-356fe1b5ac31',
    key: 'ليبيا',
    createdAt: '2021-08-06T13:39:05.706Z',
    updatedAt: '2021-08-06T13:39:05.706Z'
  },
  {
    id: '8073f864-0055-4277-b759-5830ff4078b6',
    key: 'إسبانيا',
    createdAt: '2021-08-06T13:39:12.232Z',
    updatedAt: '2021-08-06T13:39:12.232Z'
  },
  {
    id: '83ec1f3e-9d80-490a-9c03-1cf566540402',
    key: 'كينيا',
    createdAt: '2021-08-06T13:39:25.616Z',
    updatedAt: '2021-08-06T13:39:25.616Z'
  },
  {
    id: 'b7ab9506-6e70-447b-a920-5ee95a826e50',
    key: 'البرازيل',
    createdAt: '2021-08-06T13:39:19.072Z',
    updatedAt: '2021-08-06T13:39:19.072Z'
  }
];

export const product = {
  id: '1999',
  name: 'فراولة هولندية',
  rate: 0,
  details:
    'لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور',

  wholeQuantity: 200,
  currentQuantity: 140,
  minimumPurchaseQuantity: 55,
  unitPrice: 44,
  address: '7 شارع الشيخ بن نعمان',
  origin: 'LOCAL',
  originalCountry: null,
  customsNumber: null,
  marketName: 'اسم الماركة',
  capacity: 'Alias qui hic pariat',
  region: 'حي الوحدة',
  city: 'الرياض',
  audioLength: null,
  createdAt: '2021-07-28T18:19:26.451Z',
  updatedAt: '2021-07-28T18:19:26.451Z',
  images: [
    {
      id: '0031db7c-a0bd-4a04-ae3d-9d204c37070e',
      mimetype: 'image/png',
      filename: 'file-1627496365598.png',
      extension: 'png',
      size: 215074,
      protected_url: '/files/file-1627496365598.png/download',
      public_url: 'assets/img/mocks/stw.jpg',
      createdAt: '2021-07-28T18:19:25.603Z',
      updatedAt: '2021-07-28T18:19:26.000Z'
    },
    {
      id: 'b67832a2-d8c0-4ca0-b203-2828098ce8f1',
      mimetype: 'image/png',
      filename: 'file-1627496364805.png',
      extension: 'png',
      size: 206331,
      protected_url: '/files/file-1627496364805.png/download',
      public_url: 'assets/img/mocks/stw.jpg',
      createdAt: '2021-07-28T18:19:24.811Z',
      updatedAt: '2021-07-28T18:19:26.000Z'
    }
  ],
  audio: {
    id: '9baeaf5e-182a-4ac6-b38b-14e05275b31c',
    mimetype: 'audio/wav',
    filename: 'file-1627496365810.wav',
    extension: 'wav',
    size: 270380,
    protected_url: '/files/file-1627496365810.wav/download',
    public_url: '/public/uploads/file-1627496365810.wav',
    createdAt: '2021-07-28T18:19:25.816Z',
    updatedAt: '2021-07-28T18:19:25.816Z'
  },
  unit: {
    id: 'a358a0af-b33e-40c7-bc52-19c8511e4711',
    key: 'طن',
    createdAt: '2021-07-22T17:37:05.556Z',
    updatedAt: '2021-07-22T17:37:05.556Z'
  },
  type: {
    id: '4a870fe1-004c-469c-9b5d-e29a6cc30db1',
    key: 'نوع رفيع',
    createdAt: '2021-07-22T17:33:45.270Z',
    updatedAt: '2021-07-22T17:33:45.270Z'
  },
  owner: {
    id: '3d8069c5- 0779-42cb- b563 - 9b48828519ee',
    email: 'sadokmhiri@gmail.com',
    firstName: 'زينب',
    lastName: 'بالحاج',
    phoneNumber: '+21620166525',
    city: 'Sousse',
    postalCode: '4011',
    address: '7 Rue Bouali Amine Sousse',
    type: 'BUYER_SELLER',
    createdAt: '2021-07-09T15:35:22.154Z',
    updatedAt: '2021-07-29T10:27:38.000Z',
    experyDateOfcr: '2021-07-11T00:00:00.000Z',
    avatar: {
      id: 'b4ce12a0-f53f-4e88-8918-81c0d1356c3d',
      mimetype: 'image/png',
      filename: 'file-1625844922053.png',
      extension: 'png',
      size: 4709,
      protected_url: '/files/file-1625844922053.png/download',
      public_url: 'assets/img/mocks/man.png',
      createdAt: '2021-07-09T15:35:22.120Z',
      updatedAt: '2021-07-09T15:35:22.120Z'
    }
  }
};

export const products = [
  {
    id: '1999',
    name: 'فراولة هولندية',
    rate: 0,
    details:
      'لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور',
    wholeQuantity: 200,
    currentQuantity: 140,
    minimumPurchaseQuantity: 55,
    unitPrice: 44,
    address: '7 شارع الشيخ بن نعمان',
    origin: 'LOCAL',
    originalCountry: null,
    customsNumber: null,
    marketName: 'اسم الماركة',
    capacity: 'Alias qui hic pariat',
    region: 'حي الوحدة',
    city: 'الرياض',
    audioLength: null,
    createdAt: '2021-07-28T18:19:26.451Z',
    updatedAt: '2021-07-28T18:19:26.451Z',
    images: [
      {
        id: '0031db7c-a0bd-4a04-ae3d-9d204c37070e',
        mimetype: 'image/png',
        filename: 'file-1627496365598.png',
        extension: 'png',
        size: 215074,
        protected_url: '/files/file-1627496365598.png/download',
        public_url: 'assets/img/mocks/stw.jpg',
        createdAt: '2021-07-28T18:19:25.603Z',
        updatedAt: '2021-07-28T18:19:26.000Z'
      },
      {
        id: 'b67832a2-d8c0-4ca0-b203-2828098ce8f1',
        mimetype: 'image/png',
        filename: 'file-1627496364805.png',
        extension: 'png',
        size: 206331,
        protected_url: '/files/file-1627496364805.png/download',
        public_url: 'assets/img/mocks/stw.jpg',
        createdAt: '2021-07-28T18:19:24.811Z',
        updatedAt: '2021-07-28T18:19:26.000Z'
      }
    ],
    audio: {
      id: '9baeaf5e-182a-4ac6-b38b-14e05275b31c',
      mimetype: 'audio/wav',
      filename: 'file-1627496365810.wav',
      extension: 'wav',
      size: 270380,
      protected_url: '/files/file-1627496365810.wav/download',
      public_url: '/public/uploads/file-1627496365810.wav',
      createdAt: '2021-07-28T18:19:25.816Z',
      updatedAt: '2021-07-28T18:19:25.816Z'
    },
    unit: {
      id: 'a358a0af-b33e-40c7-bc52-19c8511e4711',
      key: 'طن',
      createdAt: '2021-07-22T17:37:05.556Z',
      updatedAt: '2021-07-22T17:37:05.556Z'
    },
    type: {
      id: '4a870fe1-004c-469c-9b5d-e29a6cc30db1',
      key: 'نوع رفيع',
      createdAt: '2021-07-22T17:33:45.270Z',
      updatedAt: '2021-07-22T17:33:45.270Z'
    },
    owner: {
      id: '3d8069c5- 0779-42cb- b563 - 9b48828519ee',
      email: 'sadokmhiri@gmail.com',
      firstName: 'محمد',
      lastName: 'علي',
      phoneNumber: '+21620166525',
      city: 'Sousse',
      postalCode: '4011',
      address: '7 Rue Bouali Amine Sousse',
      type: 'BUYER_SELLER',
      createdAt: '2021-07-09T15:35:22.154Z',
      updatedAt: '2021-07-29T10:27:38.000Z',
      experyDateOfcr: '2021-07-11T00:00:00.000Z',
      avatar: {
        id: 'b4ce12a0-f53f-4e88-8918-81c0d1356c3d',
        mimetype: 'image/png',
        filename: 'file-1625844922053.png',
        extension: 'png',
        size: 4709,
        protected_url: '/files/file-1625844922053.png/download',
        public_url: 'assets/img/mocks/man.png',
        createdAt: '2021-07-09T15:35:22.120Z',
        updatedAt: '2021-07-09T15:35:22.120Z'
      }
    }
  },
  {
    id: '1999',
    name: 'فراولة صغيرة',
    rate: 0,
    details:
      'لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور',
    wholeQuantity: 200,
    currentQuantity: 140,
    minimumPurchaseQuantity: 55,
    unitPrice: 26,
    address: '7 شارع الشيخ بن نعمان',
    origin: 'LOCAL',
    originalCountry: null,
    customsNumber: null,
    marketName: 'اسم الماركة',
    capacity: 'Alias qui hic pariat',
    region: 'حي الوحدة',
    city: 'الرياض',
    audioLength: null,
    createdAt: '2021-07-28T18:19:26.451Z',
    updatedAt: '2021-07-28T18:19:26.451Z',
    images: [
      {
        id: '0031db7c-a0bd-4a04-ae3d-9d204c37070e',
        mimetype: 'image/png',
        filename: 'file-1627496365598.png',
        extension: 'png',
        size: 215074,
        protected_url: '/files/file-1627496365598.png/download',
        public_url: 'assets/img/mocks/stw.jpg',
        createdAt: '2021-07-28T18:19:25.603Z',
        updatedAt: '2021-07-28T18:19:26.000Z'
      },
      {
        id: 'b67832a2-d8c0-4ca0-b203-2828098ce8f1',
        mimetype: 'image/png',
        filename: 'file-1627496364805.png',
        extension: 'png',
        size: 206331,
        protected_url: '/files/file-1627496364805.png/download',
        public_url: 'assets/img/mocks/stw.jpg',
        createdAt: '2021-07-28T18:19:24.811Z',
        updatedAt: '2021-07-28T18:19:26.000Z'
      }
    ],
    audio: {
      id: '9baeaf5e-182a-4ac6-b38b-14e05275b31c',
      mimetype: 'audio/wav',
      filename: 'file-1627496365810.wav',
      extension: 'wav',
      size: 270380,
      protected_url: '/files/file-1627496365810.wav/download',
      public_url: '/public/uploads/file-1627496365810.wav',
      createdAt: '2021-07-28T18:19:25.816Z',
      updatedAt: '2021-07-28T18:19:25.816Z'
    },
    unit: {
      id: 'a358a0af-b33e-40c7-bc52-19c8511e4711',
      key: 'طن',
      createdAt: '2021-07-22T17:37:05.556Z',
      updatedAt: '2021-07-22T17:37:05.556Z'
    },
    type: {
      id: '4a870fe1-004c-469c-9b5d-e29a6cc30db1',
      key: 'نوع رفيع',
      createdAt: '2021-07-22T17:33:45.270Z',
      updatedAt: '2021-07-22T17:33:45.270Z'
    },
    owner: {
      id: '3d8069c5- 0779-42cb- b563 - 9b48828519ee',
      email: 'sadokmhiri@gmail.com',
      firstName: 'محمد',
      lastName: 'علي',
      phoneNumber: '+21620166525',
      city: 'Sousse',
      postalCode: '4011',
      address: '7 Rue Bouali Amine Sousse',
      type: 'BUYER_SELLER',
      createdAt: '2021-07-09T15:35:22.154Z',
      updatedAt: '2021-07-29T10:27:38.000Z',
      experyDateOfcr: '2021-07-11T00:00:00.000Z',
      avatar: {
        id: 'b4ce12a0-f53f-4e88-8918-81c0d1356c3d',
        mimetype: 'image/png',
        filename: 'file-1625844922053.png',
        extension: 'png',
        size: 4709,
        protected_url: '/files/file-1625844922053.png/download',
        public_url: 'assets/img/mocks/man.png',
        createdAt: '2021-07-09T15:35:22.120Z',
        updatedAt: '2021-07-09T15:35:22.120Z'
      }
    }
  },
  {
    id: '1999',
    name: 'فراولة هولندية',
    rate: 0,
    details:
      'لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور',
    wholeQuantity: 200,
    currentQuantity: 140,
    minimumPurchaseQuantity: 55,
    unitPrice: 44,
    address: '7 شارع الشيخ بن نعمان',
    origin: 'LOCAL',
    originalCountry: null,
    customsNumber: null,
    marketName: 'اسم الماركة',
    capacity: 'Alias qui hic pariat',
    region: 'حي الوحدة',
    city: 'الرياض',
    audioLength: null,
    createdAt: '2021-07-28T18:19:26.451Z',
    updatedAt: '2021-07-28T18:19:26.451Z',
    images: [
      {
        id: '0031db7c-a0bd-4a04-ae3d-9d204c37070e',
        mimetype: 'image/png',
        filename: 'file-1627496365598.png',
        extension: 'png',
        size: 215074,
        protected_url: '/files/file-1627496365598.png/download',
        public_url: 'assets/img/mocks/stw.jpg',
        createdAt: '2021-07-28T18:19:25.603Z',
        updatedAt: '2021-07-28T18:19:26.000Z'
      },
      {
        id: 'b67832a2-d8c0-4ca0-b203-2828098ce8f1',
        mimetype: 'image/png',
        filename: 'file-1627496364805.png',
        extension: 'png',
        size: 206331,
        protected_url: '/files/file-1627496364805.png/download',
        public_url: 'assets/img/mocks/stw.jpg',
        createdAt: '2021-07-28T18:19:24.811Z',
        updatedAt: '2021-07-28T18:19:26.000Z'
      }
    ],
    audio: {
      id: '9baeaf5e-182a-4ac6-b38b-14e05275b31c',
      mimetype: 'audio/wav',
      filename: 'file-1627496365810.wav',
      extension: 'wav',
      size: 270380,
      protected_url: '/files/file-1627496365810.wav/download',
      public_url: '/public/uploads/file-1627496365810.wav',
      createdAt: '2021-07-28T18:19:25.816Z',
      updatedAt: '2021-07-28T18:19:25.816Z'
    },
    unit: {
      id: 'a358a0af-b33e-40c7-bc52-19c8511e4711',
      key: 'طن',
      createdAt: '2021-07-22T17:37:05.556Z',
      updatedAt: '2021-07-22T17:37:05.556Z'
    },
    type: {
      id: '4a870fe1-004c-469c-9b5d-e29a6cc30db1',
      key: 'نوع رفيع',
      createdAt: '2021-07-22T17:33:45.270Z',
      updatedAt: '2021-07-22T17:33:45.270Z'
    },
    owner: {
      id: '3d8069c5- 0779-42cb- b563 - 9b48828519ee',
      email: 'sadokmhiri@gmail.com',
      firstName: 'محمد',
      lastName: 'علي',
      phoneNumber: '+21620166525',
      city: 'Sousse',
      postalCode: '4011',
      address: '7 Rue Bouali Amine Sousse',
      type: 'BUYER_SELLER',
      createdAt: '2021-07-09T15:35:22.154Z',
      updatedAt: '2021-07-29T10:27:38.000Z',
      experyDateOfcr: '2021-07-11T00:00:00.000Z',
      avatar: {
        id: 'b4ce12a0-f53f-4e88-8918-81c0d1356c3d',
        mimetype: 'image/png',
        filename: 'file-1625844922053.png',
        extension: 'png',
        size: 4709,
        protected_url: '/files/file-1625844922053.png/download',
        public_url: 'assets/img/mocks/man.png',
        createdAt: '2021-07-09T15:35:22.120Z',
        updatedAt: '2021-07-09T15:35:22.120Z'
      }
    }
  },
  {
    id: '1999',
    name: 'فراولة صغيرة',
    rate: 0,
    details:
      'لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور',
    wholeQuantity: 200,
    currentQuantity: 140,
    minimumPurchaseQuantity: 55,
    unitPrice: 26,
    address: '7 شارع الشيخ بن نعمان',
    origin: 'LOCAL',
    originalCountry: null,
    customsNumber: null,
    marketName: 'اسم الماركة',
    capacity: 'Alias qui hic pariat',
    region: 'حي الوحدة',
    city: 'الرياض',
    audioLength: null,
    createdAt: '2021-07-28T18:19:26.451Z',
    updatedAt: '2021-07-28T18:19:26.451Z',
    images: [
      {
        id: '0031db7c-a0bd-4a04-ae3d-9d204c37070e',
        mimetype: 'image/png',
        filename: 'file-1627496365598.png',
        extension: 'png',
        size: 215074,
        protected_url: '/files/file-1627496365598.png/download',
        public_url: 'assets/img/mocks/stw.jpg',
        createdAt: '2021-07-28T18:19:25.603Z',
        updatedAt: '2021-07-28T18:19:26.000Z'
      },
      {
        id: 'b67832a2-d8c0-4ca0-b203-2828098ce8f1',
        mimetype: 'image/png',
        filename: 'file-1627496364805.png',
        extension: 'png',
        size: 206331,
        protected_url: '/files/file-1627496364805.png/download',
        public_url: 'assets/img/mocks/stw.jpg',
        createdAt: '2021-07-28T18:19:24.811Z',
        updatedAt: '2021-07-28T18:19:26.000Z'
      }
    ],
    audio: {
      id: '9baeaf5e-182a-4ac6-b38b-14e05275b31c',
      mimetype: 'audio/wav',
      filename: 'file-1627496365810.wav',
      extension: 'wav',
      size: 270380,
      protected_url: '/files/file-1627496365810.wav/download',
      public_url: '/public/uploads/file-1627496365810.wav',
      createdAt: '2021-07-28T18:19:25.816Z',
      updatedAt: '2021-07-28T18:19:25.816Z'
    },
    unit: {
      id: 'a358a0af-b33e-40c7-bc52-19c8511e4711',
      key: 'طن',
      createdAt: '2021-07-22T17:37:05.556Z',
      updatedAt: '2021-07-22T17:37:05.556Z'
    },
    type: {
      id: '4a870fe1-004c-469c-9b5d-e29a6cc30db1',
      key: 'نوع رفيع',
      createdAt: '2021-07-22T17:33:45.270Z',
      updatedAt: '2021-07-22T17:33:45.270Z'
    },
    owner: {
      id: '3d8069c5- 0779-42cb- b563 - 9b48828519ee',
      email: 'sadokmhiri@gmail.com',
      firstName: 'محمد',
      lastName: 'علي',
      phoneNumber: '+21620166525',
      city: 'Sousse',
      postalCode: '4011',
      address: '7 Rue Bouali Amine Sousse',
      type: 'BUYER_SELLER',
      createdAt: '2021-07-09T15:35:22.154Z',
      updatedAt: '2021-07-29T10:27:38.000Z',
      experyDateOfcr: '2021-07-11T00:00:00.000Z',
      avatar: {
        id: 'b4ce12a0-f53f-4e88-8918-81c0d1356c3d',
        mimetype: 'image/png',
        filename: 'file-1625844922053.png',
        extension: 'png',
        size: 4709,
        protected_url: '/files/file-1625844922053.png/download',
        public_url: 'assets/img/mocks/man.png',
        createdAt: '2021-07-09T15:35:22.120Z',
        updatedAt: '2021-07-09T15:35:22.120Z'
      }
    }
  },
  {
    id: '1999',
    name: 'فراولة هولندية',
    rate: 0,
    details:
      'لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور',
    wholeQuantity: 200,
    currentQuantity: 140,
    minimumPurchaseQuantity: 55,
    unitPrice: 44,
    address: '7 شارع الشيخ بن نعمان',
    origin: 'LOCAL',
    originalCountry: null,
    customsNumber: null,
    marketName: 'اسم الماركة',
    capacity: 'Alias qui hic pariat',
    region: 'حي الوحدة',
    city: 'الرياض',
    audioLength: null,
    createdAt: '2021-07-28T18:19:26.451Z',
    updatedAt: '2021-07-28T18:19:26.451Z',
    images: [
      {
        id: '0031db7c-a0bd-4a04-ae3d-9d204c37070e',
        mimetype: 'image/png',
        filename: 'file-1627496365598.png',
        extension: 'png',
        size: 215074,
        protected_url: '/files/file-1627496365598.png/download',
        public_url: 'assets/img/mocks/stw.jpg',
        createdAt: '2021-07-28T18:19:25.603Z',
        updatedAt: '2021-07-28T18:19:26.000Z'
      },
      {
        id: 'b67832a2-d8c0-4ca0-b203-2828098ce8f1',
        mimetype: 'image/png',
        filename: 'file-1627496364805.png',
        extension: 'png',
        size: 206331,
        protected_url: '/files/file-1627496364805.png/download',
        public_url: 'assets/img/mocks/stw.jpg',
        createdAt: '2021-07-28T18:19:24.811Z',
        updatedAt: '2021-07-28T18:19:26.000Z'
      }
    ],
    audio: {
      id: '9baeaf5e-182a-4ac6-b38b-14e05275b31c',
      mimetype: 'audio/wav',
      filename: 'file-1627496365810.wav',
      extension: 'wav',
      size: 270380,
      protected_url: '/files/file-1627496365810.wav/download',
      public_url: '/public/uploads/file-1627496365810.wav',
      createdAt: '2021-07-28T18:19:25.816Z',
      updatedAt: '2021-07-28T18:19:25.816Z'
    },
    unit: {
      id: 'a358a0af-b33e-40c7-bc52-19c8511e4711',
      key: 'طن',
      createdAt: '2021-07-22T17:37:05.556Z',
      updatedAt: '2021-07-22T17:37:05.556Z'
    },
    type: {
      id: '4a870fe1-004c-469c-9b5d-e29a6cc30db1',
      key: 'نوع رفيع',
      createdAt: '2021-07-22T17:33:45.270Z',
      updatedAt: '2021-07-22T17:33:45.270Z'
    },
    owner: {
      id: '3d8069c5- 0779-42cb- b563 - 9b48828519ee',
      email: 'sadokmhiri@gmail.com',
      firstName: 'محمد',
      lastName: 'علي',
      phoneNumber: '+21620166525',
      city: 'Sousse',
      postalCode: '4011',
      address: '7 Rue Bouali Amine Sousse',
      type: 'BUYER_SELLER',
      createdAt: '2021-07-09T15:35:22.154Z',
      updatedAt: '2021-07-29T10:27:38.000Z',
      experyDateOfcr: '2021-07-11T00:00:00.000Z',
      avatar: {
        id: 'b4ce12a0-f53f-4e88-8918-81c0d1356c3d',
        mimetype: 'image/png',
        filename: 'file-1625844922053.png',
        extension: 'png',
        size: 4709,
        protected_url: '/files/file-1625844922053.png/download',
        public_url: 'assets/img/mocks/man.png',
        createdAt: '2021-07-09T15:35:22.120Z',
        updatedAt: '2021-07-09T15:35:22.120Z'
      }
    }
  },
  {
    id: '1999',
    name: 'فراولة صغيرة',
    rate: 0,
    details:
      'لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور',
    wholeQuantity: 200,
    currentQuantity: 140,
    minimumPurchaseQuantity: 55,
    unitPrice: 26,
    address: '7 شارع الشيخ بن نعمان',
    origin: 'LOCAL',
    originalCountry: null,
    customsNumber: null,
    marketName: 'اسم الماركة',
    capacity: 'Alias qui hic pariat',
    region: 'حي الوحدة',
    city: 'الرياض',
    audioLength: null,
    createdAt: '2021-07-28T18:19:26.451Z',
    updatedAt: '2021-07-28T18:19:26.451Z',
    images: [
      {
        id: '0031db7c-a0bd-4a04-ae3d-9d204c37070e',
        mimetype: 'image/png',
        filename: 'file-1627496365598.png',
        extension: 'png',
        size: 215074,
        protected_url: '/files/file-1627496365598.png/download',
        public_url: 'assets/img/mocks/stw.jpg',
        createdAt: '2021-07-28T18:19:25.603Z',
        updatedAt: '2021-07-28T18:19:26.000Z'
      },
      {
        id: 'b67832a2-d8c0-4ca0-b203-2828098ce8f1',
        mimetype: 'image/png',
        filename: 'file-1627496364805.png',
        extension: 'png',
        size: 206331,
        protected_url: '/files/file-1627496364805.png/download',
        public_url: 'assets/img/mocks/stw.jpg',
        createdAt: '2021-07-28T18:19:24.811Z',
        updatedAt: '2021-07-28T18:19:26.000Z'
      }
    ],
    audio: {
      id: '9baeaf5e-182a-4ac6-b38b-14e05275b31c',
      mimetype: 'audio/wav',
      filename: 'file-1627496365810.wav',
      extension: 'wav',
      size: 270380,
      protected_url: '/files/file-1627496365810.wav/download',
      public_url: '/public/uploads/file-1627496365810.wav',
      createdAt: '2021-07-28T18:19:25.816Z',
      updatedAt: '2021-07-28T18:19:25.816Z'
    },
    unit: {
      id: 'a358a0af-b33e-40c7-bc52-19c8511e4711',
      key: 'طن',
      createdAt: '2021-07-22T17:37:05.556Z',
      updatedAt: '2021-07-22T17:37:05.556Z'
    },
    type: {
      id: '4a870fe1-004c-469c-9b5d-e29a6cc30db1',
      key: 'نوع رفيع',
      createdAt: '2021-07-22T17:33:45.270Z',
      updatedAt: '2021-07-22T17:33:45.270Z'
    },
    owner: {
      id: '3d8069c5- 0779-42cb- b563 - 9b48828519ee',
      email: 'sadokmhiri@gmail.com',
      firstName: 'محمد',
      lastName: 'علي',
      phoneNumber: '+21620166525',
      city: 'Sousse',
      postalCode: '4011',
      address: '7 Rue Bouali Amine Sousse',
      type: 'BUYER_SELLER',
      createdAt: '2021-07-09T15:35:22.154Z',
      updatedAt: '2021-07-29T10:27:38.000Z',
      experyDateOfcr: '2021-07-11T00:00:00.000Z',
      avatar: {
        id: 'b4ce12a0-f53f-4e88-8918-81c0d1356c3d',
        mimetype: 'image/png',
        filename: 'file-1625844922053.png',
        extension: 'png',
        size: 4709,
        protected_url: '/files/file-1625844922053.png/download',
        public_url: 'assets/img/mocks/man.png',
        createdAt: '2021-07-09T15:35:22.120Z',
        updatedAt: '2021-07-09T15:35:22.120Z'
      }
    }
  },
  {
    id: '1999',
    name: 'فراولة هولندية',
    rate: 0,
    details:
      'لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور',
    wholeQuantity: 200,
    currentQuantity: 140,
    minimumPurchaseQuantity: 55,
    unitPrice: 44,
    address: '7 شارع الشيخ بن نعمان',
    origin: 'LOCAL',
    originalCountry: null,
    customsNumber: null,
    marketName: 'اسم الماركة',
    capacity: 'Alias qui hic pariat',
    region: 'حي الوحدة',
    city: 'الرياض',
    audioLength: null,
    createdAt: '2021-07-28T18:19:26.451Z',
    updatedAt: '2021-07-28T18:19:26.451Z',
    images: [
      {
        id: '0031db7c-a0bd-4a04-ae3d-9d204c37070e',
        mimetype: 'image/png',
        filename: 'file-1627496365598.png',
        extension: 'png',
        size: 215074,
        protected_url: '/files/file-1627496365598.png/download',
        public_url: 'assets/img/mocks/stw.jpg',
        createdAt: '2021-07-28T18:19:25.603Z',
        updatedAt: '2021-07-28T18:19:26.000Z'
      },
      {
        id: 'b67832a2-d8c0-4ca0-b203-2828098ce8f1',
        mimetype: 'image/png',
        filename: 'file-1627496364805.png',
        extension: 'png',
        size: 206331,
        protected_url: '/files/file-1627496364805.png/download',
        public_url: 'assets/img/mocks/stw.jpg',
        createdAt: '2021-07-28T18:19:24.811Z',
        updatedAt: '2021-07-28T18:19:26.000Z'
      }
    ],
    audio: {
      id: '9baeaf5e-182a-4ac6-b38b-14e05275b31c',
      mimetype: 'audio/wav',
      filename: 'file-1627496365810.wav',
      extension: 'wav',
      size: 270380,
      protected_url: '/files/file-1627496365810.wav/download',
      public_url: '/public/uploads/file-1627496365810.wav',
      createdAt: '2021-07-28T18:19:25.816Z',
      updatedAt: '2021-07-28T18:19:25.816Z'
    },
    unit: {
      id: 'a358a0af-b33e-40c7-bc52-19c8511e4711',
      key: 'طن',
      createdAt: '2021-07-22T17:37:05.556Z',
      updatedAt: '2021-07-22T17:37:05.556Z'
    },
    type: {
      id: '4a870fe1-004c-469c-9b5d-e29a6cc30db1',
      key: 'نوع رفيع',
      createdAt: '2021-07-22T17:33:45.270Z',
      updatedAt: '2021-07-22T17:33:45.270Z'
    },
    owner: {
      id: '3d8069c5- 0779-42cb- b563 - 9b48828519ee',
      email: 'sadokmhiri@gmail.com',
      firstName: 'محمد',
      lastName: 'علي',
      phoneNumber: '+21620166525',
      city: 'Sousse',
      postalCode: '4011',
      address: '7 Rue Bouali Amine Sousse',
      type: 'BUYER_SELLER',
      createdAt: '2021-07-09T15:35:22.154Z',
      updatedAt: '2021-07-29T10:27:38.000Z',
      experyDateOfcr: '2021-07-11T00:00:00.000Z',
      avatar: {
        id: 'b4ce12a0-f53f-4e88-8918-81c0d1356c3d',
        mimetype: 'image/png',
        filename: 'file-1625844922053.png',
        extension: 'png',
        size: 4709,
        protected_url: '/files/file-1625844922053.png/download',
        public_url: 'assets/img/mocks/man.png',
        createdAt: '2021-07-09T15:35:22.120Z',
        updatedAt: '2021-07-09T15:35:22.120Z'
      }
    }
  },
  {
    id: '1999',
    name: 'فراولة صغيرة',
    rate: 0,
    details:
      'لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور',
    wholeQuantity: 200,
    currentQuantity: 140,
    minimumPurchaseQuantity: 55,
    unitPrice: 26,
    address: '7 شارع الشيخ بن نعمان',
    origin: 'LOCAL',
    originalCountry: null,
    customsNumber: null,
    marketName: 'اسم الماركة',
    capacity: 'Alias qui hic pariat',
    region: 'حي الوحدة',
    city: 'الرياض',
    audioLength: null,
    createdAt: '2021-07-28T18:19:26.451Z',
    updatedAt: '2021-07-28T18:19:26.451Z',
    images: [
      {
        id: '0031db7c-a0bd-4a04-ae3d-9d204c37070e',
        mimetype: 'image/png',
        filename: 'file-1627496365598.png',
        extension: 'png',
        size: 215074,
        protected_url: '/files/file-1627496365598.png/download',
        public_url: 'assets/img/mocks/stw.jpg',
        createdAt: '2021-07-28T18:19:25.603Z',
        updatedAt: '2021-07-28T18:19:26.000Z'
      },
      {
        id: 'b67832a2-d8c0-4ca0-b203-2828098ce8f1',
        mimetype: 'image/png',
        filename: 'file-1627496364805.png',
        extension: 'png',
        size: 206331,
        protected_url: '/files/file-1627496364805.png/download',
        public_url: 'assets/img/mocks/stw.jpg',
        createdAt: '2021-07-28T18:19:24.811Z',
        updatedAt: '2021-07-28T18:19:26.000Z'
      }
    ],
    audio: {
      id: '9baeaf5e-182a-4ac6-b38b-14e05275b31c',
      mimetype: 'audio/wav',
      filename: 'file-1627496365810.wav',
      extension: 'wav',
      size: 270380,
      protected_url: '/files/file-1627496365810.wav/download',
      public_url: '/public/uploads/file-1627496365810.wav',
      createdAt: '2021-07-28T18:19:25.816Z',
      updatedAt: '2021-07-28T18:19:25.816Z'
    },
    unit: {
      id: 'a358a0af-b33e-40c7-bc52-19c8511e4711',
      key: 'طن',
      createdAt: '2021-07-22T17:37:05.556Z',
      updatedAt: '2021-07-22T17:37:05.556Z'
    },
    type: {
      id: '4a870fe1-004c-469c-9b5d-e29a6cc30db1',
      key: 'نوع رفيع',
      createdAt: '2021-07-22T17:33:45.270Z',
      updatedAt: '2021-07-22T17:33:45.270Z'
    },
    owner: {
      id: '3d8069c5- 0779-42cb- b563 - 9b48828519ee',
      email: 'sadokmhiri@gmail.com',
      firstName: 'محمد',
      lastName: 'علي',
      phoneNumber: '+21620166525',
      city: 'Sousse',
      postalCode: '4011',
      address: '7 Rue Bouali Amine Sousse',
      type: 'BUYER_SELLER',
      createdAt: '2021-07-09T15:35:22.154Z',
      updatedAt: '2021-07-29T10:27:38.000Z',
      experyDateOfcr: '2021-07-11T00:00:00.000Z',
      avatar: {
        id: 'b4ce12a0-f53f-4e88-8918-81c0d1356c3d',
        mimetype: 'image/png',
        filename: 'file-1625844922053.png',
        extension: 'png',
        size: 4709,
        protected_url: '/files/file-1625844922053.png/download',
        public_url: 'assets/img/mocks/man.png',
        createdAt: '2021-07-09T15:35:22.120Z',
        updatedAt: '2021-07-09T15:35:22.120Z'
      }
    }
  }
];
