import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { USER_TYPES } from 'src/app/core/enums';
import { StepperConfig, StepperFields } from 'src/app/core/types/stepper.types';

@Component({
  selector: 'app-register-marketer',
  templateUrl: './register-marketer.component.html',
  styleUrls: ['./register-marketer.component.scss']
})
export class RegisterMarketerComponent implements OnInit {

  formRegister: FormGroup;
  stepperField: StepperFields[];
  stepperConfig: StepperConfig;

  constructor() { }

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm() {
    const  mail =  new FormControl('', {validators: [Validators.required, Validators.email]});
    const  firstName = new FormControl('', {validators: [Validators.required]});
    const  lastName = new FormControl('', {validators: [Validators.required]});
    const  phoneNumber = new FormControl('', {validators: [Validators.required]});
    const  pwd = new FormControl('', {validators: [Validators.required, Validators.minLength(8)]});
    const  confirmPwd = new FormControl('', {validators: [Validators.required, Validators.minLength(8)]});
    const  avatar = new FormControl('', {validators: []});
    const  secretCode = new FormControl('', {validators: [Validators.required]});
    const  commercialRegister = new FormControl('', {validators: []});
    const  expiryDateOfcr = new FormControl('', {validators: []});
    const  city = new FormControl('', {validators:  []});
    const  codePostal = new FormControl('', {validators: []});
    const  address = new FormControl('', {validators: []});


    this.stepperField = [
      {
        name: 'mail',
        text: 'البريد الإلكتروني',
        type: 'email',
        errorMsg: 'الرجاء إدخال بريد إلكتروني صحيح ',
        control: mail
      },
      {
        name: 'lastName',
        text: 'اللقب',
        type: 'text',
        control: lastName
      },
      {
        name: 'firstName',
        text: 'الإسم',
        type: 'text',
        control: firstName
      },
      {
        name: 'avatar',
        text: '',
        type: 'file',
        control: avatar
      },
      {
        name: 'phoneNumber',
        text: 'رقم الهاتف',
        type: 'tel',
        control: phoneNumber
      },{
        name: 'confirmPwd',
        text: 'أعد كلمة المرور  ',
        type: 'password',
        control: confirmPwd
      },
 
      {
        name: 'pwd',
        text: 'كلمة المرور',
        type: 'password',
        control: pwd
      },{
        name: 'secretCode',
        text: 'الرقم التسويقي : رقم متاح من المسوق ',
        type: 'text',
        control: secretCode
      },
      {
        name: 'commercialRegister',
        text: 'سجل زراعي- تجاري - وثيقة عمل حر ',
        type: 'text',
        control: commercialRegister
      },{
        name: 'expiryDateOfcr',
        text: 'تاريخ إنتهاء السجل',
        type: 'date',
        control: expiryDateOfcr
      },
      {
        name: 'codePostal',
        text: 'الترقيم البريدي ',
        type: 'number',
        control: codePostal
      },{
        name: 'city',
        text: 'المدينة ',
        type: 'text',
        control: city
      },
      ,{
        name: 'address',
        text: 'العنوان',
        type: 'text',
        control: address
      }
    ];

    this.stepperConfig = {
      number: 3,
      type: USER_TYPES.MARKETER,
      config: [
        {
          step: 1,
          size: 4
        },
        {
          step: 2,
          size: 3
        },
        {
          step: 3,
          size: 5
        }
      ]
    }

  }

  onSubmit(event) {
    console.log(event);    
  }

}
