<!-- End Navbar -->
<div class="wrapper">
  <div dir="rtl" class="container-fluid px-2 py-2 mx-1">
    <div class="row d-flex justify-content-center">
      <div
        class="col-xl-9 col-lg-8 col-md-9 col-11 text-center bg-primary p-2 p-md-6 rounded"
      >
        <h1   class="text-white mt-2 text-responsive-title">الآن .. فرص حقيقية في أسواقنا الزراعية</h1>
        <p class="text-center text-white mb-4 lead px-md-4 text-responsive">
          منصة خراج أول منصة من نوعها لتجارة الفواكه والخضار بالجملة صادره عن
          شركة سعودية / شركة انجم لتقنية المعلومات.
        </p>

     
        
        <div class="card p-4">
          
          <form class="form-card" [formGroup]="userForm" (ngSubmit)="onFormSubmit()">
            <div class="row justify-content-between text-right">
              <div class="form-group col-sm-6 flex-column d-flex">
                <label class="form-control-label px-3"
                  >الاسم <span class="text-danger"> *</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  formControlName="firstName"
                  id="fname"
                  name="fname"
                  placeholder="أدخل اسمك "
            
                />
                <label *ngIf="userForm.get('firstName').invalid && userForm.get('firstName').touched" [ngClass] = "'error'"> الرجاء إدخال كافة المعطيات </label>
              </div>
              <div class="form-group col-sm-6 flex-column d-flex">
                <label class="form-control-label px-3"
                  >اللقب<span class="text-danger"> *</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  id="lname"
                  formControlName="lastName"
                  name="lname"
                  placeholder="أدخل اسمك الأخير"
                
                />
                <label *ngIf="userForm.get('lastName').invalid && userForm.get('lastName').touched" [ngClass] = "'error'"> الرجاء إدخال كافة المعطيات </label>
              </div>
            </div>
            <div class="row justify-content-between text-right">
              <div class="form-group col-sm-6 flex-column d-flex">
                <label class="form-control-label px-3"
                  >البريد الإلكتروني</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="email"
                  formControlName="email"
                  name="email"
                  placeholder="البريد الإلكتروني"
               
                />
                <label *ngIf="userForm.get('email').invalid && userForm.get('email').touched" [ngClass] = "'error'"> الرجاء إدخال كافة المعطيات </label>
              </div>
              <div class="form-group col-sm-6 flex-column d-flex">
                <label class="form-control-label px-3"
                  >رقم الهاتف<span class="text-danger"> *</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  id="mob"
                  formControlName="phone"
                  name="mob"
                  placeholder="رقم الهاتف"
                
                />
                <label *ngIf="userForm.get('phone').invalid && userForm.get('phone').touched" [ngClass] = "'error'"> الرجاء إدخال كافة المعطيات </label>
              </div>
            </div>
            <div class="row justify-content-between text-right">
              <div class="form-group col-sm-6 flex-column d-flex">
                <label class="form-control-label px-3"
                  >المنطقة<span class="text-danger"> *</span></label
                >

                <select formControlName="state" class="form-control mx-0">
                  <option>الرياض</option>
                  <option>مكـة المكرمة</option>
                  <option>المدينة المنورة</option>
                  <option>القصيم</option>
                  <option>المنطقة الشرقية</option>
                  <option>عسير</option>

                  <option>تبــوك</option>

                  <option>حائل</option>

                  <option>الحدود الشمالية</option>

                  <option>جازان</option>

                  <option>نجران</option>

                  <option>نجران</option>

                  <option>الباحة</option>

                  <option>الجـوف</option>
                </select>
                <label *ngIf="userForm.get('state').invalid && userForm.get('state').touched" [ngClass] = "'error'"> الرجاء إدخال كافة المعطيات </label>
              </div>
              <div class="form-group col-sm-6 flex-column d-flex">
                <label class="form-control-label px-3">
                  المدينة
                  <span class="text-danger"> *</span></label
                >
                <input
                formControlName="city"
                  type="text"
                  class="form-control"
                  id="mob"
                  name="mob"
                  placeholder="المدينة"
               
                />
                <label *ngIf="userForm.get('city').invalid && userForm.get('city').touched" [ngClass] = "'error'"> الرجاء إدخال كافة المعطيات </label>
              </div>
            </div>
            <div class="row justify-content-between text-right">
              <div class="form-group col-sm-6 flex-column d-flex">
                <label class="form-control-label px-3"
                  >الجنس<span class="text-danger"> *</span></label
                >

                <select formControlName="gender" class="form-control mx-0">
                    <option>ذكر</option>
                    <option>أنثى</option>
                </select>
                <label *ngIf="userForm.get('gender').invalid && userForm.get('gender').touched" [ngClass] = "'error'"> الرجاء إدخال كافة المعطيات </label>
              </div>

              <div class="form-group col-sm-6 flex-column d-flex">
                <label class="form-control-label px-3"
                  >النشاط<span class="text-danger"> *</span></label
                >

                <select formControlName="activity" class="form-control mx-0">
                    <option>نقليات (فرد)</option>
                    <option>نقليات (مؤسسة)</option>
                    <option>نقليات (شركة)</option>
                    <option>بائع تجاري (فرد)</option>
                    <option>بائع (مؤسسة)</option>
                    <option>بائع (شركة)</option>
                    <option>مالك مزرعة</option>
                    <option>مشتري تجاري (فرد)</option>
                    <option>مشتري (مؤسسة)</option>
                    <option>مشتري (شركة)</option>
                    <option>مسوق</option>
                </select>
                <label *ngIf="userForm.get('activity').invalid && userForm.get('activity').touched" [ngClass] = "'error'"> الرجاء إدخال كافة المعطيات </label>
              </div>
            </div>
            <div class="row justify-content-between text-right">
              <div class="form-group col-12 flex-column d-flex">
                <label class="form-control-label px-3"
                  >يمكنك ترك رسالة هنا</label
                >
                <textarea
                formControlName="message"
                  type="text"
                  class="form-control"
                  id="ans"
                  name="ans"
                  placeholder=""
       
                ></textarea>
                <label *ngIf="userForm.get('message').invalid && userForm.get('message').touched" [ngClass] = "'error'"> الرجاء إدخال كافة المعطيات </label>
              </div>
            </div>
            <div class="row justify-content-end">
              <div class="form-group">
                <button *ngIf="userForm.valid" type="submit" class="btn btn-primary">
                    تسجيل المعلومات
                </button>
                <button *ngIf="userForm.invalid" disabled type="submit" class="btn btn-primary">
                  تسجيل المعلومات
              </button>
              </div>
            </div>
          </form>
        </div>
        
      </div>
    </div>
  </div>
</div>
