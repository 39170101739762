<app-navbar></app-navbar>
<div class="register-page-content container">
  <div
    *ngIf="selectedType != null"
    class="flex-row flex-sm-row user-types-list my-4 container text-center"
  >
    <label class="select">
      <select
        class="select text-center form-control"
        [(ngModel)]="selectedType"
        (change)="onChange($event.target.value)"
      >
        <option class="bg-white text-black" disabled [value]="null">
          الرجاء إختيار نوع العميل
        </option>
        <option
          class="bg-white text-black"
          [value]="userTypes.TRANSPORTER_AGENT"
        >
          ناقل
        </option>
        <option class="bg-white text-black" [value]="userTypes.SELLER">
          بائع
        </option>
        <option class="bg-white text-black" [value]="userTypes.BUYER">
          مشتري
        </option>
        <option class="bg-white text-black" [value]="userTypes.BUYER_SELLER">
          مشتري وبائع
        </option>
      </select>
    </label>
  </div>

  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-5">
        <div class="login-card">
          <div class="card-header bg-white pb-5">
            <div class="login-img">
              <div class="login-img_container">
                <img src="assets/img/logo.png" alt="kharaaj" />
              </div>
            </div>
          </div>
          <div class="card-body px-lg-5 py-lg-5 mt-1">
            <div class="text-right text-muted text-login">
              <div class="text-login_welcome">مرحباً بك</div>
              <div class="text-login_desc">
                خراج أول منصة لبيع المنتجات الزراعية بالجملة فالمنطقة
              </div>
            </div>
            <form
              *ngIf="selectedType != null"
              class="login-form"
              role="form"
              [formGroup]="loginForm"
              (submit)="onSubmit()"
            >
              <div
                *ngIf="selectedType !== userTypes.MARKETER"
                class="form-group mb-3"
              >
                <div class="input-group input-group-alternative">
                  <label class="login-form_phone-code"> {{ phoneCode }} </label>
                  <input
                    formControlName="phoneNumber"
                    class="form-control"
                    placeholder="إدخال رقم الهاتف"
                    pattern="\d*"
                    [maxlength]="phoneLength"
                    type="tel"
                  />
                </div>
              </div>
              <div
                *ngIf="selectedType === userTypes.MARKETER"
                class="form-group mb-3"
              >
                <div class="input-group input-group-alternative">
                  <input
                    formControlName="pairingCode"
                    class="form-control"
                    placeholder=" رقم الإقتران أو رقم الهاتف "
                    type="text"
                  />
                </div>
              </div>
              <div
                *ngIf="
                  selectedType === userTypes.TRANSPORTER ||
                  selectedType === userTypes.TRANSPORTER_AGENT ||
                  selectedType === userTypes.TRANSPORTER_COMPANY ||
                  selectedType === userTypes.CONTROLLER ||
                  selectedType === userTypes.MARKETER
                "
                class="form-group mb-3"
              >
                <div class="input-group input-group-alternative">
                  <input
                    formControlName="pwd"
                    class="form-control"
                    placeholder="كلمة المرور"
                    type="password"
                  />
                </div>
                <br />
                <a href="auth/forget-password">
                  <label> نسيت كلمة السر؟ </label>
                </a>
              </div>

              <div class="text-center login-form_bottom">
                <button
                  type="submit"
                  class="btn btn-primary my-4"
                  [disabled]="loginForm.invalid"
                >
                  تسجيل الدخول
                </button>
                <div class="">
                  <a
                    [routerLink]="APP_ROUTES.AUTH.REGISTER"
                    [queryParams]="{ type: selectedType }"
                    class="text-black c-pointer"
                    ><small>إنشاء حساب ؟</small></a
                  >
                </div>
              </div>
            </form>

            <div
              *ngIf="selectedType == null"
              class="flex-row flex-sm-row user-types-list my-4 text-center"
            >
              <label class="selectmin">
                <select
                  class="selectmin text-center form-control"
                  [(ngModel)]="selectedType"
                  (change)="onChange($event.target.value)"
                >
                  <option class="bg-white text-black" disabled [value]="null">
                    الرجاء إختيار نوع العميل
                  </option>
                  <option
                    class="bg-white text-black"
                    [value]="userTypes.TRANSPORTER_AGENT"
                  >
                    ناقل
                  </option>
                  <option
                    class="bg-white text-black"
                    [value]="userTypes.SELLER"
                  >
                    بائع
                  </option>
                  <option class="bg-white text-black" [value]="userTypes.BUYER">
                    مشتري
                  </option>
                  <option
                    class="bg-white text-black"
                    [value]="userTypes.BUYER_SELLER"
                  >
                    مشتري وبائع
                  </option>
                </select>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
