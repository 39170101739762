import { series } from "../../mock/data";
import { ChartOptions } from "../../types/charts.types";

export const CHART_OPTIONS: ChartOptions = {
      series: [
        {
          name: 'STOCK ABC',
          data: series.monthDataSeries1.prices
        },
      ],
      chart: {
        type: 'area',
        height: 120,
        width: 200,
        foreColor: 'black',

        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
      },

      title: {
        text: 'Fundamental Analysis of Stocks',
        align: 'left',
      },
      subtitle: {
        text: 'Price Movements',
        align: 'left',
      },
      labels: series.monthDataSeries1.dates,
      xaxis: {
        type: 'datetime',
      },
      yaxis: {
        show: false,
        opposite: true,
      },
      legend: {
        horizontalAlign: 'left',
      }
    };



export const BuyerChartOptions = {
  series: [
    {
      name: "Website Blog",
      type: "column",
      data: [440, 505, 414, 671]
    },
    {
      name: "Social Media",
      type: "line",
      data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16]
    }
  ],
  chart: {
    height: 300,
    type: "line"
  },
  stroke: {
    width: [0, 4]
  },
  title: {
    text: ""
  },
  dataLabels: {
    enabled: false,
    enabledOnSeries: [0]
  },
  labels: [
    "01 Jan 2001",
    "02 Jan 2001",
    "03 Jan 2001",
    "04 Jan 2001"
  ],
  xaxis: {
    type: "datetime"
  },
  yaxis: [
    {
      title: {
        text: "Website Blog"
      }
    }
  ]
};


export const IS_UP_COLORS = ['#009E00', '#39D839', '#9EF59E'];


export const IS_DOWN_COLORS = ['#D20000', '#F14949', '#FD9696'];