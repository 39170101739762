import { getMaxListeners } from 'process';

export const reservations = [
  {
    id: '123',
    mail: 'zeineb@gmail.com',
    name: 'فراولة حمراء',
    lastName: 'string',
    phone: '20100200',
    city: 'Riadh',
    address: '7 Road Al-sheikh Hamad',
    quantity: 25,
    price: 200,
    productId: '123456',
    userId: '11111',
    productName: 'فراولة حمراء',
    unit: 'طن',
    productImage: 'assets/img/mocks/stw.jpg',
    canRepublish: true,
  },
  {
    id: '125',
    mail: 'zeineb@gmail.com',
    name: 'بقدنوس',
    lastName: 'string',
    phone: '20100200',
    city: 'Riadh',
    address: '7 Road Al-sheikh Hamad',
    quantity: 35,
    price: 150,
    productId: '123456',
    userId: '11111',
    productName: 'بقدنوس',
    unit: 'طن',
    productImage: 'assets/img/mocks/bkd.jpeg',
    canRepublish: true,
  },
  {
    id: '126',
    mail: 'zeineb@gmail.com',
    name: 'أناناس حجم كبير',
    lastName: 'string',
    phone: '20100200',
    city: 'Riadh',
    address: '7 Road Al-sheikh Hamad',
    quantity: 200,
    price: 390,
    productId: '123456',
    userId: '11111',
    productName: 'أناناس حجم كبير',
    unit: 'طن',
    productImage: 'assets/img/mocks/ans.jpg',
    canRepublish: true,
  },
  {
    id: '127',
    mail: 'zeineb@gmail.com',
    name: 'إجاص',
    lastName: 'string',
    phone: '20100200',
    city: 'Riadh',
    address: '7 Road Al-sheikh Hamad',
    quantity: 200,
    price: 50,
    productId: '123456',
    userId: '11111',
    productName: 'إجاص',
    unit: 'طن',
    productImage: 'assets/img/mocks/por.jpg',
    canRepublish: true,
  },
  {
    id: '128',
    mail: 'zeineb@gmail.com',
    name: 'توت أبيض',
    lastName: 'string',
    phone: '20100200',
    city: 'Riadh',
    address: '7 Road Al-sheikh Hamad',
    quantity: 400,
    price: 120,
    productId: '123456',
    userId: '11111',
    productName: 'توت أبيض',
    unit: 'طن',
    productImage: 'assets/img/mocks/ttt.jpg',
    canRepublish: false,
  },
  {
    id: '129',
    mail: 'zeineb@gmail.com',
    name: 'طماطم',
    lastName: 'string',
    phone: '20100200',
    city: 'Riadh',
    address: '7 Road Al-sheikh Hamad',
    quantity: 350,
    price: 10,
    productId: '123456',
    userId: '11111',
    productName: 'طماطم',
    unit: 'طن',
    productImage: 'assets/img/mocks/tmt.jpg',
    canRepublish: true,
  },
];
