export const regions = [
  {
    id: '0a18cb02-c44f-4da5-9bb8-40b54dd7df35',
    key: 'منطقة الجوف',
    createdAt: '2021-08-04T09:58:20.051Z',
    updatedAt: '2021-08-04T09:58:20.051Z'
  },
  {
    id: '23a93016-a376-454d-9437-5c7c5db57176',
    key: 'منطقة الباحة',
    createdAt: '2021-08-04T10:02:05.599Z',
    updatedAt: '2021-08-04T10:02:05.599Z'
  },
  {
    id: '3af83600-c19a-4041-9724-eb59d0d611f2',
    key: 'منطقة المدينة المنورة',
    createdAt: '2021-08-04T09:40:46.299Z',
    updatedAt: '2021-08-04T09:40:46.299Z'
  },
  {
    id: '536cd83a-2ca0-4bae-958b-cfdc461f55dd',
    key: 'منطقة مكة المكرمة',
    createdAt: '2021-08-04T09:34:19.740Z',
    updatedAt: '2021-08-04T09:34:19.740Z'
  },
  {
    id: '803ec4c9-e839-4de2-91e2-fea3ada0e812',
    key: 'منطقة الرياض',
    createdAt: '2021-08-04T09:30:20.436Z',
    updatedAt: '2021-08-04T09:30:20.436Z'
  },
  {
    id: '93150b84-5035-42ef-957e-83eda6eb4999',
    key: 'منطقة حائل',
    createdAt: '2021-08-04T09:53:13.642Z',
    updatedAt: '2021-08-04T09:53:13.642Z'
  },
  {
    id: 'b737d119-25d3-46fc-b417-20ade1b2c884',
    key: 'منطقة جازان',
    createdAt: '2021-08-04T09:55:33.348Z',
    updatedAt: '2021-08-04T09:55:33.348Z'
  },
  {
    id: 'cac733bb-da96-4e58-a32a-334971f26206',
    key: 'منطقة تبوك',
    createdAt: '2021-08-04T09:47:18.568Z',
    updatedAt: '2021-08-04T09:47:18.568Z'
  },
  {
    id: 'e0680473-6b17-48eb-add9-7033abe06717',
    key: 'منطقة نجران',
    createdAt: '2021-08-04T09:54:16.359Z',
    updatedAt: '2021-08-04T09:54:16.359Z'
  },
  {
    id: 'e35b3977-9f88-4311-85ec-cdb7402ba9c5',
    key: 'منطقة عسير',
    createdAt: '2021-08-04T09:48:58.930Z',
    updatedAt: '2021-08-04T09:48:58.930Z'
  },
  {
    id: 'e458f9c4-a94a-4002-8ff6-0974e573df68',
    key: 'المنطقة الشرقية',
    createdAt: '2021-08-04T09:42:57.389Z',
    updatedAt: '2021-08-04T09:42:57.389Z'
  },
  {
    id: 'faf3ec67-4cf1-40ea-bb3d-ef8c8e8002c6',
    key: 'منطقة القصيم',
    createdAt: '2021-08-04T09:45:22.268Z',
    updatedAt: '2021-08-04T09:45:22.268Z'
  },
  {
    id: 'ff35ca1e-346c-4f21-98eb-cce24aa3d0bc',
    key: 'منطقة الحدود الشمالية',
    createdAt: '2021-08-04T09:59:39.007Z',
    updatedAt: '2021-08-04T09:59:39.007Z'
  }
];

export const cities = [
  {
    id: '28c658e5-b091-4e1f-885f-7c8400d485ba',
    key: 'القريات',
    createdAt: '2021-08-04T09:58:50.525Z',
    updatedAt: '2021-08-04T09:58:50.525Z'
  },
  {
    id: '4dd9bdc7-e3f6-4183-90e2-9ad8987c4afd',
    key: 'طبرجل',
    createdAt: '2021-08-04T09:58:59.200Z',
    updatedAt: '2021-08-04T09:58:59.200Z'
  },
  {
    id: '51c5986c-4ff2-4038-9d05-c300e054c856',
    key: 'دومة الجندل',
    createdAt: '2021-08-04T09:59:11.732Z',
    updatedAt: '2021-08-04T09:59:11.732Z'
  },
  {
    id: 'cb4b1a1b-fa90-471a-9f8d-fdf775ac8a92',
    key: 'سكاكا',
    createdAt: '2021-08-04T09:58:41.905Z',
    updatedAt: '2021-08-04T09:58:41.905Z'
  }
];
