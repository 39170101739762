import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { INTERNATIONAL_PHONE_CODE } from 'src/app/core/config';
import { APP_ROUTES } from 'src/app/core/config/routes';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AuthService } from '../../services/auth.service';
import { USER_TYPES } from '../../../../core/enums/index';

@Component({
  selector: 'app-register-otp',
  templateUrl: './register-otp.component.html',
  styleUrls: ['./register-otp.component.scss']
})
export class RegisterOtpComponent implements OnInit {

  phoneNumber: number;
  otpCode: any;
  isValid: boolean;

  phoneCode = INTERNATIONAL_PHONE_CODE;

  timeLeft: number = 60;
  interval;
  canResend: boolean = false;
  state: { [k: string]: any; };

  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private router: Router,
    private sharedService: SharedService,
    private layoutService: LayoutService
  ) {
    const navigation = this.router.getCurrentNavigation();
    this.state = navigation.extras.state
  }

  ngOnInit(): void {
    if (!this.state) {
       this.router.navigate([APP_ROUTES.AUTH.REGISTER]);
    }
    this.startTimer();
  }

  onChangeOtpCode() {
    if (this.otpCode.length === 6) {
      this.isValid = true;
    } else {
      this.isValid = false;
    }
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.canResend = true;
        this.pauseTimer();
      }
    }, 1000);
  }

  pauseTimer() {
    clearInterval(this.interval);
  }

  register() {
    this.authService.signUp(this.state.type, { ...this.state, otp: this.otpCode }).subscribe(
      (data) => {
        console.log(data);
        this.toastr.success('تمت العملية بنجاح ! مرحبا بك');
        this.sharedService.changeSignUpStatus('RESET');
        this.router.navigate([APP_ROUTES.AUTH.LOGIN], { queryParams: {type: this.state.type}});
      }, err => {
        this.otpCode = ''
      }
    );
  }

  login() {
    const requestData = {
      phoneNumber: this.phoneCode + this.phoneNumber,
      otp: this.otpCode
    };

    this.authService.signin(this.authService.type, requestData).subscribe(
      (response: any) => {
        if (!this.sharedService?.isMock) {
          localStorage.setItem('user', JSON.stringify(response.data.user));
          localStorage.setItem('token', response.data.token);
        }
        this.toastr.success('! تم تسجيل الدخول بنجاح');
        this.layoutService.initializeSidebarListenners();
        this.router.navigate(['/']);
      },
      (error) => {
        this.toastr.warning('أرسلنا رمز التفعيل إلى الهاتف');
      }
    );
  }

}
