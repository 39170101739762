import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { APP_ROUTES } from 'src/app/core/config/routes';
import { BUYER_SELLER_FIELDS } from 'src/app/core/const/register/buyer-seller.fields';
import { USER_TYPES } from 'src/app/core/enums';
import { StepperConfig, StepperFields } from 'src/app/core/types/stepper.types';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AuthService } from '../../services/auth.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-register-buyer-vendor',
  templateUrl: './register-buyer-vendor.component.html',
  styleUrls: ['./register-buyer-vendor.component.scss'],
})
export class RegisterBuyerVendorComponent implements OnInit {
  formRegister: FormGroup;
  stepperField: StepperFields[] = BUYER_SELLER_FIELDS;
  stepperConfig: StepperConfig;
  @Input() pairingCode: string;
  type = USER_TYPES.BUYER_SELLER;

  constructor(
    private cdr: ChangeDetectorRef, 
    private authService: AuthService,
    private sharedService: SharedService,
    private toastr: ToastrService,
    private router: Router
    ) {}

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm() {
    
    this.stepperConfig = {
      number: 1,
      type: USER_TYPES.BUYER_SELLER,
      config: [
        {
          step: 1,
          size: 9,
        },
        
      ],
    };
  }

  onSubmit(event) {
    return this.sendOtpVerification(event.phoneNumber, event.email, event);
  }

  sendOtpVerification(phoneNumber: string, email: string, event) {
    this.authService.verifyRegisterOtp(phoneNumber, email, 'BUYER')
      .pipe(take(1))
      .subscribe(
        res => {
          this.toastr.success('أرسلنا رمز التفعيل إلى الهاتف');
          const navigationExtras: NavigationExtras = {
            state: event
          };
          return this.router.navigate(['/auth/register-otp'], navigationExtras);
        }
      )
  }
}
