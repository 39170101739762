import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import * as RecordRTC from 'recordrtc';
import { Subject } from 'rxjs';
import * as alertifyjs from 'alertifyjs';


@Component({
  selector: 'app-audio-record',
  templateUrl: './audio-record.component.html',
  styleUrls: ['./audio-record.component.scss']
})
export class AudioRecordComponent implements OnInit {
  @Output() recordedFile = new EventEmitter();
  title = 'micRecorder';
  record;
  recording = false;
  url;
  error;
  file;
  interval: any;
  startTime: any;
  private _recordingTime = new Subject<string>();
  time: string = '00:00';

  private stream: MediaStream | null = null;
  private audioContext: AudioContext | null = null;
  permissionGranted: boolean = false;

  constructor(private domSanitizer: DomSanitizer) { }

  ngOnInit() { }

  sanitize(url: string) {
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }

  private async requestPermissions(): Promise<void> {
    try {
      this.stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
      this.audioContext = new AudioContext();
      this.initiateRecording();
    } catch (error) {

    }
  }

  initiateRecording() {
    this.url = '';
    this.recording = true;
    let mediaConstraints = {
      video: false,
      audio: true
    };
    navigator.mediaDevices
      .getUserMedia(mediaConstraints)
      .then(this.successCallback.bind(this), this.errorCallback.bind(this)).catch(error => {
        console.error('Error accessing media devices.', error);
        // Handle specific errors here
        if (error.name === 'NotAllowedError') {
          alertifyjs.error('يرجى تمكين إذن التسجيل في متصفحك لاستخدام هذه الميزة')
        } else if (error.name === 'NotFoundError') {
          alertifyjs.error('يرجى تمكين إذن التسجيل في متصفحك لاستخدام هذه الميزة')
        } else {
          alertifyjs.error('يرجى تمكين إذن التسجيل في متصفحك لاستخدام هذه الميزة')
        }
      }
      )
  }


  async checkPermissions(): Promise<void> {
    const permissionStatus = await navigator.permissions.query({ name: 'microphone' as PermissionName });
    permissionStatus.onchange = () => {
      if (permissionStatus.state === 'granted') {
        this.initiateRecording();
      } else {

        alertifyjs.error('يرجى تمكين إذن التسجيل في متصفحك لاستخدام هذه الميزة')
        this.requestPermissions()
      }
    };

    if (permissionStatus.state === 'granted') {
      this.initiateRecording();
    } else if (permissionStatus.state === 'denied') {

      alertifyjs.error('يرجى تمكين إذن التسجيل في متصفحك لاستخدام هذه الميزة')
      this.requestPermissions()
    }
  }

  successCallback(stream) {
    let options = {
      mimeType: 'audio/wav',
      numberOfAudioChannels: 1,
      sampleRate: 44100
    };

    var StereoAudioRecorder = RecordRTC.StereoAudioRecorder;
    this.record = new StereoAudioRecorder(stream, options);
    this.time = '00:00'

    this.startTime = moment();
    let i = 0;
    this.interval = setInterval(() => {
      i++;
      const currentTime = moment();
      const diffTime = moment.duration(currentTime.diff(this.startTime));

      console.log(diffTime.minutes())
      if (diffTime.minutes() <= 3) {

        this.time = this.toString(diffTime.minutes()) +
          ":" +
          this.toString(diffTime.seconds());
        this._recordingTime.next(this.time);

      } else {
        this.stopRecording()
      }

    }, 1000);
    this.record.record();
  }
  private toString(value) {
    let val = value;
    if (!value) val = "00";
    if (value < 10) val = "0" + value;
    return val;
  }

  stopRecording() {
    clearInterval(this.interval);
    this.recording = false;
    this.record.stop(this.processRecording.bind(this));

  }

  removeRecord() {
    this.file = null;
    this.url = null;
    this.recordedFile.emit(this.file);
  }

  processRecording(blob) {
    this.url = URL.createObjectURL(blob);
    console.log('blob', blob);
    console.log('url', this.url);
    const file = new File([blob], 'audio-boursa-' + Date.now() + '.wav', {
      type: 'audio/wav'
    });
    this.recordedFile.emit(file);
    this.file = file;
  }

  errorCallback(error) {
    alertifyjs.error('يرجى تمكين إذن التسجيل في متصفحك لاستخدام هذه الميزة')
    this.error = 'Can not play audio in your browser';
  }
}
