<nav
  class="navbar navbar-top navbar-expand navbar-dark bg-primary sticky-top border-bottom"
  [ngClass]="isMenuToggled && 'menu-toggled'"
>
  <div class="container-fluid px-0">
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav align-items-center ml-md-0" *ngIf="connectedUser">
        <li (click)="openSideNav()" id="toggle-side-bar" class="nav-item">
          <div
            class=" sidenav-toggler sidenav-toggler-dark"
            [ngClass]="{
              active: !isMenuToggled
            }"
            #toggleIcon
          >
            <div class="sidenav-toggler-inner custom-toggler-left">
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
            </div>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a
            class="nav-link pr-0"
            href="#"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div class="media align-items-center">
              <span class="avatar avatar-sm rounded-circle">
                <img
                  alt="Image placeholder"
                  [src]="
                    connectedUser?.type === USER_TYPES.CONTROLLER
                      ? 'assets/img/default-avatar.png'
                      : connectedUser?.avatar?.public_url
                      ? (isDemoMode ? '' : BLOB_URL) +
                        connectedUser?.avatar?.filename
                      : 'assets/img/default-avatar.png'
                  "
                  onerror="'../../assets/img/default-avatar.png'"
                />
              </span>
            </div>
          </a>
          <div class="dropdown-menu dropdown-menu-left text-right">
            <div class="dropdown-header noti-title"></div>
            <!-- <a class="dropdown-item">
              <span class="mr-2">قسم الدعم</span>
              <i class="ni ni-single-02"></i>
            </a> -->
            <a class="dropdown-item" routerLink="/profile">
              <span class="mr-2">الملف الشخصي</span>
              <i class="ni ni-single-02"></i>
            </a>

            <!-- <a class="dropdown-item">
              <span class="mr-2">عن التطبيق</span>
              <i class="ni ni-support-16"></i>
            </a> -->
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" routerLink="/contact">
              <span class="mr-2">خدمة دعم العملاء</span>
              <i class="ni ni-settings-gear-65"></i>
            </a>
            <!-- <a class="dropdown-item" (click)="changeDemoMode()">
              <span class="mr-2">تفعيل وضع التجريب</span>
              <i class="ni ni-calendar-grid-58"></i>
            </a> -->
            <a
              *ngIf="
                connectedUser?.type == USER_TYPES.BUYER ||
                connectedUser?.type == USER_TYPES.SELLER ||
                connectedUser?.type == USER_TYPES.BUYER_SELLER
              "
              class="dropdown-item"
              routerLink="/rules"
            >
              <span class="mr-2">الشروط و الأحكام</span>
              <i class="ni ni-single-copy-04"></i>
            </a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" (click)="logout()">
              <span class="mr-2">الخروج</span>
              <i class="ni ni-user-run"></i>
            </a>
          </div>
        </li>
      </ul>
      <ul class="navbar-nav align-items-center " *ngIf="connectedUser">
        <li class="nav-item dropdown">
          <a
            class="nav-link"
            href="#"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="ni ni-bell-55">
              <span class="badge badge-pill"
                ><strong> {{ (todos$ | async).allNotifCount }}</strong></span
              >
            </i>
          </a>
          <div
            class="dropdown-menu dropdown-menu-xl dropdown-menu-left py-0 overflow-hidden"
          >
            <!-- Dropdown header -->
            <div class="px-3 py-3 text-right">
              <h6 class="text-sm text-muted m-0" *ngIf="notifNumber">
                لديك
                <strong class="text-primary">{{
                  (todos$ | async).allNotifCount
                }}</strong>
                إشعار (ات)
              </h6>
            </div>
            <!-- List group -->
            <div class="list-group list-group-flush">
              <app-list-notifications
                (notifNumber)="setNotifNumber($event)"
                [fromNavbar]="true"
              ></app-list-notifications>
            </div>
            <!-- View all -->
            <a
              [routerLink]="APP_ROUTES.PRIVATE.DASHBOARD.NOTIFICATIONS"
              class="dropdown-item text-center text-primary font-weight-bold py-3"
              >عرض الكل</a
            >
          </div>
        </li>
        <li class="nav-item ">
          <button
            *ngIf="
              connectedUser.type == USER_TYPES.BUYER_SELLER ||
              connectedUser?.type === USER_TYPES.SELLER
            "
            class="btn btn-sm btn-white btn-add-product m-1"
            [routerLink]="APP_ROUTES.PRIVATE.DASHBOARD.PRODUCT_CHILDS.ADD"
          >
            إضافة عرض
          </button>

          <button
            *ngIf="
              connectedUser.type == USER_TYPES.BUYER_SELLER ||
              connectedUser?.type === USER_TYPES.SELLER ||
              connectedUser?.type === USER_TYPES.BUYER ||
              connectedUser?.type === USER_TYPES.TRANSPORTER_AGENT ||
              connectedUser?.type === USER_TYPES.TRANSPORTER_COMPANY
            "
            class="btn btn-sm btn-white btn-add-product m-1"
            [routerLink]="'/myaccount/wallet'"
          >
            شحن رصيد
          </button>

          <button
            *ngIf="
              connectedUser.type === USER_TYPES.TRANSPORTER_AGENT ||
              connectedUser?.type === USER_TYPES.TRANSPORTER_COMPANY
            "
            class="btn btn-sm btn-white btn-add-product"
            [routerLink]="APP_ROUTES.PRIVATE.DASHBOARD.TRANSPORT_CHILDS.ADD_CAR"
          >
            إضافة وسيلة نقل
          </button>
        </li>
      </ul>

      <ul
        class="float-right"
        *ngIf="
          !connectedUser && url != '/auth/login' && url != '/auth/register'
        "
      >
        <li class="nav-item">
          <button class="btn btn-white btn-sm fs-14" routerLink="/auth/login">
            تسجيل الدخول
          </button>
        </li>
      </ul>
      <ul
        class="float-right"
        *ngIf="
          !connectedUser && !(url != '/auth/login' && url != '/auth/register')
        "
      >
        <li class="nav-item"></li>
      </ul>
      <div class="form-inline mr-sm-3 ml-auto">
        <a *ngIf="!connectedUser" routerLink="/" href="javascript:;">
          <span class="site-title text-white fs-22">
            <span class="main-font-en text-lowercase">Kharaaj</span> خراج</span
          >
        </a>

        <a
          *ngIf="connectedUser"
          routerLink="/"
          href="javascript:;"
          class="d-none d-md-block"
        >
          <span class="site-title text-white fs-22">
            <span class="main-font-en text-lowercase">Kharaaj</span> خراج</span
          >
        </a>
      </div>
    </div>
  </div>
</nav>
