export const languages = [
  {
    id: '1bc29da7-d4c1-49ce-a9d9-24e62ae35cc0',
    key: 'AN',
    createdAt: '2021-08-03T15:09:54.747Z',
    updatedAt: '2021-08-03T15:09:54.747Z'
  },
  {
    id: '1c6566e6-6212-4954-8e20-e505a2a064d8',
    key: 'فرنسية',
    createdAt: '2021-08-04T09:04:01.216Z',
    updatedAt: '2021-08-04T09:04:01.216Z'
  },
  {
    id: '53346867-7891-4f31-93b9-a0e2091cd962',
    key: 'ألمانية',
    createdAt: '2021-08-04T09:04:10.809Z',
    updatedAt: '2021-08-04T09:04:10.809Z'
  },
  {
    id: '86cfa891-9905-4221-b82a-86f08e92312f',
    key: 'صينية',
    createdAt: '2021-08-04T09:04:23.039Z',
    updatedAt: '2021-08-04T09:04:23.039Z'
  },
  {
    id: 'a45c4f20-2603-4283-8ed7-dd2ddb5dbbb1',
    key: 'العربية',
    createdAt: '2021-08-04T09:03:38.806Z',
    updatedAt: '2021-08-04T09:03:38.806Z'
  },
  {
    id: 'a9ec4388-843f-4e82-8505-d12c3e4c94a8',
    key: 'هندية',
    createdAt: '2021-08-04T09:04:16.769Z',
    updatedAt: '2021-08-04T09:04:16.769Z'
  },
  {
    id: 'bda79d2a-9168-4017-bece-d4c7c9b884b8',
    key: 'الأنجليزية',
    createdAt: '2021-08-04T09:03:49.379Z',
    updatedAt: '2021-08-04T09:03:49.379Z'
  }
];
