<div class="filter-container">
    <form [formGroup]="form" (submit)="uploadFile()">
        <div *ngIf="!edit">
            <div class="row">
                <div class="text-right col-md-6">
                    <div class="form-group text-right">
                        <label><span class="text-danger">*</span> رقم الايبان </label>
                        <input type="text"  class="form-control mt-3" id="ibanNumber" placeholder="رقم الايبان" formControlName="ibanNumber" [ngClass]="{
                'is-invalid':
                  form.get('ibanNumber').errors && form.get('ibanNumber').errors.ibanInvalid 
              }" />
              <small
    *ngIf="form.get('ibanNumber').errors && form.get('ibanNumber').errors.ibanInvalid"
  >
    <span *ngIf="form.get('ibanNumber').errors.error.countryUnsupported">
      البلد غير مدعوم
    </span>
    <span  *ngIf="form.get('ibanNumber').errors.error.codeLengthInvalid">
      طول رمز  غير صالح
    </span>
    <span  *ngIf="form.get('ibanNumber').errors.error.patternInvalid">
      نمط رمز  غير صالح
    </span>
  </small>
                    </div>
                </div>
                <div class="text-right col-md-6">
                    <div class="form-group text-right">
                        <label><span class="text-danger">*</span> إسم البنك </label>
                        <input type="text" class="form-control mt-3" id="bankName" placeholder="إسم البنك" formControlName="bankName" [ngClass]="{
                'is-invalid':
                  form.controls['bankName']?.invalid &&
                  form.controls['bankName']?.touched
              }" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="text-right col-md-6">
                    <div class="form-group text-right">
                        <label><span class="text-danger">*</span> رقم الحساب </label>
                        <input  type="text" class="form-control mt-3" id="accountNumber" placeholder="رقم الحساب" formControlName="accountNumber" [ngClass]="{
                'is-invalid':
                  form.controls['accountNumber']?.invalid &&
                  form.controls['accountNumber']?.touched
              }" />
                    </div>
                </div>
            </div>
            <div class="text-right">
                <label><span class="text-danger">* </span>صورة الايبان </label>
            </div>
            <div class="d-flex justify-content-center mt-5 border-radius-tr-bl-10-dashed img-container">
                <img class="image-wallet w-100" [src]="currentImage" alt="img-wallet" (click)="fileInput.click()" />
                <input type="file" accept="image/*" class="d-none" #fileInput (change)="onFileUpload($event.target.files)" />
            </div>
        </div>
        <div *ngIf="edit">
            <div class="row flex-wrap wrap-reverse">
                <div class="text-right col-md-6">
                    <div class="form-group text-right">
                        <label><span class="text-danger">*</span> رقم الايبان </label>
                        <input type="text" class="form-control mt-3" id="newIbanNumber" placeholder="رقم الايبان" formControlName="newIbanNumber" [ngClass]="{
                'is-invalid':
                form.get('newIbanNumber').errors && form.get('newIbanNumber').errors.ibanInvalid
              }" />

              <small
              *ngIf="form.get('newIbanNumber').errors && form.get('newIbanNumber').errors.ibanInvalid"
            >
              <span *ngIf="form.get('newIbanNumber').errors.error.countryUnsupported">
                البلد غير مدعوم
              </span>
              <span *ngIf="form.get('newIbanNumber').errors.error.codeLengthInvalid">
                طول رمز  غير صالح
              </span>
              <span *ngIf="form.get('newIbanNumber').errors.error.patternInvalid">
                نمط رمز  غير صالح
              </span>
            </small>
                    </div>
                </div>
                <div class="text-right col-md-6">
                    <div class="form-group text-right">
                        <label><span class="text-danger">*</span> إسم البنك </label>
                        <input type="text" class="form-control mt-3" id="newBankName" placeholder="إسم البنك" formControlName="newBankName" [ngClass]="{
                'is-invalid':
                  form.controls['newBankName']?.invalid &&
                  form.controls['newBankName']?.touched
              }" />
                    </div>
                </div>
            </div>
            <div class="row flex-wrap">
                <div class="text-right col-md-6">
                    <div class="form-group text-right">
                        <label><span class="text-danger">*</span> رقم الحساب </label>
                        <input type="text" class="form-control mt-3" id="newAccountNumber" placeholder="رقم الحساب" formControlName="newAccountNumber" [ngClass]="{
                'is-invalid':
                  form.controls['newAccountNumber']?.invalid &&
                  form.controls['newAccountNumber']?.touched
              }" />
                    </div>
                </div>
                <div class="text-right col-md-6">
                    <div class="form-group text-right">
                        <label><span class="text-danger">* </span>السبب </label>
                        <input type="text" class="form-control mt-3" id="comment" placeholder="السبب" formControlName="comment" [ngClass]="{
                'is-invalid':
                  form.controls['comment']?.invalid &&
                  form.controls['comment']?.touched
              }" />
                    </div>
                </div>
            </div>
            <div class="text-right">
                <label><span class="text-danger">* </span>صورة الايبان </label>
            </div>
            <div class="d-flex justify-content-center mt-5 border-radius-tr-bl-10-dashed img-container">
                <img class="image-wallet w-100" [src]="currentImage" alt="img-wallet" (click)="fileInput.click()" />
                <input type="file" accept="image/*"  class="d-none" #fileInput (change)="onFileUpload($event.target.files)" />
            </div>
        </div>
        <div class="btn-submit d-flex justify-content-center mt-5">
            <button *ngIf="!edit" [disabled]="form.get('ibanNumber').errors && form.get('ibanNumber').errors.ibanInvalid " type="submit" class="btn btn-reserve">
        موافق
      </button>
            <button *ngIf="edit" [disabled]="form.get('newIbanNumber').errors && form.get('newIbanNumber').errors.ibanInvalid  || !file" type="submit" class="btn btn-reserve">
      موافق
    </button>
        </div>
    </form>
</div>