import { BASE_API_URL, BLOB_URL } from 'src/app/core/config/index';
import { categories, categories_old, category } from '../mock/categories';
import { languages } from '../mock/languages';
import { login } from '../mock/login';
import { marketTime } from '../mock/market-times';
import { nationalities } from '../mock/nationalites';
import { notifications } from '../mock/notifications';
import { otp } from '../mock/otp';
import { places } from '../mock/places';
import {
  product,
  products,
  product_packages,
  product_qualities,
  product_types,
  product_units,
  product_countries
} from '../mock/products';
import { cities, regions } from '../mock/regions';
import { reservations } from '../mock/reservations';
import { transactions } from '../mock/transactions';
import { car_types, transporters, cars, cooling } from '../mock/transports';

const AUTH_ENDPOINTS = `${BASE_API_URL}auth`;
const CATEGORIES_ENDPOINTS = `${BASE_API_URL}categories`;
const PRODUCT_ENDPOINTS = `${BASE_API_URL}products`;
const SETTING = `${BASE_API_URL}settings`;
const NOTIFICATIONS_ENDPOINTS = `${BASE_API_URL}notifications`;
const RESERVATIONS_ENDPOINTS = `${BASE_API_URL}reservations`;
const TRANSACTIONS_ENDPOINTS = `${BASE_API_URL}transactions`;
const FILES_ENDPOINTS = `${BASE_API_URL}files`;
const REGIONS_ENDPOINTS = `${BASE_API_URL}regions`;
const NATIONALITIES_ENDPOINTS = `${BASE_API_URL}nationalities`;
const LANGUAGES_ENDPOINTS = `${BASE_API_URL}languages`;
const TRANSPORT_ENDPOINTS = `${BASE_API_URL}transports`;
const PLACES_ENDPOINTS = `${BASE_API_URL}places`;
const PROFITS_ENDPOINTS = `${BASE_API_URL}profits`;
const PERIOD_ENDPOINTS = `${BASE_API_URL}periods`;
const COOLING_ENDPOINTS = `${BASE_API_URL}cooling`;
const MARKET_TIME_ENDPOINTS = `${BASE_API_URL}market/times`;
const FUNDS_ENDPOINTS = `${BASE_API_URL}funds`;
const DEPOSITS_ENDPOINTS = `${BASE_API_URL}deposits`;
const LOANS_ENDPOINTS = `${BASE_API_URL}loans`;
const TOPICS_ENDPOINTS = `${BASE_API_URL}topics`;
const USERS_ENDPOINTS = `${BASE_API_URL}users`;
const SUPPORT_ENDPOINTS = `${BASE_API_URL}support/messages`;
const RECIEVE_MONEY_ENDPOINTS = `${BASE_API_URL}receive-money`;
const RULES_ENDPOINTS = `${BASE_API_URL}rules`;
const FORSA_PROGRAM = `${BASE_API_URL}forsa-program-inscriptions`;
const PLATFORM_BANK_ACCOUNT_ENDPOINT = `${BASE_API_URL}platform-bank-account`;
const MY_BALANCE_ACTIVITIES = `${BASE_API_URL}balance-activities/mine`;

export const APP_ENDPOINTS = {
  BANK_ACCOUNT_PLATFORM: PLATFORM_BANK_ACCOUNT_ENDPOINT,
  MY_BALANCE_ACTIVITIES: MY_BALANCE_ACTIVITIES,
  AUTH: {
    SIGN_IN: `${AUTH_ENDPOINTS}/signin`,
    SIGN_UP: `${AUTH_ENDPOINTS}/signup`,
    OTP: `${AUTH_ENDPOINTS}/mobile/otp`,
    VERIFY_OTP: `${AUTH_ENDPOINTS}/mobile/verify/otp`,
    FORGET_PASSWORD: `${AUTH_ENDPOINTS}/forget-password`,
    VERIFY_CODE: `${AUTH_ENDPOINTS}/forget-password/verify`,

    RESET: `${AUTH_ENDPOINTS}/forget-password/reset`,
  },
  CATEGORIES: {
    ALL: `${CATEGORIES_ENDPOINTS}`,
    PARENT: `${CATEGORIES_ENDPOINTS}/parents/statistics`,
    PARENT_SIMPLE: `${CATEGORIES_ENDPOINTS}/parent`,
    SUB: `${CATEGORIES_ENDPOINTS}/sub`
  },
  PRODUCTS: {
    MINE: `${PRODUCT_ENDPOINTS}/mine`,
    TYPES: `${PRODUCT_ENDPOINTS}/types`,
    QUALITIES: `${PRODUCT_ENDPOINTS}/qualities`,
    UNITS: `${PRODUCT_ENDPOINTS}/units`,
    PACKAGES: `${PRODUCT_ENDPOINTS}/packages`,
    PRODUCTS: `${PRODUCT_ENDPOINTS}`,
    BY_CATEGORY: `${PRODUCT_ENDPOINTS}/categories`,
    COUNTRIES: `${PRODUCT_ENDPOINTS}/countries`
  },
  SETTING: {
    GET: `${SETTING}`,
  },

  TRANSPORT: {
    BUYER_REPORTS: `${TRANSPORT_ENDPOINTS}/reports/buyer-mine`,
    SELLER_REPORTS: `${TRANSPORT_ENDPOINTS}/reports/seller-mine`,
    USER_REPORT_DETAILS: `${TRANSPORT_ENDPOINTS}/reports/user/`,
    CAR_TYPES: `${TRANSPORT_ENDPOINTS}/cars/types`,
    ALL: TRANSACTIONS_ENDPOINTS,
    FAVORITES: `${TRANSPORT_ENDPOINTS}/favorite`,
    CARS: `${TRANSPORT_ENDPOINTS}/cars`,
    MY_CARS: `${TRANSPORT_ENDPOINTS}/cars/mine`,
    ADD_REPORT: `${TRANSPORT_ENDPOINTS}/reports`,
    REQUESTS: `${TRANSPORT_ENDPOINTS}/requests`,
    MY_REQUESTS: `${TRANSPORT_ENDPOINTS}/requests/mine`,
    MY_PROPOSALS: `${TRANSPORT_ENDPOINTS}/proposals/mine`,
    REPORTS: `${TRANSPORT_ENDPOINTS}/reports`,
    REPORTS_TRANSPORTER: `${TRANSPORT_ENDPOINTS}/report-transporter`,
    MY_REPORTS_TRANSPORTER: `${TRANSPORT_ENDPOINTS}/report-transporter/mine`,
    CHANGE_METHOD: `${TRANSPORT_ENDPOINTS}/change-method/`,
    MY_REPORTS: `${TRANSPORT_ENDPOINTS}/reports/controllers`,
    BUYER_HISTORY: `${TRANSPORT_ENDPOINTS}/buyer`,
    TRANSPORTER_HISTORY: `${TRANSPORT_ENDPOINTS}/transporter`,
    TRANSPORTER_CONFIRM: `${TRANSPORT_ENDPOINTS}/confirm/`,
    TRANSPORTER_CONFIRM_TAKE_ORDER: `${TRANSPORT_ENDPOINTS}/confirm-order-taken/`,
    REQUEST_INFO: `${TRANSPORT_ENDPOINTS}/requests/`,
    TRANSPORT_DETAILS: `${TRANSPORT_ENDPOINTS}/history/`

  },
  TRANSACTIONS: {
    PRODUCT: `${TRANSACTIONS_ENDPOINTS}/products`,
    MINE: `${TRANSACTIONS_ENDPOINTS}/products/mine`,
    INVOICE: `${TRANSACTIONS_ENDPOINTS}/sale-invoice-pdf/`,
    SERVICE_INVOICE: `${TRANSACTIONS_ENDPOINTS}/service-invoice-pdf/`,
    MINE_INREQUESTED: `${TRANSACTIONS_ENDPOINTS}/products/mine/request`,
  },
  NOTIFICATIONS: {
    ALL: `${NOTIFICATIONS_ENDPOINTS}`,
    STATUS: `${NOTIFICATIONS_ENDPOINTS}/status`
  },
  RESERVATIONS: {
    ALL: `${RESERVATIONS_ENDPOINTS}`
  },
  FILES: {
    UPLOAD: `${FILES_ENDPOINTS}/upload`,
    UPLOAD_MULTIPLE: `${FILES_ENDPOINTS}/upload-multiple`
  },
  REGIONS: {
    ALL: REGIONS_ENDPOINTS,
    CITIES: `${REGIONS_ENDPOINTS}/id/cities`
  },
  NATIONALTIES: {
    ALL: NATIONALITIES_ENDPOINTS
  },
  LANGUAGES: {
    ALL: LANGUAGES_ENDPOINTS
  },
  PLACES: {
    PLACES: PLACES_ENDPOINTS,
    MINE: `${PLACES_ENDPOINTS}/mine`
  },
  PROFITS: {
    TRANSACTION: `${PROFITS_ENDPOINTS}/transaction`,
    TRANSPORT: `${PROFITS_ENDPOINTS}/transport`
  },
  PERIODS: PERIOD_ENDPOINTS,
  COOLING: COOLING_ENDPOINTS,
  MARKET_TIME: MARKET_TIME_ENDPOINTS,
  LOANS: {
    LOANS: LOANS_ENDPOINTS,
    MINE: `${LOANS_ENDPOINTS}/mine`
  },
  FUNDS: {
    FUNDS: FUNDS_ENDPOINTS,
    MINE: `${FUNDS_ENDPOINTS}/mine`,
    EXPORT_EXCEL: `${FUNDS_ENDPOINTS}/export-excel`,

  },

  DEPOSITS: {
    DEPOSITS: DEPOSITS_ENDPOINTS,
    MINE: `${DEPOSITS_ENDPOINTS}/mine`,
  },
  TOPICS: {
    TOPICS: TOPICS_ENDPOINTS
  },
  SUPPORT: {
    SUPPORT: SUPPORT_ENDPOINTS
  },
  USER: {
    BY_ID: `${USERS_ENDPOINTS}/`,
    CHANGE_PASSWORD: `${USERS_ENDPOINTS}/change-password`,
    ACCOUNTS: `${USERS_ENDPOINTS}/me/account`,
    BUSINESS_INFO: `${USERS_ENDPOINTS}/me/business-info`,
    PERSONAL_INFO: `${USERS_ENDPOINTS}/me/personal-info`,
    TRANSPORTER_BUSINESS_INFO: `${USERS_ENDPOINTS}/me/transporter-business-info`,
    USER_REGISTER: `${USERS_ENDPOINTS}/me/commercial-register`,
    TRANSPORTER_INFO: `${USERS_ENDPOINTS}/me/transporter-info`,
    GAINS: `${USERS_ENDPOINTS}/me/gains`,
    ME: `${USERS_ENDPOINTS}/me`,
    CHECK_PAIRING_CODE: `${USERS_ENDPOINTS}/check-pairing-code`,
    PAIRED: `${USERS_ENDPOINTS}/paired`,

    CHANGE_EMAIL_REQUEST: `${USERS_ENDPOINTS}/change-email-request`,
    CHNAGE_PHONE_REQUEST: `${USERS_ENDPOINTS}/change-phone-request`,
    CONFIRM_CHANGE_PHONE: `${USERS_ENDPOINTS}/confirm-change-phone`,
    CONFIRM_CHANGE_EMAIL: `${USERS_ENDPOINTS}/confirm-change-email`,
  },
  RECIEVE_MONEY: {
    ALL: `${RECIEVE_MONEY_ENDPOINTS}`,
    MINE: `${RECIEVE_MONEY_ENDPOINTS}/mine`
  },
  RULES: {
    ALL: `${RULES_ENDPOINTS}`,
    APPROVE: `${RULES_ENDPOINTS}/approve`
  },
  FORSA_PROGRAM: {
    INSCRIPTIONS: `${FORSA_PROGRAM}`,
    MINE: `${FORSA_PROGRAM}/mine`,
    PROGRAMS: `${FORSA_PROGRAM}/programs`,
  },
  HARDCODED_MOCK: {
    CATEGORIES_SUB: `${CATEGORIES_ENDPOINTS}/1999/sub`,
    SUB_CATEGORY: `${CATEGORIES_ENDPOINTS}/1999`,
    PRODUCT_BY_CATEGORY: `${PRODUCT_ENDPOINTS}/categories/1999`,
    PRODUCT_BY_ID: `${PRODUCT_ENDPOINTS}/1999`,
    CITIES: `${REGIONS_ENDPOINTS}/1999/cities`
  }
};

export const APP_MOCKS = {
  [APP_ENDPOINTS.CATEGORIES.ALL]: categories_old,
  [APP_ENDPOINTS.CATEGORIES.SUB]: categories_old,
  [APP_ENDPOINTS.CATEGORIES.PARENT]: categories_old,
  [APP_ENDPOINTS.RESERVATIONS.ALL]: reservations,
  [APP_ENDPOINTS.NOTIFICATIONS.ALL]: notifications,
  [APP_ENDPOINTS.AUTH.SIGN_IN]: login,
  [APP_ENDPOINTS.AUTH.OTP]: otp,
  [APP_ENDPOINTS.PRODUCTS.TYPES]: product_types,
  [APP_ENDPOINTS.PRODUCTS.UNITS]: product_units,
  [APP_ENDPOINTS.PRODUCTS.PACKAGES]: product_packages,
  [APP_ENDPOINTS.PRODUCTS.QUALITIES]: product_qualities,
  [APP_ENDPOINTS.PRODUCTS.QUALITIES]: product_qualities,
  [APP_ENDPOINTS.PRODUCTS.COUNTRIES]: product_countries,
  [APP_ENDPOINTS.REGIONS.ALL]: regions,
  [APP_ENDPOINTS.LANGUAGES.ALL]: languages,
  [APP_ENDPOINTS.NATIONALTIES.ALL]: nationalities,
  [APP_ENDPOINTS.TRANSPORT.CAR_TYPES]: car_types,
  [APP_ENDPOINTS.PLACES.MINE]: places,
  [APP_ENDPOINTS.TRANSACTIONS.MINE]: transactions,
  [APP_ENDPOINTS.TRANSPORT.ALL]: transporters,
  [APP_ENDPOINTS.TRANSPORT.CARS]: cars,
  [APP_ENDPOINTS.COOLING]: cooling,
  [APP_ENDPOINTS.MARKET_TIME]: marketTime,
  // HARDCODED MOCK FOR DEMO ( DYNAMIC ENDPOINTS DEPENDS ON IDs )
  [APP_ENDPOINTS.HARDCODED_MOCK.CATEGORIES_SUB]: categories_old,
  [APP_ENDPOINTS.HARDCODED_MOCK.PRODUCT_BY_CATEGORY]: products,
  [APP_ENDPOINTS.HARDCODED_MOCK.SUB_CATEGORY]: category,
  [APP_ENDPOINTS.HARDCODED_MOCK.PRODUCT_BY_ID]: product,
  [APP_ENDPOINTS.HARDCODED_MOCK.CITIES]: cities
};
