import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AuthService } from '../../auth/services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private sharedService: SharedService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.sharedService?.isMock) {
      let authRequest = req;
      if (this.authService.isLoggedIn() && !req.url.includes('access_key')) {
        const authToken = this.authService.getToken();
        authRequest = req.clone({
          headers: req.headers.set(
            'authorization',
            'Bearer ' + authToken.toString()
          )
        });
      }

      return next.handle(authRequest);
    } else {
      return next.handle(req);
    }
  }
}
