export enum USER_TYPES {
  BUYER = 'BUYER',
  SELLER = 'SELLER',
  MARKETER = 'MARKETER',
  CONTROLLER = 'CONTROLLER',
  TRANSPORTER = 'TRANSPORTER',
  TRANSPORTER_AGENT = 'TRANSPORTER_AGENT',
  TRANSPORTER_COMPANY = 'TRANSPORTER_COMPANY',
  BUYER_SELLER = 'BUYER_SELLER'
}

export enum PRODUCT_TYPES {
  IMPORTED = 'IMPORTED',
  LOCAL = 'LOCAL'
}

export enum MODAL_TYPES {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  INFO = 'INFO',
  WARNING = 'WARNING'
}

export enum REPORT_TYPES {
  PENDING = 'PENDING',

  CONFIRMED = 'CONFIRMED',

  INVESTIGATING = 'INVESTIGATING',

  DONE = 'DONE',

  CLOSED = 'CLOSED',

  APPROVED_BY_ADMIN = 'APPROVED_BY_ADMIN',

  REFUSED_BY_ADMIN = 'REFUSED_BY_ADMIN'
  
};

export enum MONEY_TYPES {
  WAITING = 'WAITING',
  APPROVED = 'APPROVED',
  REFUSED = 'REFUSED'
};

export enum RATINGS {
  GOLD = 'GOLD',
  SILVER = 'SILVER',
  PLATINUM = 'PLATINUM'
};