import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, EventEmitter, NgZone, OnInit, Output, ViewChild, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from '../../modal/service/modal.service';
import { SharedService } from '../../services/shared.service';
import { NgxSpinnerService } from 'ngx-spinner';
var geoCoder = new google.maps.Geocoder();

@Component({
  selector: 'app-add-place',
  templateUrl: './add-place.component.html',
  styleUrls: ['./add-place.component.scss']
})
export class AddPlaceComponent implements OnInit, AfterViewInit {
  @Output() onAddplaces = new EventEmitter();

  @ViewChild('search') searchElementRef: ElementRef;
  form: FormGroup;

  newPlace: any;

  coords: string;
  zoom: number;
  address: string;
 
  title: string = 'AGM project';
  latitude: number;
  longitude: number;
  placeName: string;
  zip_code: string;
  lat: number;
  lng: number;

  constructor(
    private sharedService: SharedService,
    private toastr: ToastrService,
    private modalService: ModalService,
    private mapsAPILoader: MapsAPILoader,
    private spinnerService: NgxSpinnerService,
    private ngZone: NgZone
  ) { }

  ngOnInit(): void {
   
    this.findAdress();
    //load Places Autocomplete
  
    this.initializeForm();
  }

  upadteCoordinates(value: string) {
    console.error(value)
    const latitude = Number(value.split(',')[0])
    const longitude = Number(value.split(',')[1])

    if (latitude && longitude) {
      this.latitude = latitude;
      this.longitude = longitude;
      this.getAddress(this.latitude, this.longitude);
      this.searchElementRef.nativeElement.value=''
   
    }

  }

  // Get Current Location Coordinates
  setCurrentLocation() {
    if ('geolocation' in navigator) {
      this.spinnerService.show();
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
        this.searchElementRef.nativeElement.value=''
        this.coords = ''
        this.spinnerService.hide();
      },
      (error)=> {
        this.toastr.warning('يرجى تفعيل خدمة تحديد المواقع في المتصفح','لا يمكن الوصول إلى موقعك!');
        this.spinnerService.hide();
    
      });
    } else {
      this.spinnerService.hide();
    }
  }

  ngAfterViewInit(): void {
    this.findAdress();
  }

  findAdress() {
    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          // some details
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          console.log(place)
          this.address = place.formatted_address;

          this.placeName = place.name;
          this.zip_code = place.address_components[place.address_components.length - 1].long_name;
          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 16;
          this.coords = ''
        });
      });
    });
  }


  markerDragEnd($event: any) {
    console.log($event);
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude, longitude) {
    geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      console.log(results);
      console.log(status);
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }


  initializeForm() {
    this.form = new FormGroup({
      title: new FormControl('', { validators: [Validators.required] }),
    })
  }

  mapClicked($event) {
    console.log($event);
    const latitude = $event?.coords?.lat;
    const longitude = $event?.coords?.lng;
    this.newPlace = {
      longitude,
      latitude
    };
    this.form.controls.longitude.setValue(this.newPlace.longitude);
    this.form.controls.latitude.setValue(this.newPlace.latitude);

  }

  addPlace() {
    if (!this.form.value.title) {
      this.toastr.error('الرجاء إختيار المكان و عنوانه ');
      return;
    }

    const requestData = {
      ...this.form.value,
      longitude: this.longitude,
      latitude: this.latitude
    };
    this.onAddplaces.emit(requestData);
  }

}
