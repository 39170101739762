<nav
*ngIf="connectedUser"
  class="sidenav navbar navbar-vertical fixed-right navbar-expand-xs navbar-light bg-white"
  id="sidenav-main"
>
  <div class="scroll-wrapper scrollbar-inner">
    <!-- Brand -->
    <div
      class="scrollbar-inner scroll-content scroll-scrolly_visible"
      style="
        height: auto;
        margin-bottom: 0px;
        margin-right: 0px;
        max-height: 738.4px;
      "
    >
      <div class="sidenav-header text-center">
        <a class="navbar-brand">
          <span class="site-title text-primary">
            <span class="main-font-en text-lowercase">Kharaaj</span> خراج</span
          >
        </a>
      </div>

      <div class="navbar-inner">
        <hr />
        <!-- Collapse -->
        <div class="collapse navbar-collapse" id="sidenav-collapse-main">
          <!-- Nav items -->
          <ul class="navbar-nav">
            <li (click)="hideMenu()" class="nav-item">
              <!-- HOME -->
              <a
               
                class="nav-link"
                [ngClass]="{
                  active: currentSection === ROUTES.PRIVATE.DASHBOARD.HOME
                }"
                (click)="changeCurrentSection(ROUTES.PRIVATE.DASHBOARD.HOME)"
                role="button"
              >
                <span class="nav-link-text">الرئيسية</span>
                <i class="ni ni-shop text-primary"></i>
              </a>
            </li>
            <li
              *ngIf="
                type === USER_TYPES.BUYER_SELLER ||
                type === USER_TYPES.BUYER ||
                type === USER_TYPES.SELLER ||
                type === USER_TYPES.TRANSPORTER_COMPANY ||
                type === USER_TYPES.TRANSPORTER_AGENT ||
                type === USER_TYPES.MARKETER
              "
              class="nav-item"
            >
              <!-- MY ACCOUNT -->
              <a
                class="nav-link"
                [ngClass]="{
                  active: currentSection === ROUTES.PRIVATE.DASHBOARD.MY_ACCOUNT
                }"
                (click)="
                  changeCurrentSection(ROUTES.PRIVATE.DASHBOARD.MY_ACCOUNT)
                "
                role="button"
              >
                <span class="nav-link-text">حسابي</span>
                <i class="ni ni-circle-08 text-primary"></i>
              </a>
              <ul
                class="s-level"
                *ngIf="currentSection === ROUTES.PRIVATE.DASHBOARD.MY_ACCOUNT"
              >
                <li (click)="hideMenu()" class="s-nav-item">
                  <a
                    class="s-nav-link"
                    [ngClass]="{
                      active:
                        currentSubSection ===
                        ROUTES.PRIVATE.DASHBOARD.MY_ACCOUNT
                    }"
                    (click)="
                      changeCurrentSubSection(
                        ROUTES.PRIVATE.DASHBOARD.MY_ACCOUNT_CHILDS.WALLET
                      )
                    "
                    role="button"
                  >
                    <span class="nav-link-text">محفظتي</span>
                    <span class="nav-link-text">&nbsp; &#8226;</span>
                  </a>
                </li>
              </ul>
            </li>
            <li
              *ngIf="
                type === USER_TYPES.BUYER_SELLER ||
                type === USER_TYPES.BUYER ||
                type === USER_TYPES.SELLER ||
                type === USER_TYPES.TRANSPORTER_AGENT ||
                type === USER_TYPES.TRANSPORTER_COMPANY
              "
              class="nav-item"
            >
              <!-- TRANSPORT -->
              <a
                class="nav-link"
                [ngClass]="{
                  active: currentSection === ROUTES.PRIVATE.DASHBOARD.TRANSPORT
                }"
                (click)="
                  changeCurrentSection(ROUTES.PRIVATE.DASHBOARD.TRANSPORT)
                "
                role="button"
              >
                <span class="nav-link-text">قسم النقل</span>
                <i class="ni ni-bus-front-12 text-primary"></i>
              </a>
              <ul
                class="s-level"
                *ngIf="currentSection === ROUTES.PRIVATE.DASHBOARD.TRANSPORT"
              >
                <li (click)="hideMenu()" class="s-nav-item">
                  <a
                    class="s-nav-link"
                    [ngClass]="{
                      active:
                        currentSubSection ===
                        ROUTES.PRIVATE.DASHBOARD.TRANSPORT_CHILDS.REQUESTS
                    }"
                    (click)="
                      changeCurrentSubSection(
                        ROUTES.PRIVATE.DASHBOARD.TRANSPORT_CHILDS.REQUESTS
                      )
                    "
                    role="button"
                  >
                    <span class="nav-link-text">طلبات السعر</span>
                    <span class="nav-link-text">&nbsp; &#8226;</span>
                  </a>
                </li>
                <hr>
                <li (click)="hideMenu()" class="s-nav-item">
                  <a
                    class="s-nav-link"
                    [ngClass]="{
                      active:
                        currentSubSection ===
                        ROUTES.PRIVATE.DASHBOARD.TRANSPORT_CHILDS.HISTORY
                    }"
                    (click)="
                      changeCurrentSubSection(
                        ROUTES.PRIVATE.DASHBOARD.TRANSPORT_CHILDS.HISTORY
                      )
                    "
                    role="button"
                  >
                    <span class="nav-link-text">توصيلاتى</span>
                    <span class="nav-link-text">&nbsp; &#8226;</span>
                  </a>
                </li>
                <hr>
                <li (click)="hideMenu()" class="s-nav-item">
                  <a
                    class="s-nav-link"
                    [ngClass]="{
                      active:
                        currentSubSection ===
                        ROUTES.PRIVATE.DASHBOARD.MY_TRANSPORT_REPORTS
                    }"
                    (click)="
                      changeCurrentSubSection(
                        ROUTES.PRIVATE.DASHBOARD.MY_TRANSPORT_REPORTS
                      )
                    "
                    role="button"
                  >
                    <span class="nav-link-text">شكاوى النقل</span>
                    <span class="nav-link-text">&nbsp; &#8226;</span>
                  </a>
                </li>
              </ul>
            </li>

            <li
              *ngIf="
                type === USER_TYPES.BUYER_SELLER ||
                type === USER_TYPES.BUYER ||
                type === USER_TYPES.SELLER
              "
              (click)="hideMenu()"
              class="nav-item"
            >
              <a
                class="nav-link"
                [ngClass]="{
                  active:
                    currentSection === ROUTES.PRIVATE.DASHBOARD.TRANSACTIONS
                }"
                (click)="
                  changeCurrentSection(ROUTES.PRIVATE.DASHBOARD.TRANSACTIONS)
                "
                role="button"
              >
                <span class="nav-link-text">معاملاتي المالية </span>
                <i class="ni ni-chart-bar-32 text-primary"></i>
              </a>
            </li>
            <!-- MY TRANSACTIONS -->
            <li
              *ngIf="
                type === USER_TYPES.BUYER_SELLER || type === USER_TYPES.BUYER || type === USER_TYPES.SELLER
              "
              (click)="hideMenu()"
              class="nav-item"
            >
              <!-- MY RESERVATIONS -->
              <a
                class="nav-link"
                [ngClass]="{
                  active:
                    currentSection === ROUTES.PRIVATE.DASHBOARD.MY_RESERVATIONS
                }"
                (click)="
                  changeCurrentSection(ROUTES.PRIVATE.DASHBOARD.MY_RESERVATIONS)
                "
              >
                <span class="nav-link-text">حجوزاتي</span>
                <i class="ni ni-tag text-primary"></i>
              </a>
            </li>

            <li
            *ngIf="
              type === USER_TYPES.BUYER_SELLER || type === USER_TYPES.BUYER || type === USER_TYPES.SELLER
            "
            (click)="hideMenu()"
            class="nav-item"
          >
            <!-- MY RESERVATIONS -->
            <a
              class="nav-link"
              [ngClass]="{
                active:
                  currentSection === ROUTES.PRIVATE.DASHBOARD.MY_RESERVATION_REPORTS
              }"
              (click)="
                changeCurrentSection(ROUTES.PRIVATE.DASHBOARD.MY_RESERVATION_REPORTS)
              "
            >
              <span class="nav-link-text">
                شكاوى الحجوزات
              </span>
              <i class="ni ni-tag text-primary"></i>
            </a>
          </li>

            <!--MY PRODUCTS -->
            <li
            *ngIf="
              type === USER_TYPES.BUYER_SELLER  || type === USER_TYPES.SELLER
            "
            (click)="hideMenu()"
            class="nav-item"
          >
            <!-- MY RESERVATIONS -->
            <a
              class="nav-link"
              [ngClass]="{
                active:
                  currentSection === ROUTES.PRIVATE.DASHBOARD.MY_PRODUCTS
              }"
              (click)="
                changeCurrentSection(ROUTES.PRIVATE.DASHBOARD.MY_PRODUCTS)
              "
            >
              <span class="nav-link-text">منتجاتي</span>
              <i class="ni ni-tag text-primary"></i>
            </a>
          </li>
            <!-- FORSA -->
         
          </ul>
        </div>
      </div>
    </div>
  </div>
</nav>
