export const notifications = [
  {
    id: '9999',
    sender_name: 'Zeineb Haj Ali',
    sender_avatar: 'assets/img/theme/team-4.jpg',
    title: 'عرض جديد',
    content: '... تم إضافة عرض جديد',
    isSeen: false,
  },
  {
    id: '9999',
    sender_name: 'Sadok Mourad',
    sender_avatar: 'assets/img/theme/team-3.jpg',
    title: 'عرض جديد',
    content: '... تم إضافة عرض جديد',
    isSeen: true,
  },
  {
    id: '9999',
    sender_name: 'Hasan Baki',
    sender_avatar: 'assets/img/theme/team-1.jpg',
    title: 'عرض جديد',
    content: '... تم إضافة عرض جديد',
    isSeen: true,
  },
  {
    id: '9999',
    sender_name: 'Rashid Muadh',
    sender_avatar: 'assets/img/theme/team-2.jpg',
    title: 'عرض جديد',
    content: '... تم إضافة عرض جديد',
    isSeen: true,
  }
];
