export const login = {
  user: {
    id: '3d8069c5-0779-42cb-b563-9b48828519ee',
    email: 'sadokmhiri@gmail.com',
    firstName: 'Sadok',
    lastName: 'Mhiri',
    phoneNumber: '+21620166525',
    city: 'Sousse',
    postalCode: '4011',
    address: '7 Rue Bouali Amine Sousse',
    type: 'BUYER_SELLER',
    createdAt: '2021-07-09T15:35:22.154Z',
    updatedAt: '2021-07-09T15:36:46.000Z',
    experyDateOfcr: '2021-07-11T00:00:00.000Z',
    avatar: {
      id: 'b4ce12a0-f53f-4e88-8918-81c0d1356c3d',
      mimetype: 'image/png',
      filename: 'file-1625844922053.png',
      extension: 'png',
      size: 4709,
      protected_url: '/files/file-1625844922053.png/download',
      public_url: '/public/uploads/file-1625844922053.png',
      createdAt: '2021-07-09T15:35:22.120Z',
      updatedAt: '2021-07-09T15:35:22.120Z'
    }
  },
  token:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjNkODA2OWM1LTA3NzktNDJjYi1iNTYzLTliNDg4Mjg1MTllZSIsImlhdCI6MTYyNTg0NTAwNiwiZXhwIjoxNjI4NDM3MDA2fQ.nhjPuv8z1eNEmkUWVuyI7O0JlTA6h8dxZyOXVwkhrpA'
};
