<div
  *ngIf="category"
  class="card card-profile c-pointer p-2"
  data-image="img-raised"
  [routerLink]="[
  APP_ROUTES.PRIVATE.DASHBOARD.HOME_CHILDS.SUB_CATEGORY,
  isDemo ? '1999' : category?.id
  ]">
  <div
    [routerLink]="[
    APP_ROUTES.PRIVATE.DASHBOARD.HOME_CHILDS.SUB_CATEGORY,
    isDemo ? '1999' : category?.id
    ]"
    class="card-header-image d-flex c-pointer outline-none">
    <span class="points"> </span>
    <div
      class="card-title text-primary text-right fs-18 c-pointer outline-none"
      [routerLink]="[
      APP_ROUTES.PRIVATE.DASHBOARD.HOME_CHILDS.SUB_CATEGORY,
      isDemo ? '1999' : category?.id
      ]">
      <span class="c-pointer">{{ category?.name }}</span>
      <img width="80%" height="100px" class="" [src]="
        BASE_API + category?.attachment?.filename">
    </div>
  </div>

  <div
    *ngIf="showChart && !isSameDay"
    class="card-body d-flex justify-content-center">
    <apx-chart
      #muuuuu
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [xaxis]="chartOptions.xaxis"
      [stroke]="chartOptions.stroke"
      [dataLabels]="chartOptions.dataLabels"
      [yaxis]="chartOptions.yaxis"
      [labels]="chartOptions.labels"
      [title]="chartOptions.title"
      [colors]="['#5ba556', '#42c425', '#7bf96d']"></apx-chart>
  </div>




  <div class="card-footer text-center">
    <div class="boxes-price">
      <div class="box-price">
        <div class="box-price_header">
          الأقل سعر / كلغ
           </div>
        <div class="box-price_footer">
          <div class="d-flex justify-content-end">
            <span class="mr-2">
              {{ CURRENCY }}
            </span>
            <span>
              {{ category?.productsLowestPrice || '0' }}
            </span>

          </div>
        </div>
      </div>
      <div class="box-price">
        <div class="box-price_header">       أعلى سعر / كلغ</div>
        <div class="box-price_footer">
          <div class="d-flex justify-content-end">
            <span class="mr-2">
              {{ CURRENCY }}
            </span>
            <span>
              {{ category?.productsHighestPrice || '0' }}
            </span>

          </div>

        </div>
      </div>
    </div>
  </div>
</div>
