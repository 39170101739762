import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MODAL_TYPES } from 'src/app/core/enums';
import { ModalConfig } from 'src/app/core/types';
import { ModalService } from '../service/modal.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, OnDestroy {
  @ViewChild('btnDisplaySuccessModal') btnDisplaySuccessModal: any;
  @ViewChild('btnDisplayErrorModal') btnDisplayErrorModal: any;
  @ViewChild('btnDisplayInfoModal') btnDisplayInfoModal: any;

  componentDestroyed$ = new Subject<boolean>();

  type: MODAL_TYPES;
  title: string;
  description: string;

  constructor(private modalService: ModalService) {}

  ngOnInit(): void {
    this.modalService
      .getModalSubAsObservable()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((config: ModalConfig) => {
        const { type, title, description } = config;
        this.type = type;
        this.title = title;
        this.description = description;
        if (type === MODAL_TYPES.SUCCESS) {
          this.showSuccessModal();
        }
        if (type === MODAL_TYPES.ERROR) {
          this.showErrorModal();
        }
        if (type === MODAL_TYPES.INFO) {
          this.showInfoModal();
        }
      });
  }

  showSuccessModal() {
    this.btnDisplaySuccessModal?.nativeElement?.click();
  }

  showErrorModal() {
    this.btnDisplayErrorModal?.nativeElement?.click();
  }

  showInfoModal() {
    this.btnDisplayInfoModal?.nativeElement?.click();
  }

  ngOnDestroy() {
    this.componentDestroyed$.complete();
  }
}
