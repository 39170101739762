import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepperComponent } from './components/stepper/stepper.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './layout/header/header.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { NavbarComponent } from './layout/navbar/navbar.component';
import { ModalComponent } from './modal/modal/modal.component';
import { NotificationComponent } from './components/notification/notification.component';
import { ListNotificationsComponent } from './components/list-notifications/list-notifications.component';
import { AudioRecordComponent } from './components/audio-record/audio-record.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { DemoAlertComponent } from './components/demo-alert/demo-alert.component';
import { CategoryCardComponent } from './components/category-card/category-card.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ProductCardComponent } from './components/product-card/product-card.component';
import { PlacesComponent } from './components/places/places.component';
import { AddPlaceComponent } from './components/add-place/add-place.component';
import { AgmCoreModule } from '@agm/core';
import { ContactComponent } from './components/contact/contact.component';
import { UserCardComponent } from './components/user-card/user-card.component';
import { AddAccountComponent } from './components/add-account/add-account.component';
import { RatingComponent } from './components/rating/rating.component';
import { RulesComponent } from './components/rules/rules.component';
import { CommercialRegisterComponent } from './components/commercial-register/commercial-register.component';
import { RulesRegisterComponent } from './components/rules-register/rules-register.component';
import { FooterComponent } from './layout/footer/footer.component';
import { TransporterIdentificationComponent } from './components/transporter-identification/transporter-identification.component';
import { NgxImageCompressService } from 'ngx-image-compress';
import { NgAudioRecorderModule } from 'ng-audio-recorder';

@NgModule({
  declarations: [
    StepperComponent,
    HeaderComponent,
    SidebarComponent,
    NavbarComponent,
    ModalComponent,
    NotificationComponent,
    ListNotificationsComponent,
    AudioRecordComponent,
    CarouselComponent,
    DemoAlertComponent,
    CategoryCardComponent,
    ProductCardComponent,
    PlacesComponent,
    AddPlaceComponent,
    ContactComponent,
    UserCardComponent,
    AddAccountComponent,
    RatingComponent,
    RulesComponent,
    CommercialRegisterComponent,
    RulesRegisterComponent,
    FooterComponent,
    TransporterIdentificationComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgAudioRecorderModule,
    RouterModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDtrTzC8evG8i7SjFqNMks7p6ALESEwwO8',
      libraries: ['places']
    }),
    NgApexchartsModule
  ],
  exports: [
    AgmCoreModule,
    
    StepperComponent,
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    NavbarComponent,
    ModalComponent,
    NotificationComponent,
    AudioRecordComponent,
    CarouselComponent,
    DemoAlertComponent,
    CategoryCardComponent,
    ProductCardComponent,
    ModalComponent,
    PlacesComponent,
    AddPlaceComponent,
    ContactComponent,
    UserCardComponent,
    AddAccountComponent,
    RatingComponent,
    RulesComponent,
    CommercialRegisterComponent,
    RulesRegisterComponent,
    AddAccountComponent,
    TransporterIdentificationComponent
  ],
  providers: [NgxImageCompressService],
})
export class SharedModule {}
