export const car_types = [
  {
    id: '345073fd-b33a-4bec-b53a-260fc4a566be',
    key: 'نوع السيارة 6',
    createdAt: '2021-08-04T08:39:26.815Z',
    updatedAt: '2021-08-04T08:39:26.815Z'
  },
  {
    id: '540f247c-8b68-4322-a2bf-e2275ed004a0',
    key: 'نوع السيارة 3',
    createdAt: '2021-08-04T08:38:51.791Z',
    updatedAt: '2021-08-04T08:38:51.791Z'
  },
  {
    id: 'a0ddf847-6cf0-4f27-8b08-663147936e99',
    key: 'نوع السيارة 5',
    createdAt: '2021-08-04T08:39:17.013Z',
    updatedAt: '2021-08-04T08:39:17.013Z'
  },
  {
    id: 'c0ee62d7-8da1-4dd5-b25c-6530c86362ae',
    key: 'نوع السيارة 1',
    createdAt: '2021-08-04T08:38:29.889Z',
    updatedAt: '2021-08-04T08:38:29.889Z'
  },
  {
    id: 'c1213be8-9503-4389-8255-7d2f682f0f63',
    key: 'نوع السيارة 2',
    createdAt: '2021-08-04T08:38:46.217Z',
    updatedAt: '2021-08-04T08:38:46.217Z'
  },
  {
    id: 'f6b126d2-8025-473b-8e61-db7de83e080d',
    key: 'نوع السيارة 4',
    createdAt: '2021-08-04T08:39:05.155Z',
    updatedAt: '2021-08-04T08:39:05.155Z'
  }
];

export const transporterAgents = [
  {
    id: '1999',
    email: 'zaynebhadj@yopmail.com',
    firstName: 'Zayneb',
    lastName: 'Hadj',
    phoneNumber: '+21620166525',
    city: 'Gabes',
    postalCode: '4000',
    address: '7 Rue Gabes 777',
    type: 'TRANSPORTER_AGENT',
    createdAt: '2021-09-03T17:19:31.733Z',
    updatedAt: '2021-09-03T17:19:31.733Z',
    nationality: 'Tunisian',
    language: 'Arabe',
    experyDateOfdl: '2021-09-03T18:18:55.000Z'
  },
  {
    id: '1888',
    email: 'zaynebhadj@yopmail.com',
    firstName: 'Zayneb',
    lastName: 'Hadj',
    phoneNumber: '+21620166525',
    city: 'Gabes',
    postalCode: '4000',
    address: '7 Rue Gabes 777',
    type: 'TRANSPORTER_AGENT',
    createdAt: '2021-09-03T17:19:31.733Z',
    updatedAt: '2021-09-03T17:19:31.733Z',
    nationality: 'Tunisian',
    language: 'Arabe',
    experyDateOfdl: '2021-09-03T18:18:55.000Z'
  },
  {
    id: '1777',
    email: 'zaynebhadj@yopmail.com',
    firstName: 'Zayneb',
    lastName: 'Hadj',
    phoneNumber: '+21620166525',
    city: 'Gabes',
    postalCode: '4000',
    address: '7 Rue Gabes 777',
    type: 'TRANSPORTER_AGENT',
    createdAt: '2021-09-03T17:19:31.733Z',
    updatedAt: '2021-09-03T17:19:31.733Z',
    nationality: 'Tunisian',
    language: 'Arabe',
    experyDateOfdl: '2021-09-03T18:18:55.000Z'
  }
];

export const transporterCompanies = [
  {
    id: '1999',
    email: 'mohamedsaleh@yopmail.com',
    firstName: 'Mohamed',
    lastName: 'Saleh',
    phoneNumber: '+21625990008',
    city: 'Hammam Sousse',
    postalCode: '4000',
    address: 'Hammam Sousse',
    type: 'TRANSPORTER_COMPANY',
    createdAt: '2021-09-03T17:28:16.417Z',
    updatedAt: '2021-09-03T17:28:16.417Z',
    experyDateOfcr: '2021-09-03T18:18:55.000Z'
  },
  {
    id: '1666',
    email: 'mohamedsaleh@yopmail.com',
    firstName: 'Mohamed',
    lastName: 'Saleh',
    phoneNumber: '+21625990008',
    city: 'Hammam Sousse',
    postalCode: '4000',
    address: 'Hammam Sousse',
    type: 'TRANSPORTER_COMPANY',
    createdAt: '2021-09-03T17:28:16.417Z',
    updatedAt: '2021-09-03T17:28:16.417Z',
    experyDateOfcr: '2021-09-03T18:18:55.000Z'
  }
];

export const transporters = {
  transporterAgents,
  transporterCompanies
};

export const cars = [
  {
    id: '01c1c7a5-48fe-4b5a-aa6b-5733e716dcb1',
    title: 'AAAA',
    maxWeight: 999,
    fullName: 'aaaa aaaa',
    phoneNumber: '+21620111222',
    experyDateOfdl: '2025-08-31T15:13:55.000Z',
    createdAt: '2021-08-31T18:58:12.252Z',
    updatedAt: '2021-08-31T18:58:12.252Z',
    carType: {
      id: '345073fd-b33a-4bec-b53a-260fc4a566be',
      key: 'نوع السيارة 6',
      createdAt: '2021-08-04T08:39:26.815Z',
      updatedAt: '2021-08-04T08:39:26.815Z'
    },
    maxWeightUnit: {
      id: 'a358a0af-b33e-40c7-bc52-19c8511e4711',
      key: 'طن',
      createdAt: '2021-07-22T17:37:05.556Z',
      updatedAt: '2021-07-22T17:37:05.556Z'
    },
    language: {
      id: 'a45c4f20-2603-4283-8ed7-dd2ddb5dbbb1',
      key: 'العربية',
      createdAt: '2021-08-04T09:03:38.806Z',
      updatedAt: '2021-08-04T09:03:38.806Z'
    },
    nationality: {
      id: '99b24188-73db-4f6e-9073-35fb5b96ba20',
      key: 'المملكة العربية السعودية',
      createdAt: '2021-08-04T09:16:18.296Z',
      updatedAt: '2021-08-04T09:16:18.296Z'
    },
    region: {
      id: '0a18cb02-c44f-4da5-9bb8-40b54dd7df35',
      key: 'منطقة الجوف',
      createdAt: '2021-08-04T09:58:20.051Z',
      updatedAt: '2021-08-04T09:58:20.051Z'
    },
    city: {
      id: '28c658e5-b091-4e1f-885f-7c8400d485ba',
      key: 'القريات',
      createdAt: '2021-08-04T09:58:50.525Z',
      updatedAt: '2021-08-04T09:58:50.525Z'
    },
    place: {
      id: '5ba7327c-794a-43b7-84f6-3bb46bf42297',
      title: 'My Place',
      latitude: 48.80372848618392,
      longitude: 2.4011165276169777,
      createdAt: '2021-08-19T10:04:12.318Z',
      updatedAt: '2021-08-19T10:04:12.318Z'
    },
    coolingOption: {
      id: 'ffcf8f4b-896b-4066-a719-fff0cf4439dd',
      key: 'تبريد',
      createdAt: '2021-08-31T18:28:51.761Z',
      updatedAt: '2021-08-31T18:28:51.761Z'
    },
    vehicle: {
      id: '700ec391-7d60-4c31-9352-c76c1ab85557',
      mimetype: 'image/*',
      filename: 'file-1630436291712.png ',
      extension: 'png ',
      size: 24035,
      protected_url: '/files/file-1630436291712.png /download',
      public_url: '../../../assets/img/ill/inn.svg',
      createdAt: '2021-08-31T18:58:11.717Z',
      updatedAt: '2021-08-31T18:58:11.717Z'
    },
    greyCard: {
      id: '700ec391-7d60-4c31-9352-c76c1ab85557',
      mimetype: 'image/*',
      filename: 'file-1630436291712.png ',
      extension: 'png ',
      size: 24035,
      protected_url: '/files/file-1630436291712.png /download',
      public_url: '/public/uploads/file-1630436291712.png ',
      createdAt: '2021-08-31T18:58:11.717Z',
      updatedAt: '2021-08-31T18:58:11.717Z'
    },
    drivingLicense: {
      id: '700ec391-7d60-4c31-9352-c76c1ab85557',
      mimetype: 'image/*',
      filename: 'file-1630436291712.png ',
      extension: 'png ',
      size: 24035,
      protected_url: '/files/file-1630436291712.png /download',
      public_url: '/public/uploads/file-1630436291712.png ',
      createdAt: '2021-08-31T18:58:11.717Z',
      updatedAt: '2021-08-31T18:58:11.717Z'
    }
  },
  {
    id: '01c1c7a5-48fe-4b5a-aa6b-5733e716dcb1',
    title: 'BBBBB',
    maxWeight: 900,
    fullName: 'aaaa aaaa',
    phoneNumber: '+21620111222',
    experyDateOfdl: '2025-08-31T15:13:55.000Z',
    createdAt: '2021-08-31T18:58:12.252Z',
    updatedAt: '2021-08-31T18:58:12.252Z',
    carType: {
      id: '345073fd-b33a-4bec-b53a-260fc4a566be',
      key: 'نوع السيارة 6',
      createdAt: '2021-08-04T08:39:26.815Z',
      updatedAt: '2021-08-04T08:39:26.815Z'
    },
    maxWeightUnit: {
      id: 'a358a0af-b33e-40c7-bc52-19c8511e4711',
      key: 'طن',
      createdAt: '2021-07-22T17:37:05.556Z',
      updatedAt: '2021-07-22T17:37:05.556Z'
    },
    language: {
      id: 'a45c4f20-2603-4283-8ed7-dd2ddb5dbbb1',
      key: 'العربية',
      createdAt: '2021-08-04T09:03:38.806Z',
      updatedAt: '2021-08-04T09:03:38.806Z'
    },
    nationality: {
      id: '99b24188-73db-4f6e-9073-35fb5b96ba20',
      key: 'المملكة العربية السعودية',
      createdAt: '2021-08-04T09:16:18.296Z',
      updatedAt: '2021-08-04T09:16:18.296Z'
    },
    region: {
      id: '0a18cb02-c44f-4da5-9bb8-40b54dd7df35',
      key: 'منطقة الجوف',
      createdAt: '2021-08-04T09:58:20.051Z',
      updatedAt: '2021-08-04T09:58:20.051Z'
    },
    city: {
      id: '28c658e5-b091-4e1f-885f-7c8400d485ba',
      key: 'القريات',
      createdAt: '2021-08-04T09:58:50.525Z',
      updatedAt: '2021-08-04T09:58:50.525Z'
    },
    place: {
      id: '5ba7327c-794a-43b7-84f6-3bb46bf42297',
      title: 'My Place',
      latitude: 48.80372848618392,
      longitude: 2.4011165276169777,
      createdAt: '2021-08-19T10:04:12.318Z',
      updatedAt: '2021-08-19T10:04:12.318Z'
    },
    coolingOption: {
      id: 'ffcf8f4b-896b-4066-a719-fff0cf4439dd',
      key: 'تبريد',
      createdAt: '2021-08-31T18:28:51.761Z',
      updatedAt: '2021-08-31T18:28:51.761Z'
    },
    vehicle: {
      id: '700ec391-7d60-4c31-9352-c76c1ab85557',
      mimetype: 'image/*',
      filename: 'file-1630436291712.png ',
      extension: 'png ',
      size: 24035,
      protected_url: '/files/file-1630436291712.png /download',
      public_url: '../../../assets/img/ill/inn.svg',
      createdAt: '2021-08-31T18:58:11.717Z',
      updatedAt: '2021-08-31T18:58:11.717Z'
    },
    greyCard: {
      id: '700ec391-7d60-4c31-9352-c76c1ab85557',
      mimetype: 'image/*',
      filename: 'file-1630436291712.png ',
      extension: 'png ',
      size: 24035,
      protected_url: '/files/file-1630436291712.png /download',
      public_url: '/public/uploads/file-1630436291712.png ',
      createdAt: '2021-08-31T18:58:11.717Z',
      updatedAt: '2021-08-31T18:58:11.717Z'
    },
    drivingLicense: {
      id: '700ec391-7d60-4c31-9352-c76c1ab85557',
      mimetype: 'image/*',
      filename: 'file-1630436291712.png ',
      extension: 'png ',
      size: 24035,
      protected_url: '/files/file-1630436291712.png /download',
      public_url: '/public/uploads/file-1630436291712.png ',
      createdAt: '2021-08-31T18:58:11.717Z',
      updatedAt: '2021-08-31T18:58:11.717Z'
    }
  }
];

export const cooling = [
  {
    id: '1999',
    key: 'بدون غطاء',
    createdAt: '2021-08-31T18:29:12.904Z',
    updatedAt: '2021-08-31T18:29:12.904Z'
  },
  {
    id: 'a4dd4420-15d3-485d-934e-f0af06270282',
    key: 'تجميد',
    createdAt: '2021-08-31T18:29:00.974Z',
    updatedAt: '2021-08-31T18:29:00.974Z'
  },
  {
    id: 'ffcf8f4b-896b-4066-a719-fff0cf4439dd',
    key: 'تبريد',
    createdAt: '2021-08-31T18:28:51.761Z',
    updatedAt: '2021-08-31T18:28:51.761Z'
  }
];
