import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { INTERNATIONAL_PHONE_CODE, PHONE_LENGTH } from 'src/app/core/config';
import { APP_ROUTES } from 'src/app/core/config/routes';
import { USER_TYPES } from 'src/app/core/enums';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-internal-login',
  templateUrl: './internal-login.component.html',
  styleUrls: ['./internal-login.component.scss']
})
export class InternalLoginComponent implements OnInit {

  isPhoneSelected = true;
  isCodeSelected = false;

  selectPhone() {
    this.isCodeSelected = false;
    this.isPhoneSelected = true;
  }

  selectCode() {
    this.isCodeSelected = true;
    this.isPhoneSelected = false;
  }

  loginForm: FormGroup;
  phoneCode = INTERNATIONAL_PHONE_CODE;
  phoneLength: number = PHONE_LENGTH;
  APP_ROUTES = APP_ROUTES;

  componentDestroyed$ = new Subject<boolean>();
  isDemoMode: boolean;

  userTypes = USER_TYPES;
  selectedType: string = USER_TYPES.CONTROLLER;

  constructor(
    private authService: AuthService,
    private sharedService: SharedService,
    private router: Router,
    private toastr: ToastrService,
    private layoutService: LayoutService
  ) { }

  ngOnInit(): void {
    this.initializeForm();
    this.sharedService
      .getDemoStatus()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((status) => {
        this.isDemoMode = status;
      });
  }

  initializeForm() {
    this.loginForm = new FormGroup({
      phoneNumber: new FormControl('', {
        validators: [
          Validators.required,
          Validators.maxLength(this.phoneLength),
          Validators.minLength(this.phoneLength)
        ]
      })
    });

    this.setRegisterType(this.userTypes.CONTROLLER)
  }

  changeDemoMode() {
    this.sharedService.changeMockMode();
  }

  //TODO: Uncomment this block when we go back to OTP usage

  /*
  onSubmit() {
    this.authService.sendOtp(this.loginForm.value.phoneNumber).subscribe(
      (response) => {
        if (response) {
          this.router.navigate([APP_ROUTES.AUTH.OTP]);
        }
      },
      (error) => {
        this.toastr.error('! عذراً، لقد حصل خطأ');
      }
    );
  }
  */

  // TODO: Delete this block when we go back to OTP usage

  onSubmit() {
    console.log(this.loginForm.valid);
    console.log(this.loginForm.value);


    const requestData = {
      ...this.loginForm.value,
      phoneNumber: this.phoneCode + this.loginForm.value.phoneNumber,
      pairingCode: this.loginForm.value.pairingCode
    };
    if (this.selectedType != this.userTypes.MARKETER && this.selectedType != this.userTypes.CONTROLLER && this.selectedType != this.userTypes.TRANSPORTER_AGENT && this.selectedType != this.userTypes.TRANSPORTER && this.selectedType != this.userTypes.TRANSPORTER_COMPANY) {
      this.authService.sendOtp(this.loginForm.value.phoneNumber, this.selectedType).subscribe(
        (response) => {
          if (response) {
            this.toastr.success('أرسلنا رمز التفعيل إلى الهاتف والبريد الإلكتروني')
            this.router.navigate([APP_ROUTES.AUTH.OTP]);
          }
        }
      );
    } else {
      if (this.selectedType == this.userTypes.MARKETER) {
        if (this.isPhoneSelected) {
          delete requestData.phoneNumber
          requestData.pairingCode = this.phoneCode + requestData.pairingCode
        } else {
          delete requestData.phoneNumber
        }
      }
      this.authService
        .signin(this.selectedType, requestData)
        .subscribe((response: any) => {
          if (!this.sharedService?.isMock) {
            localStorage.setItem('user', JSON.stringify(response.data.user));
            localStorage.setItem('token', response.data.token);
          }
          this.toastr.success('! تم تسجيل الدخول بنجاح');
          this.layoutService.initializeSidebarListenners();
          this.router.navigate(['/']);
        });
    }

  }

  setRegisterType(choosedType) {
    this.selectedType = this.userTypes[choosedType];
    if (this.selectedType === this.userTypes.TRANSPORTER_AGENT || this.selectedType === this.userTypes.TRANSPORTER_COMPANY ||
      this.selectedType === this.userTypes.CONTROLLER) {
      this.loginForm.addControl('pwd', new FormControl('', { validators: [Validators.required] }))
      if (!this.loginForm.contains('phoneNumber')) {
        this.loginForm.addControl('phoneNumber', new FormControl('', {
          validators: [
            Validators.required,
            Validators.maxLength(this.phoneLength),
            Validators.minLength(this.phoneLength)
          ]
        }));
      }
    }
    else if (this.selectedType === this.userTypes.MARKETER) {
      this.loginForm.addControl('pairingCode', new FormControl('', { validators: [Validators.required] }))
      this.loginForm.addControl('pwd', new FormControl('', { validators: [Validators.required] }))
      if (this.loginForm.contains('phoneNumber')) {
        this.loginForm.removeControl('phoneNumber');
      }
    }
    else {
      if (!this.loginForm.contains('phoneNumber')) {
        this.loginForm.addControl('phoneNumber', new FormControl('', {
          validators: [
            Validators.required,
            Validators.maxLength(this.phoneLength),
            Validators.minLength(this.phoneLength)
          ]
        }));
      }
      if (this.loginForm.contains('pwd')) {
        this.loginForm.removeControl('pwd');
      }
      if (this.loginForm.contains('pairingCode')) {
        this.loginForm.removeControl('pairingCode');
      }
    }
    console.log(this.selectedType);
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.complete();
  }

}
