import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { INTERNATIONAL_PHONE_CODE } from 'src/app/core/config';
import { APP_ROUTES } from 'src/app/core/config/routes';
import { APP_ENDPOINTS } from 'src/app/core/endpoints/app.endpoints';
import { USER_TYPES } from 'src/app/core/enums';
import { SharedService } from 'src/app/shared/services/shared.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  phoneNumber: number;
  phoneCode = INTERNATIONAL_PHONE_CODE;
  type: any;
  email: any;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private sharedService: SharedService
  ) { }

  signUp(
    type: string,
    requestData: any,
    identityCard?: any,
    drivingLicense?: any
  ) {
    const formData = new FormData();

    formData.append('avatar', requestData.avatar);
    delete requestData?.avatar;
    if (requestData?.identityCard) {
      formData.append('identityCard', requestData?.identityCard);
      delete requestData?.identityCard;
    }
    if (requestData?.drivingLicense) {
      formData.append('drivingLicense', requestData?.drivingLicense);
      delete requestData?.drivingLicense;
    }
    if (type === USER_TYPES.BUYER_SELLER || type === USER_TYPES.SELLER || type === USER_TYPES.BUYER) {
      formData.append('commertialRegister', requestData.commercialRegister);
      delete requestData.commercialRegister;
      delete requestData.commertialRegister;
    }
    delete requestData.legacyAccepted;
    if (requestData?.experyDateOfcr) {
      requestData = {
        ...requestData,
        experyDateOfcr: new Date(requestData.experyDateOfcr).toISOString()
      };
    }
    if (requestData?.experyDateOfdl) {
      requestData = {
        ...requestData,
        experyDateOfdl: new Date(requestData.experyDateOfdl).toISOString()
      };
    }
    requestData = {
      ...requestData,
      kind: type
    };
    formData.append('data', JSON.stringify(requestData));
    formData.append('type', type);
    return this.httpClient.post(APP_ENDPOINTS.AUTH.SIGN_UP, formData);
  }

  updateCommercialRegister(
    data
  ) {
    const formData = new FormData();
    formData.append('commertialRegister', data.commertialRegister);
    formData.append('taxId', data.taxId);
    formData.append('experyDateOfcr', data.experyDateOfcr);
    formData.append('firstName', data.firstName);
    formData.append('lastName', data.lastName);
    formData.append('thirdName', data.thirdName);

    return this.httpClient.patch(APP_ENDPOINTS.USER.USER_REGISTER, formData);
  }

  updateTransporterBusinessInfo(
    data
  ) {
    const formData = new FormData();
    formData.append('drivingLicense', data.drivingLicense);
    formData.append('identityCard', data.identityCard);
    formData.append('taxId', data.taxId);
    formData.append('experyDateOfdl', data.experyDateOfdl);
    formData.append('firstName', data.firstName);
    formData.append('lastName', data.lastName);
    formData.append('thirdName', data.thirdName);

    return this.httpClient.patch(APP_ENDPOINTS.USER.TRANSPORTER_INFO, formData);
  }

  signin(type, requestData) {
    const payload = {
      data: requestData,
      type
    };
    return this.httpClient.post(APP_ENDPOINTS.AUTH.SIGN_IN, payload);
  }

  sendResetCode(phoneNumber) {
    const payload = {
      phoneNumber: phoneNumber,
    };
    return this.httpClient.post(APP_ENDPOINTS.AUTH.FORGET_PASSWORD, payload);
  }

  verifyResetCode(phoneNumber, code) {
    const payload = {
      phoneNumber: phoneNumber,
      code: code
    };
    return this.httpClient.post(APP_ENDPOINTS.AUTH.VERIFY_CODE, payload);
  }

  resetPassword(phoneNumber, code, newPassword) {
    const payload = {
      phoneNumber: phoneNumber,
      code: code,
      newPassword: newPassword,
    };
    return this.httpClient.post(APP_ENDPOINTS.AUTH.RESET, payload);
  }

  sendOtp(phoneNumber, type) {
    const payload = {
      phoneNumber: this.phoneCode + phoneNumber,
      type: type,
    };
    this.phoneNumber = phoneNumber;
    this.type = type;
    return this.httpClient.post(APP_ENDPOINTS.AUTH.OTP, payload);
  }

  sendRegisterOtp(phoneNumber, type) {
    const payload = {
      phoneNumber: phoneNumber,
      type: type,
    };
    this.phoneNumber = phoneNumber;
    this.type = type;
    return this.httpClient.post(APP_ENDPOINTS.AUTH.OTP, payload);
  }

  verifyOtp(phoneNumber, email, type) {
    const payload = {
      phoneNumber: this.phoneCode + phoneNumber,
      email: email,
      type: type,
    };
    this.phoneNumber = phoneNumber;
    this.email = email;
    this.type = type;
    return this.httpClient.post(APP_ENDPOINTS.AUTH.VERIFY_OTP, payload);
  }

  verifyRegisterOtp(phoneNumber, email, type) {
    const payload = {
      phoneNumber: phoneNumber,
      email: email,
      type: type,
    };
    this.phoneNumber = phoneNumber;
    this.email = email;
    this.type = type;
    return this.httpClient.post(APP_ENDPOINTS.AUTH.VERIFY_OTP, payload);
  }

  logout() {
    localStorage.clear();
    this.sharedService.connectedUserInfo = null;
    this.router.navigate([APP_ROUTES.AUTH.LOGIN]).then(
      () => window.location.reload()
    );
  }

  getToken() {
    return localStorage.getItem('token');
  }

  isLoggedIn() {
    if (this.sharedService?.isMock) {
      return true;
    }
    const user = localStorage.getItem('user');
    const token = localStorage.getItem('token');
    if (user && token) {
      return true;
    } else {
      return false;
    }
  }

  getConnectedUser() {
    return this.httpClient.get(APP_ENDPOINTS.USER.ME);
  }

  checkPairingCode(pairingCode: string) {
    return this.httpClient.post(APP_ENDPOINTS.USER.CHECK_PAIRING_CODE, { pairingCode });
  }
}
