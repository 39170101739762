<div class="filter-container">
  <form [formGroup]="form" (submit)="addPlace()">
    <div class="text-right">
      <div class="form-group text-right">
        <label><span class="text-danger">*</span> الإسم </label>
        <input type="text" class="form-control mt-3" id="firstName" placeholder="الإسم" formControlName="title"
          [ngClass]="{
          'is-invalid':
          form.controls['title']?.invalid && form.controls['title']?.touched
          }" />

        <label class="mt-4"> أدخل العنوان</label>

        <input #search autocorrect="off" autocapitalize="off" spellcheck="off" placeholder="أدخل الموقع"
          class="form-control mt-3" type="text">


        <label class="mt-4"> أدخل إحداثيات</label>

        <input [ngModelOptions]="{standalone: true}" (ngModelChange)="upadteCoordinates($event)" [(ngModel)]="coords"
          autocorrect="off" autocapitalize="off" spellcheck="off" placeholder="أدخل إحداثيات" class="form-control mt-3"
          type="text">

      </div>

      <button type="button" (click)="setCurrentLocation()" class="my-2 btn btn-secondary ">
        تحديد المكان الحالي
      </button>



      <div class="form-group text-right">




        <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
          <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"
            (dragEnd)="markerDragEnd($event)"></agm-marker>
        </agm-map>

        <h5 *ngIf="address" class="mt-3">Address: {{address}}</h5>
      </div>
    </div>
    <div class="btn-submit d-flex justify-content-center mt-5">
      <button [disabled]="form.invalid || !latitude || !longitude" type="submit" class="btn btn-reserve">
        موافق
      </button>
    </div>
  </form>
</div>