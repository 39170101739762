import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APP_ROUTES } from 'src/app/core/config/routes';
import { APP_ENDPOINTS } from 'src/app/core/endpoints/app.endpoints';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  product: any;

  constructor(private httpClient: HttpClient) { }

  getProductTypesByCategory(categoryId) {
    return this.httpClient.get(APP_ENDPOINTS.PRODUCTS.TYPES + '/' + categoryId);
  }

  getProductTypes() {
    return this.httpClient.get(APP_ENDPOINTS.PRODUCTS.TYPES);
  }

  disableProduct(id: string) {
    return this.httpClient.patch(`${APP_ENDPOINTS.PRODUCTS.PRODUCTS}/${id}/disable`, {});
  }

  getProductQualities() {
    return this.httpClient.get(APP_ENDPOINTS.PRODUCTS.QUALITIES);
  }

  getTransactionProfit() {
    return this.httpClient.get(APP_ENDPOINTS.PROFITS.TRANSACTION);
  }



  getProductPackages() {
    return this.httpClient.get(APP_ENDPOINTS.PRODUCTS.PACKAGES);
  }

  getProductCounties() {
    return this.httpClient.get(APP_ENDPOINTS.PRODUCTS.COUNTRIES);
  }

  getProductsByCatergory(categoryId: string) {
    return this.httpClient.get(
      `${APP_ENDPOINTS.PRODUCTS.PRODUCTS}?categoryId=${categoryId}`
    );
  }

  getFiltredProducts(filter) {
    const httpParams = new HttpParams();
    filter.forEach((item) => {
      httpParams.set(item?.key, item?.value);
    });
    return this.httpClient.get(APP_ENDPOINTS.PRODUCTS.PRODUCTS, {
      params: httpParams
    });
  }

  getProductById(productId: string) {
    return this.httpClient.get(
      `${APP_ENDPOINTS.PRODUCTS.PRODUCTS}/${productId}`
    );
  }

  getPublicProductById(productId: string) {
    return this.httpClient.get(
      `${APP_ENDPOINTS.PRODUCTS.PRODUCTS}/public/${productId}`
    );
  }

  purchaseProduct(requestData: any) {
    return this.httpClient.post(
      APP_ENDPOINTS.TRANSACTIONS.PRODUCT,
      requestData
    );
  }

  addProduct(payload: any) {
    return this.httpClient.post(APP_ENDPOINTS.PRODUCTS.PRODUCTS, payload);
  }

  updateProduct(id: string,  payload: any) {
    return this.httpClient.patch(`${APP_ENDPOINTS.PRODUCTS.PRODUCTS}/${id}`, payload);
  }

  getProduct() {
    return this.product;
  }

  setProduct(product: any) {
    this.product = product;
  }

  getIsMarketOpen() {
    return this.httpClient.get(`${APP_ENDPOINTS.MARKET_TIME}/check`)
  }

  getMyApprovedProducts() {
    return this.httpClient.get(`${APP_ENDPOINTS.PRODUCTS.MINE}?status=APPROVED`);
  }

  getMarketOpeningTimes() {
    return this.httpClient.get(`${APP_ENDPOINTS.MARKET_TIME}/open`)
  }
}
