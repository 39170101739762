export const categories_old = [
  {
    id: '1999',
    name: 'فراولة',
    fctPrice: 100,
    salesPercentage: 25,
    high: true,
    transactions: [],
    highestPrice: 150,
    lowestPrice: 90,
    createdAt: '2021-06-29T20:38:30.147Z',
    updatedAt: '2021-06-29T20:38:30.147Z',
    parentCategory: 'string',
    childCategories: {},
    products: [
      {
        id: '2999',
        rate: 4,
        date: '2021-06-29T20:38:30.147Z',
        details: 'فراولة تونسية أصيلة',
        quantity: '200',
        unitPrice: 15,
        address: 'طريق الرياض - الرياض 24',
        createdAt: '2021-06-29T20:38:30.147Z',
        updatedAt: '2021-06-29T20:38:30.147Z',
        type: {
          id: '3999',
          key: 'نوع 1',
          createdAt: '2021-06-29T20:38:30.147Z',
          updatedAt: '2021-06-29T20:38:30.147Z',
          products: ['string']
        },
        quality: {
          id: '4999',
          key: 'جودة 1',
          createdAt: '2021-06-29T20:38:30.148Z',
          updatedAt: '2021-06-29T20:38:30.148Z',
          products: ['string']
        },
        unit: {
          id: '5999',
          key: 'وحدة 1',
          createdAt: '2021-06-29T20:38:30.148Z',
          updatedAt: '2021-06-29T20:38:30.148Z',
          products: ['string']
        },
        category: '1999',
        seller: {},
        buyer: {},
        attachments: {}
      }
    ],
    attachment: {},
    data: {
      prices: [
        8107.85,
        8128.0,
        8122.9,
        8165.5,
        8340.7,
        8423.7,
        8423.5,
        8514.3,
        8481.85,
        8487.7,
        8506.9,
        8626.2,
        8668.95,
        8602.3,
        8607.55,
        8512.9,
        8496.25,
        8600.65,
        8881.1,
        9340.85
      ],
      dates: [
        '13 Nov 2017',
        '14 Nov 2017',
        '15 Nov 2017',
        '16 Nov 2017',
        '17 Nov 2017',
        '20 Nov 2017',
        '21 Nov 2017',
        '22 Nov 2017',
        '23 Nov 2017',
        '24 Nov 2017',
        '27 Nov 2017',
        '28 Nov 2017',
        '29 Nov 2017',
        '30 Nov 2017',
        '01 Dec 2017',
        '04 Dec 2017',
        '05 Dec 2017',
        '06 Dec 2017',
        '07 Dec 2017',
        '08 Dec 2017'
      ]
    }
  },
  {
    id: '1888',
    name: 'بطيخ أحمر',
    fctPrice: 100,
    salesPercentage: 85,
    high: true,
    transactions: [],
    highestPrice: 120,
    lowestPrice: 90,
    createdAt: '2021-06-29T20:38:30.147Z',
    updatedAt: '2021-06-29T20:38:30.147Z',
    parentCategory: 'string',
    childCategories: {},
    products: [
      {
        id: '2888',
        rate: 4,
        date: '2021-06-29T20:38:30.147Z',
        details: 'بطيخ أحمر حجم كبير',
        quantity: '200',
        unitPrice: 15,
        address: 'طريق الرياض - الرياض 24',
        createdAt: '2021-06-29T20:38:30.147Z',
        updatedAt: '2021-06-29T20:38:30.147Z',
        type: {
          id: '3999',
          key: 'نوع 1',
          createdAt: '2021-06-29T20:38:30.147Z',
          updatedAt: '2021-06-29T20:38:30.147Z',
          products: ['string']
        },
        quality: {
          id: '4999',
          key: 'جودة 1',
          createdAt: '2021-06-29T20:38:30.148Z',
          updatedAt: '2021-06-29T20:38:30.148Z',
          products: ['string']
        },
        unit: {
          id: '5999',
          key: 'وحدة 1',
          createdAt: '2021-06-29T20:38:30.148Z',
          updatedAt: '2021-06-29T20:38:30.148Z',
          products: ['string']
        },
        category: '1999',
        seller: {},
        buyer: {},
        attachments: {}
      }
    ],
    attachment: {},
    data: {
      prices: [
        8107.85,
        8128.0,
        8122.9,
        8165.5,
        8340.7,
        8423.7,
        8423.5,
        8514.3,
        8481.85,
        8487.7,
        8506.9,
        8626.2,
        8668.95,
        8602.3,
        8607.55,
        8512.9,
        8496.25,
        8600.65,
        8881.1,
        9340.85
      ],
      dates: [
        '13 Nov 2017',
        '14 Nov 2017',
        '15 Nov 2017',
        '16 Nov 2017',
        '17 Nov 2017',
        '20 Nov 2017',
        '21 Nov 2017',
        '22 Nov 2017',
        '23 Nov 2017',
        '24 Nov 2017',
        '27 Nov 2017',
        '28 Nov 2017',
        '29 Nov 2017',
        '30 Nov 2017',
        '01 Dec 2017',
        '04 Dec 2017',
        '05 Dec 2017',
        '06 Dec 2017',
        '07 Dec 2017',
        '08 Dec 2017'
      ]
    }
  },
  {
    id: '1777',
    name: 'تفاح',
    fctPrice: 360,
    salesPercentage: 70,
    high: false,
    transactions: [],
    highestPrice: 20,
    lowestPrice: 15,
    createdAt: '2021-06-29T20:38:30.147Z',
    updatedAt: '2021-06-29T20:38:30.147Z',
    parentCategory: 'string',
    childCategories: {},
    products: [
      {
        id: '2777',
        rate: 4,
        date: '2021-06-29T20:38:30.147Z',
        details: 'تفاح أخضر',
        quantity: '200',
        unitPrice: 15,
        address: 'طريق الرياض - الرياض 24',
        createdAt: '2021-06-29T20:38:30.147Z',
        updatedAt: '2021-06-29T20:38:30.147Z',
        type: {
          id: '3999',
          key: 'نوع 1',
          createdAt: '2021-06-29T20:38:30.147Z',
          updatedAt: '2021-06-29T20:38:30.147Z',
          products: ['string']
        },
        quality: {
          id: '4999',
          key: 'جودة 1',
          createdAt: '2021-06-29T20:38:30.148Z',
          updatedAt: '2021-06-29T20:38:30.148Z',
          products: ['string']
        },
        unit: {
          id: '5999',
          key: 'وحدة 1',
          createdAt: '2021-06-29T20:38:30.148Z',
          updatedAt: '2021-06-29T20:38:30.148Z',
          products: ['string']
        },
        category: '1999',
        seller: {},
        buyer: {},
        attachments: {}
      }
    ],
    attachment: {},
    data: {
      prices: [
        8506.9,
        8626.2,
        8668.95,
        8602.3,
        8607.55,
        8512.9,
        8496.25,
        8600.65,
        8881.1,
        9340.85,
        8107.85,
        8128.0,
        8122.9,
        8165.5,
        8340.7,
        8423.7,
        8423.5,
        8514.3,
        8481.85,
        8487.7
      ],
      dates: [
        '13 Nov 2017',
        '14 Nov 2017',
        '15 Nov 2017',
        '16 Nov 2017',
        '17 Nov 2017',
        '20 Nov 2017',
        '21 Nov 2017',
        '22 Nov 2017',
        '23 Nov 2017',
        '24 Nov 2017',
        '27 Nov 2017',
        '28 Nov 2017',
        '29 Nov 2017',
        '30 Nov 2017',
        '01 Dec 2017',
        '04 Dec 2017',
        '05 Dec 2017',
        '06 Dec 2017',
        '07 Dec 2017',
        '08 Dec 2017'
      ]
    }
  },
  {
    id: '1666',
    name: 'اللوز',
    fctPrice: 260,
    salesPercentage: 35,
    high: true,
    transactions: [],
    highestPrice: 250,
    lowestPrice: 90,
    createdAt: '2021-06-29T20:38:30.147Z',
    updatedAt: '2021-06-29T20:38:30.147Z',
    parentCategory: 'string',
    childCategories: {},
    products: [
      {
        id: '2666',
        rate: 4,
        date: '2021-06-29T20:38:30.147Z',
        details: 'لوز أخضر',
        quantity: '200',
        unitPrice: 100,
        address: 'طريق الرياض - الرياض 24',
        createdAt: '2021-06-29T20:38:30.147Z',
        updatedAt: '2021-06-29T20:38:30.147Z',
        type: {
          id: '3999',
          key: 'نوع 1',
          createdAt: '2021-06-29T20:38:30.147Z',
          updatedAt: '2021-06-29T20:38:30.147Z',
          products: ['string']
        },
        quality: {
          id: '4999',
          key: 'جودة 1',
          createdAt: '2021-06-29T20:38:30.148Z',
          updatedAt: '2021-06-29T20:38:30.148Z',
          products: ['string']
        },
        unit: {
          id: '5999',
          key: 'وحدة 1',
          createdAt: '2021-06-29T20:38:30.148Z',
          updatedAt: '2021-06-29T20:38:30.148Z',
          products: ['string']
        },
        category: '1999',
        seller: {},
        buyer: {},
        attachments: {}
      }
    ],
    attachment: {},
    data: {
      prices: [
        8107.85,
        8128.0,
        8122.9,
        8165.5,
        8340.7,
        8423.7,
        8423.5,
        8514.3,
        8481.85,
        8487.7,
        8506.9,
        8626.2,
        8668.95,
        8602.3,
        8607.55,
        8512.9,
        8496.25,
        8600.65,
        8881.1,
        9340.85
      ],
      dates: [
        '13 Nov 2017',
        '14 Nov 2017',
        '15 Nov 2017',
        '16 Nov 2017',
        '17 Nov 2017',
        '20 Nov 2017',
        '21 Nov 2017',
        '22 Nov 2017',
        '23 Nov 2017',
        '24 Nov 2017',
        '27 Nov 2017',
        '28 Nov 2017',
        '29 Nov 2017',
        '30 Nov 2017',
        '01 Dec 2017',
        '04 Dec 2017',
        '05 Dec 2017',
        '06 Dec 2017',
        '07 Dec 2017',
        '08 Dec 2017'
      ]
    }
  }
];

export const categories = [
  {
    id: '2c072cf3-d53c-4ca0-ab53-9e8291f58a4d',
    name: 'البرتقال أبو سرة',
    createdAt: '2021-07-14T14:02:43.545Z',
    updatedAt: '2021-07-14T14:02:43.000Z',
    fctPrice: 0,
    salesPercentage: 25,
    high: false,
    transactions: 15,
    highestPrice: 25,
    lowestPrice: 5
  },
  {
    id: '3eeca3f1-2e37-4358-b1ba-1938e00346ba',
    name: 'البرتقال',
    createdAt: '2021-07-14T14:02:43.578Z',
    updatedAt: '2021-07-14T14:02:43.578Z',
    fctPrice: 0,
    salesPercentage: 25,
    high: false,
    transactions: 0,
    highestPrice: 0,
    lowestPrice: 0
  },
  {
    id: '4ee76157-279f-4f38-8b7f-2e1e24c380ba',
    name: 'البرتقال العادى',
    createdAt: '2021-07-14T14:08:12.308Z',
    updatedAt: '2021-07-14T14:08:12.308Z',
    fctPrice: 0,
    salesPercentage: 25,
    high: false,
    transactions: 0,
    highestPrice: 0,
    lowestPrice: 0
  },
  {
    id: '54eb91ee-c7b3-403e-a8f6-adc3a88263bd',
    name: 'البرتقال الصيفى',
    createdAt: '2021-07-14T14:09:31.338Z',
    updatedAt: '2021-07-14T14:09:31.338Z',
    fctPrice: 0,
    salesPercentage: 25,
    high: false,
    transactions: 0,
    highestPrice: 0,
    lowestPrice: 0
  },
  {
    id: '55ac5894-746b-4617-aa7f-eb6749b1a969',
    name: 'البرتقال الصيفى',
    createdAt: '2021-07-14T14:02:43.539Z',
    updatedAt: '2021-07-14T14:02:43.000Z',
    fctPrice: 0,
    salesPercentage: 25,
    high: false,
    transactions: 0,
    highestPrice: 0,
    lowestPrice: 0
  },
  {
    id: '7ab073f1-d85d-408f-913f-ffa0b2d24b9d',
    name: 'البرتقال اليافاوى',
    createdAt: '2021-07-14T14:09:13.508Z',
    updatedAt: '2021-07-14T14:09:13.508Z',
    fctPrice: 0,
    salesPercentage: 25,
    high: false,
    transactions: 0,
    highestPrice: 0,
    lowestPrice: 0
  },
  {
    id: '9442b9fb-a107-44c2-9002-8e7ecdad35c7',
    name: 'البرتقال الدموى',
    createdAt: '2021-07-14T14:08:43.912Z',
    updatedAt: '2021-07-14T14:08:43.912Z',
    fctPrice: 0,
    salesPercentage: 25,
    high: false,
    transactions: 0,
    highestPrice: 0,
    lowestPrice: 0
  },
  {
    id: 'dbc42c52-759d-4f2e-aa90-ae7d93c4c3aa',
    name: 'البرتقال اليافاوى',
    createdAt: '2021-07-14T14:02:43.467Z',
    updatedAt: '2021-07-14T14:02:43.000Z',
    fctPrice: 0,
    salesPercentage: 25,
    high: false,
    transactions: 0,
    highestPrice: 0,
    lowestPrice: 0
  },
  {
    id: 'e72bbd82-4d07-489d-9767-8efd25e7b3d2',
    name: 'البرتقال العادى',
    createdAt: '2021-07-14T14:02:43.454Z',
    updatedAt: '2021-07-14T14:02:43.000Z',
    fctPrice: 0,
    salesPercentage: 25,
    high: false,
    transactions: 0,
    highestPrice: 0,
    lowestPrice: 0
  },
  {
    id: 'efdee159-878f-404f-8666-18c7237f83e1',
    name: 'البرتقال أبو سرة',
    createdAt: '2021-07-14T14:09:52.571Z',
    updatedAt: '2021-07-14T14:09:52.571Z',
    fctPrice: 0,
    salesPercentage: 25,
    high: false,
    transactions: 0,
    highestPrice: 0,
    lowestPrice: 0
  },
  {
    id: 'f6724c64-6d28-44aa-bf27-d28c3eb4e594',
    name: 'البرتقال الدموى',
    createdAt: '2021-07-14T14:02:43.461Z',
    updatedAt: '2021-07-14T14:02:43.000Z',
    fctPrice: 0,
    salesPercentage: 25,
    high: false,
    transactions: 0,
    highestPrice: 0,
    lowestPrice: 0
  }
];

export const category = {
  id: '1999',
  name: 'فراولة',
  salesPercentage: 25,
  fctPrice: 100,
  high: true,
  transactions: 5.11,
  highestPrice: 150,
  lowestPrice: 90,
  createdAt: '2021-06-29T20:38:30.147Z',
  updatedAt: '2021-06-29T20:38:30.147Z',
  parentCategory: 'string',
  childCategories: {},
  products: [
    {
      id: '2999',
      rate: 4,
      date: '2021-06-29T20:38:30.147Z',
      details: 'فراولة تونسية أصيلة',
      quantity: '200',
      unitPrice: 15,
      address: 'طريق الرياض - الرياض 24',
      createdAt: '2021-06-29T20:38:30.147Z',
      updatedAt: '2021-06-29T20:38:30.147Z',
      type: {
        id: '3999',
        key: 'نوع 1',
        createdAt: '2021-06-29T20:38:30.147Z',
        updatedAt: '2021-06-29T20:38:30.147Z',
        products: ['string']
      },
      quality: {
        id: '4999',
        key: 'جودة 1',
        createdAt: '2021-06-29T20:38:30.148Z',
        updatedAt: '2021-06-29T20:38:30.148Z',
        products: ['string']
      },
      unit: {
        id: '5999',
        key: 'وحدة 1',
        createdAt: '2021-06-29T20:38:30.148Z',
        updatedAt: '2021-06-29T20:38:30.148Z',
        products: ['string']
      },
      category: '1999',
      seller: {},
      buyer: {},
      attachments: {}
    }
  ],
  attachment: {},
  data: {
    prices: [
      8107.85,
      8128.0,
      8122.9,
      8165.5,
      8340.7,
      8423.7,
      8423.5,
      8514.3,
      8481.85,
      8487.7,
      8506.9,
      8626.2,
      8668.95,
      8602.3,
      8607.55,
      8512.9,
      8496.25,
      8600.65,
      8881.1,
      9340.85
    ],
    dates: [
      '13 Nov 2017',
      '14 Nov 2017',
      '15 Nov 2017',
      '16 Nov 2017',
      '17 Nov 2017',
      '20 Nov 2017',
      '21 Nov 2017',
      '22 Nov 2017',
      '23 Nov 2017',
      '24 Nov 2017',
      '27 Nov 2017',
      '28 Nov 2017',
      '29 Nov 2017',
      '30 Nov 2017',
      '01 Dec 2017',
      '04 Dec 2017',
      '05 Dec 2017',
      '06 Dec 2017',
      '07 Dec 2017',
      '08 Dec 2017'
    ]
  }
};
