import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-demo-alert',
  templateUrl: './demo-alert.component.html',
  styleUrls: ['./demo-alert.component.scss']
})
export class DemoAlertComponent implements OnInit {
  constructor(private sharedService: SharedService) {}

  ngOnInit(): void {}

  changeDemoMode() {
    this.sharedService.changeMockMode();
  }
}
