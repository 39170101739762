import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PublicRoutingModule } from './public-routing.module';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { AddBankAccountComponent } from './components/add-bank-account/add-bank-account.component';
import { CommercialRegisterComponent } from './components/commercial-register/commercial-register.component';
import { RulesPageComponent } from './components/rules-page/rules-page.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InscriptionFormComponent } from './components/inscription-form/inscription-form.component';

@NgModule({
  declarations: [
    LandingPageComponent,
    AddBankAccountComponent,
    CommercialRegisterComponent,
    RulesPageComponent,
    InscriptionFormComponent
  ],
  imports: [CommonModule, PublicRoutingModule, SharedModule, FormsModule, ReactiveFormsModule],
  exports: [LandingPageComponent,]
})
export class PublicModule {}
