<div class="rules-container text-right">
    <div class="rules-title text-right mb-5">
      <span class="fw-800 fs-24"> الشروط والأحكام </span>
    </div>
    <div class="rules-text fs-18">
      <div
        class="text-right fs-22"
        *ngIf="myRules"
        [innerHTML]="myRules?.text"
      ></div>
    </div>
  
    <!-- switch button -->
   
    <!-- -->
  </div>
  