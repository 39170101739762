import { USER_TYPES } from '../enums';

export const SIDENAV = [
   {
    key: 'HOME',
    for: [
        Object.values(USER_TYPES)
    ]
   },
   {
    key: 'ACCOUNT',
    for: [
        USER_TYPES.BUYER,
        USER_TYPES.SELLER,
        USER_TYPES.BUYER_SELLER,
        USER_TYPES.TRANSPORTER_COMPANY,
        USER_TYPES.TRANSPORTER_AGENT
    ]
   },
   {
    key: 'TRANSPORT',
    for: [
       USER_TYPES.BUYER,
       USER_TYPES.BUYER_SELLER,
       USER_TYPES.SELLER,
       USER_TYPES.TRANSPORTER_AGENT,
       USER_TYPES.TRANSPORTER_COMPANY
    ]
   },
   {
    key: 'TRANSACTION',
    for: [
       USER_TYPES.BUYER,
       USER_TYPES.BUYER_SELLER,
       USER_TYPES.SELLER,
       USER_TYPES.TRANSPORTER_AGENT,
       USER_TYPES.TRANSPORTER_COMPANY,
       USER_TYPES.MARKETER
    ]
   },
   {
    key: 'RESERVATION',
    for: [
       USER_TYPES.BUYER,
       USER_TYPES.BUYER_SELLER,
       USER_TYPES.SELLER,
       USER_TYPES.MARKETER
    ]
   }
]