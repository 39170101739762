<div
  *ngIf="(todos$ | async).notifications && (todos$ | async).notifications.length; else no_notifications"
  class="notifications-list"
>
  <app-notification class="margin-notif" (click)="onClickNotif(notif)"
    *ngFor="let notif of (todos$ | async).notifications | slice:0:4;"
    [notification]="notif"
  ></app-notification>
</div>

<ng-template #no_notifications>
  <div class="d-flex justify-content-center">
    <h5>لا يوجد لديك إشعار(ات) إلى حد الآن</h5>
  </div>
</ng-template>
