<div class="card container p-4">
  <form [formGroup]="form">
    <h2 class="mb-4 text-center">
      الرجاء إستكمال معلوماتك التجارية للتمكن من البيع أو الشراء على المنصة
    </h2>
    <hr>
  <div class="row">


    <div class="col-md-12">

      <div class="row flex-wrap wrap-reverse">
        <div class="form-group text-right col-md-6">
          <label> الإسم الاخير للجهة</label>
          <input

            type="text"
            class="form-control mt-3"
            id="thirdName"
            placeholder="الإسم الاخير للجهة"
            formControlName="thirdName"
            [ngClass]="{
            'is-invalid':
            form.controls['thirdName']?.invalid &&
            form.controls['thirdName']?.touched
            }"
            />
        </div>

        <div class="form-group text-right col-md-6">
          <label> الإسم الثاني للجهة</label>
          <input

            type="text"
            class="form-control mt-3"
            id="lastName"
            placeholder="الإسم الثاني للجهة"
            formControlName="lastName"
            [ngClass]="{
            'is-invalid':
            form.controls['lastName']?.invalid &&
            form.controls['lastName']?.touched
            }"
            />
        </div>

        <div class="form-group text-right col-md-6">
          <label> الإسم الأول للجهة</label>
          <input

            type="text"
            class="form-control mt-3"
            id="firstName"
            placeholder=" الإسم الأول للجهة"
            formControlName="firstName"
            [ngClass]="{
            'is-invalid':
            form.controls['firstName']?.invalid &&
            form.controls['firstName']?.touched
            }"

            />
        </div>
        <p class="text-warning mt-4 text-center">
          شريكنا الزراعي في حال كان المتقدم شركة أو مؤسسة يرجى كتابة اسم الكيان ، وفي حال كان المتقدم فرد يرجى كتابة اسمكم الشخصي
        </p>
      </div>


      <label class="float-right"> الرقم الضريبي (في حال عليك ضريبة)</label>
      <input

      formControlName="taxId"
      [ngClass]="{
      'is-invalid':
      form.controls['taxId']?.invalid &&
      form.controls['taxId']?.touched
      }"

        class="form-control my-4"

        type="text"



        />

      <label class="float-right">تاريخ إنتهاء السجل التجاري</label>
      <input

      formControlName="experyDateOfcr"
      [ngClass]="{
      'is-invalid':
      form.controls['experyDateOfcr']?.invalid &&
      form.controls['experyDateOfcr']?.touched
      }"

        class="form-control my-4"
        placeholder="{{todayDate}}"
        type="date"
        min="{{todayDate}}"
        name="field"

        />


      <label class="float-right">صورة السجل التجاري  أو وثيقة عمل حر</label>
      <input
        class="text-right my-4"
        type="file"
        accept="image/*"
        #fileInputCommercial
        (change)="onFileCommercialRegisterChange($event.target)"
        />
      <a
        class="image-commercial-container"
        (click)="fileInputCommercial.click()">
        <img
          class="image-commercial"
          [src]="imgCommercialPath"
          alt="cam"
          />
      </a>
      <div class="row">
        <button class="btn btn-primary mt-4 float-right"
        (click)="update()">
        تحديث المعلومات التجارية
      </button>
      </div>

    </div>



  </div>
  </form>
</div>
