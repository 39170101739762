<app-navbar></app-navbar>
<div class="register-page-content container">
    <ul
        class="nav nav-pills nav-fill flex-row flex-sm-row user-types-list my-4"
        id="tabs-text"
        role="tablist">


        <li class="nav-item mx-1">
            <a
                [ngClass]="{ active: selectedType === userTypes.MARKETER }"
                class="nav-link mb-sm-3 mb-md-0 active fw-800"
                id="tabs-text-1-tab"
                data-toggle="tab"
                role="tab"
                aria-controls="tabs-text-1"
                aria-selected="true"
                (click)="setRegisterType(userTypes.MARKETER)">مسوق</a>
        </li>
        <li class="nav-item mx-1">
            <a
                [ngClass]="{ active: selectedType === userTypes.CONTROLLER }"
                class="nav-link mb-sm-3 mb-md-0 active fw-800"
                id="tabs-text-1-tab"
                data-toggle="tab"
                role="tab"
                aria-controls="tabs-text-1"
                aria-selected="true"
                (click)="setRegisterType(userTypes.CONTROLLER)">مراقب</a>
        </li>



    </ul>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-5">
                <div class="login-card">
                    <div class="card-header bg-white pb-5">
                        <div class="login-img">
                            <div class="login-img_container">
                                <img src="assets/img/logo.png" alt="kharaaj" />
                            </div>
                        </div>
                    </div>
                    <div class="card-body px-lg-5 py-lg-5 mt-1">
                        <div class="text-right text-muted text-login">
                            <div class="text-login_welcome">مرحباً بك</div>
                            <div class="text-login_desc">
                                <small>البورصة الزراعية أول منصة لبيع المنتجات</small>
                                <small>الزراعية بالجملة فالمنطقة</small>
                            </div>
                        </div>
                        <form
                            class="login-form"
                            role="form"
                            [formGroup]="loginForm"
                            (submit)="onSubmit()">
                            <div
                                *ngIf="selectedType !== userTypes.MARKETER"
                                class="form-group mb-3">
                                <div class="input-group
                                    input-group-alternative">
                                    <label class="login-form_phone-code"> {{
                                        phoneCode }} </label>
                                    <input
                                        formControlName="phoneNumber"
                                        class="form-control"
                                        placeholder="إدخال رقم الهاتف"
                                        pattern="\d*"
                                        [maxlength]="phoneLength"
                                        type="tel"
                                        />
                                </div>
                            </div>
                            <div
                                *ngIf="selectedType === userTypes.MARKETER"
                                class="form-group mb-3">
                                <div class="btn-group float-right btn-group-toggle my-2" data-toggle="buttons">

                                    <label (click)="selectCode()" [ngClass]="{'active': isCodeSelected === true}" class="btn  mx-2">
                                        <input type="radio" name="options" id="option2" autocomplete="off"> رقم الإقتران
                                      </label>
                                      
                                    <label (click)="selectPhone()" [ngClass]="{'active': isPhoneSelected === true}" class="btn   mx-2">
                                      <input type="radio" name="options" id="option1" autocomplete="off" checked> رقم الهاتف
                                    </label>
                                   
                                    
                                  </div>

                                <div class="input-group
                                
                                    input-group-alternative">
                                    
                                    <input
                                    *ngIf="isCodeSelected"
                                        formControlName="pairingCode"
                                        class="form-control"
                                        placeholder="رقم الإقتران "
                                        type="text"
                                        />

                                        <div class="input-group
                                        input-group-alternative"   *ngIf="isPhoneSelected">
                                        <label class="login-form_phone-code"> {{
                                            phoneCode }} </label>
                                        <input
                                            formControlName="pairingCode"
                                            class="form-control"
                                            placeholder="إدخال رقم الهاتف"
                                            pattern="\d*"
                                            [maxlength]="phoneLength"
                                            type="tel"
                                            />
                                    </div>
                                </div>
                            </div>
                            <div
                                *ngIf="
                                selectedType === userTypes.TRANSPORTER_AGENT ||
                                selectedType === userTypes.TRANSPORTER_COMPANY
                                ||
                                selectedType === userTypes.CONTROLLER ||
                                selectedType === userTypes.MARKETER
                                "
                                class="form-group mb-3">
                                <div class="input-group
                                    input-group-alternative">
                                    <input
                                        formControlName="pwd"
                                        class="form-control"
                                        placeholder="كلمة المرور"
                                        type="password"
                                        />
                                </div>
                                <br>
                                <a href="auth/forget-password">
                                    <label>
                                        نسيت كلمة السر؟
                                    </label>
                                </a>

                            </div>

                            <div class="text-center login-form_bottom">
                                <button
                                    type="submit"
                                    class="btn btn-primary my-4"
                                    [disabled]="loginForm.invalid">
                                    تسجيل الدخول
                                </button>
                             

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
