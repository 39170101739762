import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { APP_ROUTES } from 'src/app/core/config/routes';
import { USER_TYPES } from 'src/app/core/enums';
import { StepperConfig, StepperFields } from 'src/app/core/types/stepper.types';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AuthService } from '../../services/auth.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-register-buyer',
  templateUrl: './register-buyer.component.html',
  styleUrls: ['./register-buyer.component.scss']
})
export class RegisterBuyerComponent implements OnInit {
  formRegister: FormGroup;
  stepperField: StepperFields[];
  stepperConfig: StepperConfig;
  @Input() pairingCode: string;

  type: string = USER_TYPES.BUYER;

  constructor(
    private authService: AuthService,
    private sharedService: SharedService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm() {
    const mail = new FormControl('', {
      validators: [Validators.required, Validators.email]
    });
    const firstName = new FormControl('', {
      validators: [Validators.required]
    });

    const otp = new FormControl('', {
      validators: [Validators.required, Validators.maxLength(6), Validators.minLength(6)]
    });
    const lastName = new FormControl('', { validators: [Validators.required] });
    const phoneNumber = new FormControl('', {
      validators: [Validators.required]
    });
    const pwd = new FormControl('', {
      validators: [Validators.required, Validators.minLength(8)]
    });
    const confirmPwd = new FormControl('', {
      validators: [Validators.required, Validators.minLength(8)]
    });
    const avatar = new FormControl('', { validators: [] });
    const city = new FormControl('', { validators: [] });
    const region = new FormControl('', { validators: [] });
    const codePostal = new FormControl('', { validators: [] });
    const address = new FormControl('', { validators: [] });
    const pairingCode = new FormControl({ value: this.pairingCode, disabled: true });
    const taxId = new FormControl('', {
      validators: [Validators.maxLength(15), Validators.minLength(15)]
    });
    const commertialRegister = new FormControl('', { validators: [] });
    const experyDateOfcr = new FormControl('', { validators: [] });


    this.stepperField = [
      {
        name: 'email',
        text: '* البريد الإلكتروني',
        type: 'email',
        errorMsg: 'الرجاء إدخال بريد إلكتروني صحيح ',
        control: mail
      },


      {
        name: 'phoneNumber',
        text: '* رقم الهاتف',
        type: 'tel',
        control: phoneNumber
      },
      {
        name: 'region',
        text: 'المنطقة *',
        type: 'select',
        control: region,
        childs: []
      },
      {
        name: 'city',
        text: 'المدينة *',
        type: 'select',
        control: city,
        childs: []
      }
    ];

    this.stepperConfig = {
      number: 1,
      type: USER_TYPES.BUYER,
      config: [
        {
          step: 1,
          size: 9,
        }
      ],
    };
  }

  onSubmit(event) {
    return this.sendOtpVerification(event.phoneNumber, event.email, event);
  }

  sendOtpVerification(phoneNumber: string, email: string, event) {
    this.authService.verifyRegisterOtp(phoneNumber, email, 'BUYER')
      .pipe(take(1))
      .subscribe(
        res => {
          this.toastr.success('أرسلنا رمز التفعيل إلى الهاتف');
          const navigationExtras: NavigationExtras = {
            state: event
          };
          return this.router.navigate(['/auth/register-otp'], navigationExtras);
        }
      )
  }
}
