<app-navbar></app-navbar>
<div class="register-page-content">
  <div
    *ngIf="selectedType != null"
    class="flex-row flex-sm-row user-types-list my-4 container text-center"
  >
    <label class="select">
      <select
        class="select text-center form-control"
        [(ngModel)]="selectedType"
        (change)="onChange($event.target.value)"
      >
        <option class="bg-white text-black" disabled [value]="null">
          الرجاء إختيار نوع العميل
        </option>
        <option class="bg-white text-black" [value]="userTypes.TRANSPORTER">
          ناقل
        </option>
        <option class="bg-white text-black" [value]="userTypes.SELLER">
          بائع
        </option>
        <option class="bg-white text-black" [value]="userTypes.BUYER">
          مشتري
        </option>
        <option class="bg-white text-black" [value]="userTypes.BUYER_SELLER">
          مشتري وبائع
        </option>
      </select>
    </label>
  </div>

  <div class="shape shape-style-1 bg-gradient-default">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>

  <div class="register-page-type-container">
    <div *ngIf="selectedType == null" class="my-3">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="card bg-white shadow register-card">
            <div class="card-body px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-4 text-create-account">
                <small>إنشاء حساب</small>
              </div>
              <div
                class="flex-row flex-sm-row user-types-list my-4 text-center"
              >
                <label class="select">
                  <select
                    class="select text-center form-control"
                    [(ngModel)]="selectedType"
                    (change)="onChange($event.target.value)"
                  >
                    <option class="bg-white text-black" disabled [value]="null">
                      الرجاء إختيار نوع العميل
                    </option>
                    <option
                      class="bg-white text-black"
                      [value]="userTypes.TRANSPORTER"
                    >
                      ناقل
                    </option>
                    <option
                      class="bg-white text-black"
                      [value]="userTypes.SELLER"
                    >
                      بائع
                    </option>
                    <option
                      class="bg-white text-black"
                      [value]="userTypes.BUYER"
                    >
                      مشتري
                    </option>
                    <option
                      class="bg-white text-black"
                      [value]="userTypes.BUYER_SELLER"
                    >
                      مشتري وبائع
                    </option>
                  </select>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-register-buyer-vendor
      [pairingCode]="pairingCode"
      *ngIf="selectedType === userTypes.BUYER_SELLER"
    ></app-register-buyer-vendor>
    <app-register-buyer
      [pairingCode]="pairingCode"
      *ngIf="selectedType === userTypes.BUYER"
    ></app-register-buyer>
    <app-register-seller
      [pairingCode]="pairingCode"
      *ngIf="selectedType === userTypes.SELLER"
    ></app-register-seller>
    <app-register-transporter
      *ngIf="selectedType === userTypes.TRANSPORTER"
    ></app-register-transporter>
  </div>
</div>
