import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ContactInfoService } from '../../services/contact-info.service';

@Component({
  selector: 'app-inscription-form',
  templateUrl: './inscription-form.component.html',
  styleUrls: ['./inscription-form.component.scss']
})
export class InscriptionFormComponent implements OnInit {
  userForm = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    phone: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.email]),
    city: new FormControl('', Validators.required),
    state: new FormControl('', Validators.required),
    gender: new FormControl('', Validators.required),
    activity: new FormControl('', Validators.required),
    message: new FormControl('')
  });

  constructor(private contactInfoService: ContactInfoService, private toastrService: ToastrService,) {}

  onFormSubmit(): void {
    const data = this.userForm.getRawValue();
    if (data.email.length == 0) delete data.email;
    this.contactInfoService.createContactInfo(data).subscribe(
      res=> {
        this.toastrService.success('تم إرسال معلوماتك بنجاح ، وسنتصل بك قريبًا');
        this.userForm.reset()
      }
    )
  }

  ngOnInit(): void {}
}
