import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { APP_ENDPOINTS } from 'src/app/core/endpoints/app.endpoints';
import { BehaviorSubject, forkJoin, Subject } from 'rxjs';
import { isMock, MAPS_API_KEY } from 'src/app/core/config';
import { Router } from '@angular/router';
import { APP_ROUTES } from 'src/app/core/config/routes';
import { login } from 'src/app/core/mock/login';
import { USER_TYPES } from 'src/app/core/enums';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  isMock: boolean;

  isMockSub$ = new BehaviorSubject<boolean>(isMock);

  signupStatus$ = new Subject<string>();

  connectedUserInfo;

  constructor(private http: HttpClient, private router: Router) {
    this.isMockSub$.next(this.isMock);
  }

  getNotifications(limit) {
    return this.http.get(`${APP_ENDPOINTS.NOTIFICATIONS.ALL}?limit=${limit}`);
  }

  markNotificationAsSeen(id: string) {
    return this.http.patch(`${APP_ENDPOINTS.NOTIFICATIONS.STATUS}/${id}`, {
      seen: true
    });
  }

  markNotificationAsUnSeen(id: string) {
    return this.http.patch(`${APP_ENDPOINTS.NOTIFICATIONS.STATUS}/${id}`, {
      seen: false
    });
  }

  uploadFile(file: any) {
    console.log(file);

    const formData = new FormData();
    if (typeof file === 'string') {
      const type = (file.split(';')[0]).split('/')[1];
      const f = this.dataURLtoFile(file,'product-'+ new Date().toISOString()+'.'+type);
      console.log(f);
      formData.append('file', f);
    }
    else {
      formData.append('file', file);
    }
    console.log(formData);
    
    return this.http.post(`${APP_ENDPOINTS.FILES.UPLOAD}`, formData);
  }

  uploadMultipleFile(files: File[]) {
    return forkJoin(
      files.map((file) => this.uploadFile(file)).map((res: any) => res)
    );
  }

  uploadFiles(files: any) {
    return this.http.post(APP_ENDPOINTS.FILES.UPLOAD_MULTIPLE, files);
  }

  getNationalities() {
    return this.http.get(APP_ENDPOINTS.NATIONALTIES.ALL);
  }

  getRegions() {
    return this.http.get(APP_ENDPOINTS.REGIONS.ALL);
  }

  getCities(regionId: string) {
    if (this.isMock) {
      regionId = '1999';
    }
    return this.http.get(`${APP_ENDPOINTS.REGIONS.ALL}/${regionId}/cities`);
  }

  getPlaces() {
    return this.http.get(APP_ENDPOINTS.PLACES.MINE);
  }

  getLanguages() {
    return this.http.get(APP_ENDPOINTS.LANGUAGES.ALL);
  }

  changeMockMode() {
    this.isMock = !this.isMock;
    this.isMockSub$.next(this.isMock);
    this.logoutUser();
    this.router.navigate([APP_ROUTES.AUTH.LOGIN]);
  }

  getDemoStatus() {
    return this.isMockSub$.asObservable();
  }

  getConnectedUser() {
    if (this.isMock) {
      return login.user;
    }
    const user = JSON.parse(localStorage.getItem('user'));
    return user;
  }

  getTransactions() {
    return this.http.get(APP_ENDPOINTS.TRANSACTIONS.MINE);
  }

  getInrequestedTransactions() {
    return this.http.get(APP_ENDPOINTS.TRANSACTIONS.MINE_INREQUESTED);
  }

  getMarketTimes() {
    return this.http.get(APP_ENDPOINTS.MARKET_TIME);
  }

  getPeriods() {
    return this.http.get(APP_ENDPOINTS.PERIODS);
  }

  getTopics() {
    return this.http.get(APP_ENDPOINTS.TOPICS.TOPICS);
  }

  sendSupportMessage(payload: any) {
    return this.http.post(APP_ENDPOINTS.SUPPORT.SUPPORT, payload);
  }

  addNewPlace(payload: any) {
    return this.http.post(APP_ENDPOINTS.PLACES.PLACES, payload);
  }

  editAccount(payload: any) {
    return this.http.patch(APP_ENDPOINTS.USER.ACCOUNTS, payload);
  }

  addNewAccount(payload: any) {
    return this.http.post(APP_ENDPOINTS.USER.ACCOUNTS, payload);
  }

  getRules() {
    return this.http.get(APP_ENDPOINTS.RULES.ALL);
  }

  HgetRole(role: string) {
    switch(role) {
      case USER_TYPES.BUYER: return 'مشتري'
      case USER_TYPES.BUYER_SELLER: return 'مشتري بائع'
      case USER_TYPES.SELLER: return 'بائع'
      case USER_TYPES.MARKETER: return 'مسوق'
      case USER_TYPES.TRANSPORTER: return 'ناقل'
      case USER_TYPES.TRANSPORTER_AGENT: return 'مندوب توصيل'
      case USER_TYPES.TRANSPORTER_COMPANY: return 'شركة توصيل'
      default: return ''
    }
  }

  getUserById(id: string) {
    return this.http.get(`${APP_ENDPOINTS.USER.BY_ID}${id}`)
  }

  getUserInfo() {
    return this.http.get(APP_ENDPOINTS.USER.ME);
  }

  getReports() {
    return this.http.get(APP_ENDPOINTS.TRANSPORT.MY_REPORTS);
  }

  addReport(payload: any) {
    return this.http.post(APP_ENDPOINTS.TRANSPORT.REPORTS, payload);
  }

  addReportTransporter(payload: any) {
    return this.http.post(APP_ENDPOINTS.TRANSPORT.REPORTS_TRANSPORTER, payload);
  }

  addCommentToReport(payload: any, reportId: string) {
    return this.http.post(`${APP_ENDPOINTS.TRANSPORT.REPORTS}/${reportId}/comments`, payload);
  }

  closeReport(payload: any, reportId: string) {
    return this.http.post(`${APP_ENDPOINTS.TRANSPORT.REPORTS}/${reportId}/done`, payload);
  }

  updateReportStatus(payload: any, reportId: string) {
    return this.http.patch(`${APP_ENDPOINTS.TRANSPORT.REPORTS}/${reportId}`, payload);
  }

  updateReportByStatus(status: string, reportId: string) {
    return this.http.patch(`${APP_ENDPOINTS.TRANSPORT.REPORTS}/${reportId}/${status}`, {});
  }

  getProductUnits() {
    return this.http.get(APP_ENDPOINTS.PRODUCTS.UNITS);
  }

  getPlatformSettings() {
    return this.http.get(APP_ENDPOINTS.SETTING.GET);
  }

  approveLegacyRules() {
    return this.http.patch(APP_ENDPOINTS.RULES.APPROVE, {});
  }

  logoutUser() {
    this.connectedUserInfo = null;
    localStorage.clear();
  }

  getSignUpStatus() {
    return this.signupStatus$.asObservable();
  }

  changeSignUpStatus(status: string) {
    this.signupStatus$.next(status);
  }

  //utils

  dataURLtoFile(dataurl, filename) {
 
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});
  }

  getAddress(long, lat) {
    return this.http.get(`http://api.positionstack.com/v1/reverse?access_key=${MAPS_API_KEY}&query=${lat},${long}`);
  } 

}
