<div class="top-section">
  <div class="top-section-items">
    <div class="top-section-items_btns">
      <div class="btn-filter">
        <div class="filter-indicator"></div>
      </div>
    </div>
    <div class="top-section-items_title">
    <span>
      للتمكن من تحويل أو سحب أموالك، يرجى استكمال تفاصيل حسابك المصرفي
    </span>
    </div>
  </div>
</div>

<div class="px-4">
  <app-add-account
    [isPage]="true"
    (onAddAccount)="onAddAccount($event)"
  ></app-add-account>
</div>
