<div class="contact-container">
  <div class="top-section d-flex justify-content-end mb-4 mr-5 fw-800">
    <span class="title">إتصل بنا</span>
  </div>

  <div class="card p-3">
    <form [formGroup]="form" (submit)="sendForm()">
      <div class="">
        <div class="filter_title text-right mb-3 mt-3">
          <span class="text-danger">*</span> الموضوع
        </div>
        <select dir="rtl" class="form-control mt-3" formControlName="topic">
          <option value="" selected disabled></option>
          <option *ngFor="let topic of topics" [value]="topic?.id">
            {{ topic?.key }}
          </option>
        </select>
      </div>
      <div class="">
        <div class="filter_title text-right mb-3 mt-4">
          <span class="text-danger">*</span> الملاحظات
        </div>
        <textarea
          rows="4"
          dir="rtl"
          formControlName="description"
          class="form-control"
        >
        </textarea>
      </div>
      <div class="btn-submit d-flex justify-content-center mt-5">
        <button [disabled]="form.invalid" type="submit" class="btn btn-reserve">
          إرسال
        </button>
      </div>
    </form>
  </div>
</div>
