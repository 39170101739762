import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  @ViewChild("videoPlayer", { static: false }) videoplayer: ElementRef;
  isPlay: boolean = false;
  width =window.innerWidth;
  constructor(private sharedService: SharedService) {}
  year = new Date().getFullYear()
  ngOnInit(): void {
    console.log(this.isPlay)
    this.pause()
  }

  changeDemoMode() {
    this.sharedService.changeMockMode();
  }

  play() {
    var myVideo: any = document.getElementById("my_video_1");
    myVideo.play();
  }

  pause() {
    var myVideo: any = document.getElementById("my_video_1");
    myVideo.pause();
  }
}
