import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MODAL_TYPES } from 'src/app/core/enums';
import { ModalService } from '../../modal/service/modal.service';
import { SharedService } from '../../services/shared.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  form: FormGroup;

  topics = [];

  constructor(
    private sharedService: SharedService,
    private modalService: ModalService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.intializeForm();
    this.getTopics();
  }

  intializeForm() {
    this.form = new FormGroup({
      topic: new FormControl('', {validators: [Validators.required]}),
      description: new FormControl('', {validators: [Validators.required]})
    });
  }

  getTopics() {
    this.sharedService.getTopics()
    .pipe(take(1))
    .subscribe((response: any) => {
      if (response && response?.data) {
        this.topics = response?.data;
      }
    })
  }

  sendForm() {
    if (this.form.invalid) {
      this.toastr.error('الرجاء إدخال جميع المعلومات ');
      return;
    }
    const requestData = {
      ...this.form.value,
    };
    this.sharedService.sendSupportMessage(requestData)
    .pipe(take(1))
    .subscribe((response: any) => {
      if (response && response?.data) {
        this.modalService.showSuccess({
          title: 'لقد تم إرسال طلبكم بنجاح',
          description: 'شكراً على ثقتكم في منصة البورصة الزراعية  ',
          type: MODAL_TYPES.SUCCESS
        });
        this.form.reset();
      }
    })
  }

}
