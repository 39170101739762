import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MODAL_TYPES } from 'src/app/core/enums';
import { ModalConfig } from 'src/app/core/types';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  modalSub = new Subject<ModalConfig>();

  constructor() {}

  getModalSubAsObservable() {
    return this.modalSub.asObservable();
  }

  showSuccess(config: ModalConfig) {
    config.type = MODAL_TYPES.SUCCESS;
    this.modalSub.next(config);
  }

  showError(config: ModalConfig) {
    config.type = MODAL_TYPES.ERROR;
    this.modalSub.next(config);
  }

  showInfo(config: ModalConfig) {
    config.type = MODAL_TYPES.INFO;
    this.modalSub.next(config);
  }

  showWarning(config: ModalConfig) {
    config.type = MODAL_TYPES.WARNING;
    this.modalSub.next(config);
  }
}
