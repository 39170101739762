import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { APP_ROUTES } from 'src/app/core/config/routes';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  currentSection$ = new BehaviorSubject<string>(
    APP_ROUTES.PRIVATE.DASHBOARD.HOME
  );
  currentSubSection$ = new BehaviorSubject<string>('');
  isMenuToggled = true;
  menuToggled$ = new Subject<boolean>();

  currentSection;

  constructor() {}

  getCurrentSection() {
    return this.currentSection$.asObservable();
  }

  changeCurrentSection(currentSection: string) {
    this.currentSection$.next(currentSection);
    this.currentSection = currentSection;
  }

  unsubscribeFromCurrentSection() {
    this.currentSection$.complete();
  }

  getCurrentSubSection() {
    return this.currentSubSection$.asObservable();
  }

  changeCurrentSubSection(currentSubSection: string) {
    this.currentSubSection$.next(currentSubSection);
  }

  toggleMenu() {
    this.isMenuToggled = !this.isMenuToggled;
    this.menuToggled$.next(this.isMenuToggled);
  }

  getMenuToggled() {
    return this.menuToggled$.asObservable();
  }

  initializeSidebarListenners() {
    document.getElementById('body').classList.remove('g-sidenav-pinned');
    document.getElementById('body').classList.add('g-sidenav-hidden');
    document.addEventListener('click', (event: any) => {
      if ( document.getElementById('sidenav-main') && document.getElementById('toggle-side-bar') &&
        !document.getElementById('sidenav-main').contains(event.target) &&
        !document.getElementById('toggle-side-bar').contains(event.target)
      ) {
        if (!document.getElementById('backdrop').classList.contains('d-none')) {
          document.getElementById('body').classList.remove('g-sidenav-pinned');
          document.getElementById('body').classList.add('g-sidenav-hidden');
          document.getElementById('backdrop').classList.add('d-none');
          this.toggleMenu();
        }
      }
    });
  }

  unsubscribeFromCurrentSubSection() {
    this.currentSubSection$.complete();
  }
}
