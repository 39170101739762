import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { USER_TYPES } from 'src/app/core/enums';
import { ModalService } from '../../modal/service/modal.service';
import { SharedService } from '../../services/shared.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-rules-register',
  templateUrl: './rules-register.component.html',
  styleUrls: ['./rules-register.component.scss']
})
export class RulesRegisterComponent implements OnInit {

  @Input() isSignup;
  @Input() type;

  rules;
  user;
  userType;
  myRules;
  safeText = ''
  isLegacyAccepted: boolean;

  constructor(
    private sharedService: SharedService,
    private modalService: ModalService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    if (!this.isSignup) {
      this.user = JSON.parse(localStorage.getItem('user'));
      this.userType = this.user?.type;
      if (this.userType && this.userType == USER_TYPES.BUYER || this.userType && this.userType == USER_TYPES.TRANSPORTER || this.userType && this.userType == USER_TYPES.TRANSPORTER_AGENT || this.userType && this.userType == USER_TYPES.TRANSPORTER_COMPANY || this.userType == USER_TYPES.SELLER || this.userType == USER_TYPES.BUYER_SELLER)
      this.getRules();
    }
    else {
      this.userType = this.type;
      this.getRules();
    }
    
  }

  getRules() {
    this.sharedService.getRules()
    .pipe(take(1))
    .subscribe((response: any) => {
      if (response && response?.data) {
        this.rules = response?.data;
        if (this.userType && this.userType == USER_TYPES.TRANSPORTER || this.userType && this.userType == USER_TYPES.TRANSPORTER_AGENT || this.userType && this.userType == USER_TYPES.TRANSPORTER_COMPANY) 
          {
            this.userType = USER_TYPES.TRANSPORTER
          } 
        this.myRules = this.rules?.find((rule: any) => rule?.for == this.userType);
        this.isLegacyAccepted = this.sharedService.connectedUserInfo?.isRulesApproved;
      }
    })
  }


}
