<!-- Navbar -->
<nav
  id="navbar-main"
  class="landing-page-bourse navbar navbar-main navbar-expand-lg navbar-transparent navbar-light py-2"
>
  <div class="container landing-page-bourse">
    <div
      class="navbar-collapse collapse landing-page-bourse"
      id="navbar_global"
    >
      <div class="navbar-collapse-header landing-page-bourse">
        <div class="row">
          <div class="col-6 collapse-brand landing-page-bourse"></div>
        </div>
      </div>
    </div>
  </div>
</nav>
<!-- End Navbar -->
<div class="wrapper">
  <div class="section section-hero section-shaped">
    <div class="shape shape-style-3 shape-default"></div>
    <div class="page-header">
      <div class="container shape-container d-flex align-items-center py-lg">
        <div class="col px-0">
          <div
            class="title-for-animation row align-items-center justify-content-center"
          >
            <div class="col-lg-6 text-center">
              <h1 class="text-white display-1">
                <span
                  class="site-title text-white d-flex justify-content-center"
                >
                  <span class="main-font-en text-lowercase mr-2">Kharaaj</span>
                  خراج</span
                >
              </h1>
              <h2 class="display-4 font-weight-normal text-white">
                أول منصة لبيع المنتجات الزراعية بالجملة في المنطقة
              </h2>
              <div class="btn-wrapper mt-4">
                <a
                  routerLink="/auth/login"
                  class="btn btn-secondary btn-icon mt-3 mb-sm-0"
                >
                  <span class="btn-inner--icon"
                    ><i class="ni ni-button-play"></i
                  ></span>
                  <span class="btn-inner--text text-primary"
                    >تصفح المنصة الآن</span
                  >
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="separator separator-bottom separator-skew zindex-100">
      <svg
        x="0"
        y="0"
        viewBox="0 0 2560 100"
        preserveAspectRatio="none"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <polygon class="fill-white" points="2560 0 2560 100 0 100"></polygon>
      </svg>
    </div>
  </div>

</div>
