<div class="d-flex justify-content-end align-items-end audio-container">
  <span
    *ngIf="url"
    class="remove-record ml-1 text-red c-pointer"
    (click)="removeRecord()"
  >
    ✘  
  </span>

  

  <div class="audio-record mt-3">
    <audio controls="" class="audio-product" *ngIf="url">
      <source [src]="sanitize(url)" type="audio/wav" />
    </audio>
   
    <label *ngIf="!url && !recording" class="timer-record ml-2 text-red c-pointer">
      مدة السجل القصوى 3 دقائق
    </label>

    <label *ngIf="!url && recording" class="timer-record ml-2 text-red c-pointer">
      {{time}} / O3:00
    </label>

    <button
      (click)="checkPermissions()"
      class="btn record-icon ml-3"
      *ngIf="!recording"
      data-toggle="tooltip"
      data-placement="bottom"
      title="بدء التسجيل"
    ></button>
    
    <button
      (click)="stopRecording()"
      class="btn recording-icon ml-3"
      *ngIf="recording"
      data-toggle="tooltip"
      data-placement="bottom"
      title="إنهاء التسجيل"
    ></button>
  </div>
</div>
