import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/features/auth/services/auth.service';
import { take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-commercial-register',
  templateUrl: './commercial-register.component.html',
  styleUrls: ['./commercial-register.component.scss']
})
export class CommercialRegisterComponent implements OnInit {
  commercialRegister;
  imgCommercialPath: any = 'assets/img/placeholder.png';
  todayDate;
  field: any;
  taxId: string = '';

  form: FormGroup;
  constructor(private authService: AuthService, private toastrService: ToastrService,) {
    this.form = new FormGroup({
      taxId: new FormControl(''),
      firstName: new FormControl('', {
        validators: [Validators.required]
      }),
      lastName: new FormControl('', {
        validators: [Validators.required]
      }),
      thirdName: new FormControl('', {
        validators: [Validators.required]
      }),
      experyDateOfcr: new FormControl('', {
        validators: []
      })
    });
  }

  ngOnInit(): void {
    this.getTodayDate()
  }

  update() {
    //if (!this.commercialRegister) this.toastrService.warning('الرجاء تحميل الصورة');

    const data = this.form.getRawValue()
    if (this.commercialRegister) data.commertialRegister = this.commercialRegister

    this.authService.updateCommercialRegister(data)
      .pipe(take(1))
      .subscribe(
        res => {
          window.location.reload()
        }, err => {
          console.log(err)
        }
      )
  }

  getTodayDate() {

    const today = new Date();
    let dd: any = today.getDate();
    let mm: any = today.getMonth() + 1;
    const yyyy = today.getFullYear();

    if (dd < 10) {
      dd = '0' + dd;
    }

    if (mm < 10) {
      mm = '0' + mm;
    }
    this.todayDate = yyyy + '-' + mm + '-' + dd;
  }

  onFileCommercialRegisterChange(file) {
    this.commercialRegister = file.files[0];
    this.imgCommercialPath = file.value;
    let reader = new FileReader();
    reader.onload = () => {
      if (reader.result) {
        this.imgCommercialPath = reader.result;
      }
    };
    reader.readAsDataURL(this.commercialRegister);
  }

}
function parseDate(field: any): any {
  throw new Error('Function not implemented.');
}

