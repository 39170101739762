export const marketTime = [
  {
    id: '1e82b332-0dcc-11ec-bf8d-0242ac1b0003',
    day: 'MONDAY',
    openingTime: '00:00:00',
    closingTime: '00:00:00',
    createdAt: '2021-09-04T22:04:47.704Z',
    updatedAt: '2021-09-04T22:04:47.704Z'
  },
  {
    id: '1e831ed1-0dcc-11ec-bf8d-0242ac1b0003',
    day: 'TUESDAY',
    openingTime: '00:00:00',
    closingTime: '00:00:00',
    createdAt: '2021-09-04T22:04:47.804Z',
    updatedAt: '2021-09-04T22:04:47.804Z'
  },
  {
    id: '1e83a28e-0dcc-11ec-bf8d-0242ac1b0003',
    day: 'WEDNESDAY',
    openingTime: '00:00:00',
    closingTime: '00:00:00',
    createdAt: '2021-09-04T22:04:47.807Z',
    updatedAt: '2021-09-04T22:04:47.807Z'
  },
  {
    id: '1e843920-0dcc-11ec-bf8d-0242ac1b0003',
    day: 'THURSDAY',
    openingTime: '00:00:00',
    closingTime: '00:00:00',
    createdAt: '2021-09-04T22:04:47.811Z',
    updatedAt: '2021-09-04T22:04:47.811Z'
  },
  {
    id: '1e849e5c-0dcc-11ec-bf8d-0242ac1b0003',
    day: 'FRIDAY',
    openingTime: '00:00:00',
    closingTime: '00:00:00',
    createdAt: '2021-09-04T22:04:47.813Z',
    updatedAt: '2021-09-04T22:04:47.813Z'
  },
  {
    id: '1e84f8e3-0dcc-11ec-bf8d-0242ac1b0003',
    day: 'SATURDAY',
    openingTime: '00:00:00',
    closingTime: '00:00:00',
    createdAt: '2021-09-04T22:04:47.816Z',
    updatedAt: '2021-09-04T22:04:47.816Z'
  },
  {
    id: '1e8551f4-0dcc-11ec-bf8d-0242ac1b0003',
    day: 'SUNDAY',
    openingTime: '00:00:00',
    closingTime: '00:00:00',
    createdAt: '2021-09-04T22:04:47.818Z',
    updatedAt: '2021-09-04T22:04:47.818Z'
  }
];
