<div *ngIf="product && type === 1" class="card card-profile" style="height: 600px" data-image="img-raised">
  <div class="card-header-image d-flex">
    <!--
 <span class="points"> ... </span>

    -->

    <div class="card-title text-primary text-right c-pointer">
      {{
      product?.owner?.firstName +
      ' ' +
      product?.owner?.lastName +
      ' ' +
      product?.owner?.thirdName || 'إسم المزود'
      }}
      <div class="card-title-details">
        <span class="card-title-details_date">
          {{ product?.createdAt | date : 'MMM dd' }}
        </span>
        <span *ngIf="product.isPublished == false" class="ribbon__content fs-14">
          منتج مباع
        </span>

        <!--
 <span class="card-title-details_rating">
          <span class="star"> &#9733; </span>
          <span class="star"> &#9733; </span>
          <span class="star"> &#9733; </span>
          <span class="star"> &#9733; </span>
        </span>
        -->
      </div>
    </div>
    <a class="card-img" href="javascript:;">
      <img *ngIf="product?.owner?.avatar?.filename" class="img"
        [src]="(isDemoMode ? '' : BASE_API) + product?.owner?.avatar?.filename"
        onerror="'assets/img/default-avatar.png'" />
      <img *ngIf="!product?.owner?.avatar?.filename" class="img" [src]="'assets/img/default-avatar.png'"
        onerror="'assets/img/default-avatar.png'" />
    </a>
  </div>

  <div class="card-body text-right" (click)="goToProduct()">
   
    <div class="product-image">
      <img [src]="(isDemoMode ? '' : BASE_API) + product?.images[0]?.filename" onerror="'assets/img/mocks/stw.jpg'"
        alt="product-image" />
    </div>
    <div class="body-card">
      <div class="">
        <span>
   
          <span class="bold text-lg">
            {{ product?.name }}
           
          </span>
        </span>
      </div>
      <br>
      <div class="">
        <span>
          النوعية :
          <span class="bold">
            {{ product?.type?.key }}
            <i class="far fa-check-square"></i>
          </span>
        </span>
      </div>
      <div class="">
        <span>
          الكمية المتاحة:
          <span class="bold">
            {{ product?.currentQuantity }} {{ product?.unit?.key }}
            <i class="far fa-check-square"></i>
          </span>
        </span>
      </div>
    </div>
  
  </div>
  <div class="card-footer text-center p-2">
    <div class="line"></div>
    <div  class="product-details_price price-custom text-center ">
      <h2 *ngIf="product.taxId">  <span class="text-xs ">شامل الضريبة *</span>   {{ product?.unitPrice }}  ﷼ / {{ product?.unit?.key }}</h2>  
      <h2 *ngIf="!product.taxId">  <span class="text-xs "> غير شامل الضريبة *</span>   {{ product?.unitPrice }}  ﷼  / {{ product?.unit?.key }}</h2>  
 
    </div>
  </div>
</div>

<!-- You will work on this block Ayoub -> remove this comment when you see it :D -->
<div *ngIf="product && type === 2" class="card-profile2">
  <div class="card-header bg-gray card2 c-pointer" (click)="goToProduct()">
    <img [src]="(isDemoMode ? '' : BASE_API) + product?.images[0]?.filename" onerror="'assets/img/mocks/stw.jpg'"
      alt="product-image " class="card2-image" />
  </div>

  <div>
    <div class="card-text">
      <span class="date"><small>{{ product?.createdAt | date : 'MM/dd/yyyy' }}</small></span>
      <div class="product-details">
        {{ product?.details }}
      </div>
    </div>
    <div class="line"></div>
    <div class="card-flex">
      <div class="product-details_price price-custom">
        <span> {{ CURRENCY }} {{ product?.unitPrice }}</span>
      </div>
      <div class="body-card">
        <div class="">
          <span>
            النوعية :
            <span class="bold">
              {{ product?.type?.key }}
              <i class="far fa-check-square"></i>
            </span>
          </span>
        </div>
        <div class="">
          <span>
            الكمية :
            <span class="bold">
              {{ product?.currentQuantity }} {{ product?.unit?.key }}
              <i class="far fa-check-square"></i>
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>