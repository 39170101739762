import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BLOB_URL, CURRENCY, isMock } from 'src/app/core/config';
import { APP_ROUTES } from 'src/app/core/config/routes';
import { SellerService } from 'src/app/features/private/buyer/services/seller.service';
import { ProductService } from 'src/app/features/private/product/services/product.service';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit, OnDestroy {
  @Input() type;
  @Input() product;
  APP_ROUTES = APP_ROUTES;
  BASE_API = BLOB_URL;
  isDemoMode: boolean = isMock;
  CURRENCY = CURRENCY;

  componentDestroyed$ = new Subject<boolean>();

  constructor(
    private productService: ProductService,
    private sharedService: SharedService,
    private sellerService: SellerService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.sharedService
      .getDemoStatus()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((status) => {
        this.isDemoMode = status;
      });
  }

  goToProduct() {
    this.productService.setProduct(this.product);
    this.router.navigate([
      APP_ROUTES.PRIVATE.DASHBOARD.PRODUCT_CHILDS.PAGE,
      this.product?.id
    ]);
  }

  goToSeller() {
    this.sellerService.setSeller(this.product?.owner);
    this.router.navigate([`${APP_ROUTES.PRIVATE.DASHBOARD.BUYER}/${this.product?.owner?.id}`]);
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.complete();
  }
}
