import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { INTERNATIONAL_PHONE_CODE } from 'src/app/core/config';
import { APP_ROUTES } from 'src/app/core/config/routes';
import { LayoutService } from 'src/app/shared/services/layout.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent implements OnInit {
  phoneNumber: number;
  otpCode: any;
  isValid: boolean;

  phoneCode = INTERNATIONAL_PHONE_CODE;

  timeLeft: number = 60;
  interval;
  canResend: boolean = false;

  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private router: Router,
    private sharedService: SharedService,
    private layoutService: LayoutService
  ) {}

  ngOnInit(): void {
    this.phoneNumber = this.authService.phoneNumber
    if (!this.authService.phoneNumber) {
      this.router.navigate([APP_ROUTES.AUTH.LOGIN]);
    }
    this.startTimer();
  }

  onChangeOtpCode() {
    if (this.otpCode.length === 6) {
      this.isValid = true;
    } else {
      this.isValid = false;
    }
  }

  resendOtp() {
    this.timeLeft = 60;
    this.canResend = false;
    this.startTimer();
    this.authService.sendOtp(this.phoneNumber, this.authService.type).subscribe((response) => {
      console.log(response);
    });
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.canResend = true;
        this.pauseTimer();
      }
    }, 1000);
  }

  pauseTimer() {
    clearInterval(this.interval);
  }

  login() {
    const requestData = {
      phoneNumber: this.phoneCode + this.phoneNumber,
      otp: this.otpCode
    };

    this.authService.signin(this.authService.type, requestData).subscribe(
      (response: any) => {
        if (!this.sharedService?.isMock) {
          localStorage.setItem('user', JSON.stringify(response.data.user));
          localStorage.setItem('token', response.data.token);
        }
        this.toastr.success('! تم تسجيل الدخول بنجاح');
        this.layoutService.initializeSidebarListenners();
        this.router.navigate(['/']);
      },
      (error) => {
        this.toastr.warning('أرسلنا رمز تفعيل جديد إلى الهاتف والبريد الإلكتروني')
      }
    );
  }
}
