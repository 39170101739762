export const places = [
  {
    id: 'e5fc8289-fd83-4fcc-b3e2-f28ea268b262',
    title: 'أمام البنك المركزي',
    latitude: 34.3333333,
    longitude: 43.3333333,
    createdAt: '2021-08-16T16:19:57.413Z',
    updatedAt: '2021-08-16T16:19:57.413Z'
  }
];
