export const APP_ROUTES = {
  AUTH: {
    LOGIN: '/auth/login',
    REGISTER: '/auth/register',
    OTP: '/auth/otp'
  },
  PRIVATE: {
    DASHBOARD: {
      HOME: '/',
      TRANSACTIONS: '/transactions',
      TRANSPORT: '/transport',
      MY_ACCOUNT: '/myaccount',
      USERS: '/users',
      MY_RESERVATIONS: '/reservations',
      MY_RESERVATION_REPORTS: '/transaction-reports',
      MY_TRANSPORT_REPORTS: '/transport-reports',
      NOTIFICATIONS: '/notifications',
      PRODUCT: '/product/share',
      BUYER: '/buyer',
      RULES: '/rules',
      REGISTER_EXPIRES: '/register-expires',
      FORSA: '/services/forsa',
      MY_PRODUCTS: '/my-products',
      MY_ACCOUNT_CHILDS: {
        WALLET: '/myaccount/wallet',
      },
      HOME_CHILDS: {
        CATEGORY: '/category',
        SUB_CATEGORY: '/subcategory'
      },
      PRODUCT_CHILDS: {
        SHARE: '/product/share',
        PAGE: '/product/page',
        ADD: '/product/add'
      },
      TRANSPORT_CHILDS: {
        ADD_CAR: '/transport/add',
        REQUESTS_LIST: '/transport/request-list',
        REQUESTS: '/transport/requests',
        HISTORY: '/transport/history',

      }
    },
    FINANCES: {
      ADD_BANK_ACCOUNT: '/services/add-account'
    }
  }
};
