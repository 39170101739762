import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { APP_ROUTES } from 'src/app/core/config/routes';
import { AuthService } from 'src/app/features/auth/services/auth.service';
import { LayoutService } from '../../services/layout.service';
import { takeUntil } from 'rxjs/operators';
import { SharedService } from '../../services/shared.service';
import { BLOB_URL, isMock } from 'src/app/core/config';
import { USER_TYPES } from 'src/app/core/enums';
import { DataService, NotifInfo } from '../../services/data.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('toggleIcon') toggleIcon: any;

  isMenuToggled: boolean = true;
  notifNumber: number;

  componentDestroyed$ = new Subject<boolean>();

  BLOB_URL = BLOB_URL;
  APP_ROUTES = APP_ROUTES;
  USER_TYPES = USER_TYPES;

  connectedUser: any;
  isDemoMode: boolean = isMock;
  todos$: Observable<NotifInfo>;
  url: string;

  constructor(
    private authService: AuthService,
    private layoutService: LayoutService,
    private sharedService: SharedService,
    private dataService: DataService,
    private router: Router, location: Location
  ) {
    this.router.events.subscribe((val) => {
      if(location.path() != ''){
        this.url = location.path();
        console.log(this.url)
      } else {
        this.url = 'Home'
      }
    });
  }

  ngOnInit(): void {
    this.todos$ = this.dataService.todos$;
    this.sharedService
      .getDemoStatus()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((status) => {
        this.isDemoMode = status;
      });
    this.getMenuToggleStatus();
    this.connectedUser = this.sharedService.getConnectedUser();
    console.log(this.connectedUser);
  }

  ngAfterViewInit() {}

  openSideNav() {
    if (this.isMenuToggled) {
      document.getElementById('body').classList.add('g-sidenav-pinned');
      document.getElementById('body').classList.remove('g-sidenav-hidden');
      document.getElementById('backdrop').classList.remove('d-none');
    } else {
      document.getElementById('body').classList.remove('g-sidenav-pinned');
      document.getElementById('body').classList.add('g-sidenav-hidden');
      document.getElementById('backdrop').classList.add('d-none');
    }
    this.isMenuToggled = !this.isMenuToggled;
    this.layoutService.toggleMenu();
  }

  logout() {
    this.authService.logout();
  }

  setNotifNumber(number: number) {
    this.notifNumber = number;
  }

  getMenuToggleStatus() {
    this.layoutService
      .getMenuToggled()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((isToggled: boolean) => {
        this.isMenuToggled = isToggled;
      });
  }

  toggleMenu() {
    this.layoutService.toggleMenu();
  }

  changeDemoMode() {
    this.sharedService.changeMockMode();
  }

  ngOnDestroy() {
    this.componentDestroyed$.complete();
  }
}
