import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpResponse,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { APP_ROUTES } from 'src/app/core/config/routes';
import { APP_MOCKS } from 'src/app/core/endpoints/app.endpoints';
import { AuthService } from 'src/app/features/auth/services/auth.service';
import { SharedService } from '../services/shared.service';

import * as alertifyjs from 'alertifyjs';

@Injectable()
export class HttpMockInterceptor implements HttpInterceptor {
  isLoading = false;
  counter: number = 0;

  constructor(
    private sharedService: SharedService,
    private toastrService: ToastrService,
    private spinnerService: NgxSpinnerService,
    private authService: AuthService,
    private router: Router,
  ) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {



    if (this.sharedService.isMock) {
      if (
        req.method !== 'GET' &&
        !req.url.includes('signin') &&
        !req.url.includes('otp')
      ) {


      }
      const mock = APP_MOCKS[req.url];
      if (mock) {
        return of(
          new HttpResponse({
            status: 200,
            body: { data: mock }
          })
        );
      } else {
      }
    } else {
      this.counter++;
      this.spinnerService.show();

      setTimeout(() => {

        if (req.method === 'GET') this.spinnerService.hide();

      }, 60000);

      return next.handle(req).pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {

            this.counter--;

            if (!this.counter) {
              this.spinnerService.hide();
            }
            if (this.counter < 0) this.counter = 0
          }
          return event;
        }),
        catchError((error) => {
          if (error instanceof HttpErrorResponse) {

            


            this.counter--;
            if (!this.counter) {
              this.spinnerService.hide();
              if (error.error.message == 'Invalid pairing code') {


              }
              else if (error.error.message == 'Transporter is not approved yet') {




              }
              else if (error.status == 406) {

                var arabic = /[\u0600-\u06FF]/;
                var string = error?.error?.message; // some Arabic string from Wikipedia

                let isArabic = arabic.test(string); // displays true
                if (isArabic == true) {
                  alertifyjs.error(error.error.message)
                } else {
                  alertifyjs.error('الرجاء التثبت')
                }
              }

              else if (error.status == 400) {
                var arabic = /[\u0600-\u06FF]/;
                var string = error?.error?.message; // some Arabic string from Wikipedia

                let isArabic = arabic.test(string); // displays true
                if (isArabic == true) {

                  alertifyjs.error(error.error.message)
                } else {
                  alertifyjs.error('الرجاء التثبت')

                }

              }
              else if (error.status == 404) {
                var arabic = /[\u0600-\u06FF]/;
                var string = error?.error?.message; // some Arabic string from Wikipedia

                let isArabic = arabic.test(string); // displays true
                if (isArabic == true) {
                  alertifyjs.error(error.error.message)

                } else {

                  alertifyjs.error('الرجاء التثبت')
                }

              }
              else if (error.status == 401 || error.status == 403) {
                var arabic = /[\u0600-\u06FF]/;
                var string = error?.error?.message; // some Arabic string from Wikipedia

                let isArabic = arabic.test(string); // displays true
                if (isArabic == true) {
                  alertifyjs.error(error.error.message)
                } else {
                  localStorage.clear();
                  this.sharedService.connectedUserInfo = null;
                  this.router.navigate([APP_ROUTES.AUTH.LOGIN]).then(
                    () => window.location.reload()
                  );
                }
              }
              else if (error.status == 409) {
                var arabic = /[\u0600-\u06FF]/;
                var string = error?.error?.message; // some Arabic string from Wikipedia

                let isArabic = arabic.test(string); // displays true
                if (isArabic == true) {

                  alertifyjs.error(error.error.message)
                } else {
                  alertifyjs.error('الرجاء التثبت')

                }
              }
              else {

                alertifyjs.error('عذرا لقد حصل خطأ')
              }

            }

            return Observable.throw(error);
          }
        })
      );

    }
  }
}