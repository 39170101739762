import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { notifications } from 'src/app/core/mock/notifications';
import { DataService, NotifInfo } from '../../services/data.service';
import { SharedService } from '../../services/shared.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-list-notifications',
  templateUrl: './list-notifications.component.html',
  styleUrls: ['./list-notifications.component.scss']
})
export class ListNotificationsComponent implements OnInit {
  @Output() notifNumber = new EventEmitter<number>();
  @Input() fromNavbar;
  todos$: Observable<NotifInfo>;
  listNotifications: any[];

  constructor(private sharedService: SharedService,
    private dataService: DataService, public router: Router,) {}

  getNotifications(){
    this.sharedService.getNotifications(50)
    .pipe(take(1))
    .subscribe((notifications: any) => {
    
      if (notifications) {
        this.listNotifications = notifications?.data;
        this.dataService.update(notifications?.data)
        let unseenCount = 0
        this.listNotifications.forEach(notif=>{
          if(notif.seen == false){
            unseenCount++
          }
        })
        this.notifNumber.emit(unseenCount);
        if (this.fromNavbar && this.listNotifications?.length > 2) {
          this.listNotifications = this.listNotifications.slice(0,4);
        }
      }
    });
  }

  ngOnInit(): void {
    this.todos$ = this.dataService.todos$;
    //this.getNotifications();
  }

  onClickNotif(notification) {
    console.log(notification)
    if( notification.seen == false){
      this.sharedService
      .markNotificationAsSeen(notification.notification.id)
      .pipe(take(1))
      .subscribe((result) => {
        this.getNotifications()
      });
      if(notification.notification.product){
        this.router.navigate(['/product/page/'+notification.notification.product?.id]);
      }
    }if( notification.seen == true) {
      this.sharedService
      .markNotificationAsUnSeen(notification.notification.id)
      .pipe(take(1))
      .subscribe((result) => {
        this.getNotifications()
      });
    }
     
  }
}
