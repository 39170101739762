<section class="">
  <app-demo-alert *ngIf="isDemoMode"></app-demo-alert>
  <div id="backdrop" class="backdrop d-none w-100"></div>
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.1)" color="#0D6938" type="ball-scale-multiple"
  
  >
    <h1 class="text-spinner">... نحن نعمل على ذلك، يرجى الانتظار</h1>
  </ngx-spinner>
  <app-modal></app-modal>
  <router-outlet></router-outlet>
</section>