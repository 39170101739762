<div
  class=""
  [ngClass]="{
    'text-gold': rate === RATINGS?.GOLD,
    'text-gray': rate === RATINGS?.GOLD,
    'text-platinum': rate === RATINGS?.PLATINUM
  }"
>
  <span *ngIf="rate === RATINGS.SILVER" class="card-title-details_rating">
    <span class="star"> &#9733; </span>
  </span>
  <span *ngIf="rate === RATINGS.GOLD" class="card-title-details_rating">
    <span class="star"> &#9733; </span>
    <span class="star"> &#9733; </span>
    <span class="star"> &#9733; </span>
  </span>
  <span *ngIf="rate === RATINGS.PLATINUM" class="card-title-details_rating">
    <span class="star"> &#9733; </span>
    <span class="star"> &#9733; </span>
    <span class="star"> &#9733; </span>
    <span class="star"> &#9733; </span>
    <span class="star"> &#9733; </span>
  </span>
</div>
