import { FormControl, Validators } from '@angular/forms';
import { USER_TYPES } from '../../enums';

//********* TRANSPORTER FIELDS ********//

const email = new FormControl('', {
  validators: [Validators.required, Validators.email]
});
const firstName = new FormControl('', {
  validators: [Validators.required]
});
const lastName = new FormControl('', { validators: [Validators.required] });
const phoneNumber = new FormControl('', {
  validators: [Validators.required]
});
const pwd = new FormControl('', {
  validators: [Validators.required, Validators.minLength(8)]
});
const confirmPwd = new FormControl('', {
  validators: [Validators.required, Validators.minLength(8)]
});
const avatar = new FormControl('', { validators: [] });
const secretNumber = new FormControl('', {
  validators: [Validators.required]
});
const city = new FormControl('', { validators: [] });
const region = new FormControl('', { validators: [] });
const postalCode = new FormControl('', { validators: [] });
const address = new FormControl('', { validators: [] });
const typeTransportor = new FormControl('', {
  validators: [Validators.required]
});
const nationality = new FormControl('', { validators: [Validators.required] });
const language = new FormControl('', { validators: [Validators.required] });
const experyDateOfdl = new FormControl('', {
  validators: [Validators.required]
});
const identityCard = new FormControl('');
const drivingLicense = new FormControl('');
const commertialRegister = new FormControl('', { validators: [] });
const taxId = new FormControl('', {
  validators: [Validators.maxLength(15), Validators.minLength(15)]
});


export const TRANSPORTER_FIELDS = [
  {
    name: 'email',
    text: '* البريد الإلكتروني',
    type: 'email',
    errorMsg: 'الرجاء إدخال بريد إلكتروني صحيح ',
    control: email
  },
  {
    name: 'phoneNumber',
    text: '* رقم الهاتف',
    type: 'tel',
    control: phoneNumber
  },
  {
    name: 'pwd',
    text: '* كلمة المرور',
    type: 'password',
    control: pwd
  },
  {
    name: 'region',
    text: 'المنطقة *',
    type: 'select',
    control: region,
    childs: []
  },
  {
    name: 'city',
    text: 'المدينة * ',
    type: 'select',
    control: city,
    childs: []
  }
];
