<div class="stepper">
  <div class="stepper_header"></div>
  <div class="stepper_body">
    <div *ngFor="let step of stepForms; let i= index" class="step">
      <div *ngIf="currentStep === i" class="stepper_body_form">
        <form
          class="stepper-form"
          [formGroup]="step.form"
          (submit)="onSubmitStep()">
          <div class="form-group">
            <div
              *ngFor="let field of step.fields; let j= index"
              class="input-group input-group-alternative mb-3">
              <div
                *ngIf="
                field.type === 'file' &&
                field.name !== 'commertialRegister' &&
                field.name !== 'identityCard' &&
                field.name !== 'drivingLicense'
                "
                class="stepper-form-img">
                <div class="stepper-form-img_img">
                  <input
                    type="file"
                    accept="image/*" 
                    #fileInput
                    (change)="onFileInput($event.target)"
                    />
                  <a (click)="fileInput.click()">
                    <img
                      [ngClass]="{ 'br-50': avatar }"
                      [src]="imgPath"
                      alt="cam"
                      />
                  </a>
                </div>
                <span class="stepper-form-img_text">إضافة صورة</span>
              </div>

              <!-- commercial register -->

              <div
                *ngIf="
                (field.type === 'file' &&
                field.name === 'commertialRegister' &&
                !field?.for) ||
                (field.type === 'file' &&
                field.name === 'commertialRegister' &&
                field?.for &&
                field?.for === transporterType)
                "
                class="stepper-form-img-commercial">
                <div class="">
                  <input
                    type="file"
                    accept="image/*" 
                    #fileInputCommercial
                    (change)="onFileCommercialRegisterChange($event.target)"
                    />
                  <a
                    class="image-commercial-container"
                    (click)="fileInputCommercial.click()">
                    <img
                      class="image-commercial"
                      [src]="imgCommercialPath"
                      alt="cam"
                      />
                  </a>
                  <span class="stepper-form-img_text">{{ field.text }}</span>
                </div>
              </div>

              <!-- -->

              <!-- Identity Card -->

              <div
                *ngIf="
                field.type === 'file' &&
                field.name === 'identityCard' &&
                field?.for &&
                field?.for == transporterType
                "
                class="stepper-form-img-commercial mt-3">
                <div class="">
                  <input
                    type="file"
                    #fileInputIdentityCard
                    accept="image/*" 
                    (change)="onFileIdentityCardChange($event.target)"
                    />
                  <a
                    class="image-commercial-container"
                    (click)="fileInputIdentityCard.click()">
                    <img
                      class="image-commercial"
                      [src]="imgIdentityCardPath"
                      alt="cam"
                      />
                  </a>
                  <span class="stepper-form-img_text">{{ field.text }}</span>
                </div>
              </div>

              <!-- -->

              <!-- driving licence -->

              <div
                *ngIf="
                field.type === 'file' &&
                field.name === 'drivingLicense' &&
                field?.for &&
                field?.for == transporterType
                "
                class="stepper-form-img-commercial">
                <div class="">
                  <input
                    type="file"
                    #fileInputdrivingLicense
                    accept="image/*" 
                    (change)="onFiledrivingLicense($event.target)"
                    />
                  <a
                    class="image-commercial-container"
                    (click)="fileInputdrivingLicense.click()">
                    <img
                      class="image-commercial"
                      [src]="imgdrivingLicensePath"
                      alt="cam"
                      />
                  </a>
                  <span class="stepper-form-img_text">{{ field.text }}</span>
                </div>
              </div>

              <!-- -->

              <input
                *ngIf="field.type === 'date'"
                [formControlName]="field.name"
                class="form-control"
                [placeholder]="field.text"
                [type]="field.type"
                [min]="todayDate"
                [ngClass]="{
                'is-invalid':
                step.form.controls[field.name].invalid &&
                step.form.controls[field.name].touched
                }"
                />

              <label *ngIf="field.type === 'date' && field.name ==
                'experyDateOfcr'" class="stepper-from_phone-code">{{ field?.text
                }}</label>








              <input
                *ngIf="
                field.type !== 'file' &&
                field.type !== 'tel' &&
                field.type !== 'email' &&
                field.type !== 'select' &&
                field.type !== 'date' &&
                field.name != 'otp'
                "
                [formControlName]="field.name"
                class="form-control"
                [placeholder]="field.text"
                [type]="field.type"
                [ngClass]="{
                'is-invalid':
                step.form.controls[field.name].invalid &&
                step.form.controls[field.name].touched
                }"
                />

              <label
                *ngIf="field.type === 'tel'"
                class="stepper-from_phone-code">



                <span>
                  {{ phoneCode }}
                </span>
              </label>
         


              <label
                *ngIf="field.name === 'experyDateOfdl'"
                class="stepper-from_phone-code">
                إنتهاء الصلاحية
              </label>




             
              <input
                *ngIf="field.type === 'tel'"
                [formControlName]="field.name"
                [(ngModel)]="phoneNumber"
                class="form-control"
                pattern="\d*"
                [maxlength]="phoneLength"
                [placeholder]="field.text"
                [type]="field.type"
                [ngClass]="{
                'is-invalid':
                step.form.controls[field.name].invalid &&
                step.form.controls[field.name].touched
                }"
                />



                <input
                *ngIf="field.type === 'email'"
                [formControlName]="field.name"
                [(ngModel)]="email"
                class="form-control"
            
             
                [placeholder]="field.text"
                [type]="field.type"
                [ngClass]="{
                'is-invalid':
                step.form.controls[field.name].invalid &&
                step.form.controls[field.name].touched
                }"
                />







              <select
                dir="rtl"
                class="form-control"
                *ngIf="field?.type === 'select' && field?.name != 'region'"
                [formControlName]="field.name"
                [attr.disabled]="field?.disabled"
                [ngClass]="{
                'is-invalid':
                step.form.controls[field.name].invalid &&
                step.form.controls[field.name].touched
                }">
                <option value="" selected disabled>{{ field?.text }}</option>
                <option
                  *ngFor="let child of field?.childs"
                  [value]="child?.value">
                  {{ child?.key }}
                </option>
              </select>

              <select
                dir="rtl"
                class="form-control"
                *ngIf="field?.type == 'select' && field?.name == 'region'"
                [formControlName]="field.name"
                [ngClass]="{
                'is-invalid':
                step.form.controls[field.name].invalid &&
                step.form.controls[field.name].touched
                }">
                <option value="" selected disabled>{{ field?.text }}</option>
                <option *ngFor="let region of regions" [value]="region?.id">
                  {{ region?.key }}
                </option>
              </select>
            </div>
            <div
              *ngIf="
              currentStep + 1 === stepperConfig.number &&
              i + 1 === stepForms.length
              "
              class="row my-2 text-right">
              <div class="row">
                <hr>
                <a class="mx-3" (click)="openRulesModal()">
                  الإطلاع على الشروط والأحكام
                </a>
                <hr>
              </div>
              <div class="col-12 d-flex justify-content-end">
                <label class="custom-toggle mx-3">
                  <input
                    type="checkbox"
                    [checked]="isLegacyAccepted"
                    (change)="onChangeCheck()"
                    />
                  <span class="custom-toggle-slider rounded-circle"></span>
                </label>
                <label class=""><span>أوافق على
                    <a>الشروط والأحكام</a></span></label>

              </div>
            </div>
          </div>
          <div class="stepper-btns">
            <div class="text-center">
              <button type="submit" class="btn btn-primary btn-register">
                <span *ngIf="currentStep + 1 !== stepperConfig.number">
                  التالي
                </span>
                <span *ngIf="currentStep + 1 === stepperConfig.number">
                  إنشاء حساب
                </span>
              </button>
            </div>
            <div class="text-center">
              <button
                type="reset"
                (click)="backStep()"
                class="btn ">
                <span *ngIf="currentStep <1 "> إلغاء </span>
                <span *ngIf="currentStep>= 1"> رجوع </span>
              </button>
            </div>
          </div>
          <div class="text-center py-2">
            <a [routerLink]="[APP_ROUTES.AUTH.LOGIN]" [queryParams]="{type: type}" class="text-black c-pointer"><small>تسجيل
                الدخول ؟</small></a>
          </div>
        </form>
      </div>
    </div>

  </div>

</div>

<!-- MODAL RULES -->
<div
  class="modal fade bd-example-modal-lg"
  id="modal_rules"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myLargeModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 class="modal-title text-right" id="exampleModalLabele">
          الشروط والأحكام
        </h5>
      </div>
      <div class="modal-body">
        <app-rules-register [isSignup]="true" [type]="type"></app-rules-register>
      </div>
      <div class="modal-footer d-none">
        <button
          type="button"
          #btnCloseModalHistoric
          class="btn btn-secondary"
          data-dismiss="modal">
          إغلاق
        </button>
      </div>
    </div>
  </div>
</div>

<button
  class="v-none"
  #btn_modal_rules
  data-toggle="modal"
  data-target="#modal_rules"></button>
