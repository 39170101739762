import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { APP_ROUTES } from 'src/app/core/config/routes';
import { AuthService } from 'src/app/features/auth/services/auth.service';
import { ModalService } from 'src/app/shared/modal/service/modal.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-add-bank-account',
  templateUrl: './add-bank-account.component.html',
  styleUrls: ['./add-bank-account.component.scss']
})
export class AddBankAccountComponent implements OnInit {

  connectedUser = JSON.parse(localStorage.getItem('user'));
  connectedUserUpdated: any;

  constructor(
    private sharedService: SharedService,
    private modalService: ModalService,
    private router: Router,
    private authService: AuthService,
  ) { 
    if (this.connectedUser.account && this.connectedUser?.account?.accountNumber) {
      this.router.navigate([APP_ROUTES.PRIVATE.DASHBOARD.HOME]);
    }
  }

  ngOnInit(): void {
    
  }

  onAddAccount(requestData: any) {
    this.sharedService.addNewAccount(requestData)
    .pipe(take(1))
    .subscribe((response: any) => {
      //this.getConnectedUserData();
      this.sharedService.connectedUserInfo = {
        ...this.sharedService.connectedUserInfo,
        account: response?.data
      }
      this.modalService.showSuccess({
        title: 'لقد تم إضافة الحساب بنجاح ',
        description: ''
      });
      this.router.navigate([APP_ROUTES.PRIVATE.DASHBOARD.HOME]);
      localStorage.setItem('CN', 'true') // CN: can navigate;      
    });
  }


  getConnectedUserData() {
    this.authService.getConnectedUser()
    
    .pipe(take(1))
    .subscribe((response: any) => {
      if (response && response?.data) {
        this.connectedUserUpdated = response?.data;
        this.updateUser();
      }
    });
  }

  updateUser() {
    const user = {...this.connectedUserUpdated};
    if (user.account) delete user.account;
    if (user.products)  delete user.products;
    if (user.cars) delete user.cars;
    localStorage.setItem('user', JSON.stringify(user));
  }

}
